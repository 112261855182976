import { useEffect, useContext } from "react";
import Chart from "chart.js";
import 'chartjs-plugin-colorschemes';

import AdminContext from "../Context/AdminContext";

import { v4 as uuidv4 } from 'uuid';

export default function ChartOperator({data}) {
  const adminContext = useContext(AdminContext);

  const id = uuidv4();

  useEffect(() => {
    if (data && adminContext) {
      const sets = [];
      adminContext.operators.forEach((operator) => {
        const operatorData = data.dataset.filter((item) => item.operatorId === operator.id);
        if (operatorData) {
          const operatorDataArray = [];
          data.labels.forEach((day) => {
            const operatorDataOnDay = operatorData.find((item) => item['DATE(updatedAt)'] === day)
            if (operatorDataOnDay) {
              operatorDataArray.push(operatorDataOnDay['COUNT(id)']);
            } else {
              operatorDataArray.push(0);
            }
          })
          const set = {
            label: operator.name,
            data: operatorDataArray,
          }
          sets.push(set);
        }
      });

      let config = {
        type: "bar",
        data: {
          labels: data.labels,
          datasets: sets
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            align: "end",
            position: "bottom",
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "# cotas aceitas",
                },
                ticks: {
                  fontColor: "rgba(255,255,255,.7)",
                  beginAtZero: true,
                },
                gridLines: {
                  borderDash: [2],
                  drawBorder: false,
                },
              },
            ],
          },
        },
      };
      let ctx = document.getElementById(id).getContext("2d");
      new Chart(ctx, config);
    }
  }, [id, data, adminContext]);

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full shadow-lg rounded">
        <div className="rounded-t bg-blueGray-100 mb-0 px-4 py-4">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h2 className="text-blueGray-700 text-lg font-semibold">
                por operador
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          <div className="relative h-250-px">
            <canvas id={id}></canvas>
          </div>
        </div>
      </div>
    </>
  );
}
