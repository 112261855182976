const getOperatorsRawData = () => {
    return [
        {
          date: "2021-10-10T00:00:00.000-03:00",
          operator: 1,
          quotas: 10,
          volume: 100000,
          step: 1,
        },
        {
          date: "2021-10-11T00:00:00.000-03:00",
          operator: 1,
          quotas: 15,
          volume: 150000,
          step: 1,
        },
        {
          date: "2021-10-10T00:00:00.000-03:00",
          operator: 1,
          quotas: 5,
          volume: 50000,
          step: 2,
        },
        {
          date: "2021-10-11T00:00:00.000-03:00",
          operator: 1,
          quotas: 7,
          volume: 750000,
          step: 2,
        },
        {
          date: "2021-10-10T00:00:00.000-03:00",
          operator: 2,
          quotas: 11,
          volume: 110000,
          step: 1,
        },
        {
          date: "2021-10-11T00:00:00.000-03:00",
          operator: 2,
          quotas: 16,
          volume: 160000,
          step: 1,
        },
        {
          date: "2021-10-10T00:00:00.000-03:00",
          operator: 2,
          quotas: 6,
          volume: 60000,
          step: 2,
        },
        {
          date: "2021-10-11T00:00:00.000-03:00",
          operator: 2,
          quotas: 8,
          volume: 850000,
          step: 2,
        },
      ];
    }

const getSteps = () => {
    return [
        {
          id: 1,
          name: "backlog",
          title: "Iniciar",
        },
        {
          id: 2,
          name: "in_progress",
          title: "Atendimento",
        },
        {
          id: 3,
          name: "no_response",
          title: "Sem Resposta",
        },
        {
          id: 4,
          name: "in_negotiation",
          title: "Negociação",
        },
        {
          id: 5,
          name: "denied",
          title: "Negado",
        },
        {
          id: 6,
          name: "accepted",
          title: "Aceito",
        },
        {
          id: 7,
          name: "discarted",
          title: "Descartado",
        },
      ];
    }


const operatorsApi = {
    getOperatorsRawData,
    getSteps,
}

export default operatorsApi;
