import React, {useContext, useEffect, useState} from "react";
import { DataGrid } from '@material-ui/data-grid';

import AdminContext from "components/Context/AdminContext";

export default function TableOperators({data}) {
    const [operatorsRawData, setOperatorsRawData] = useState([]);

    const adminContext = useContext(AdminContext);
    const steps = adminContext.steps;
    const operators = adminContext.operators;

    useEffect(() => {
        if (data) {
            const arrData = []
            data.forEach((status) => {
                arrData.push({
                    operator: status.operatorId,
                    quotas: status["COUNT(id)"],
                    step: status.status,
                },)
            })
            setOperatorsRawData(arrData)
        }
    }, [data]);

    const getColumns = () => {
        let cols = [
        {
            field: 'operator',
            headerName: 'Operador',
            type: 'string',
            width: 130,
        }];
        steps.forEach(step => { 
            cols.push({
                field: step.name,
                headerName: step.title,
                type: 'string',
                width: 140,
            });
        })
        cols.push(
            {
                field: 'total',
                headerName: 'Total',
                type: 'string',
                width: 90,
            }
        );
        return cols;
    }

    const getRows = () => {
        let rows = [];
        operators.forEach(operator => {
            let row = {
                id: operator.id,
                operator: operator.name,
            }
            const operatorData = operatorsRawData.filter(item => item.operator === operator.id);
            steps.forEach(step => {
                row[step.name] = 0;
                const stepData = operatorData.filter(item => item.step === step.name);
                stepData.forEach(item => {
                    row[step.name] = row[step.name] + item.quotas;
                });
            });
            row['total'] = 0;
            operatorData.forEach(item => {
                row['total'] = row['total'] + item.quotas;
            })
            rows.push(row);
        });
        return rows;
    }
  
    return (
        <>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div className="rounded-t bg-blueGray-100 mb-0 px-4 py-4">
                <div className="text-center flex">
                    <h3 className="text-blueGray-700 text-xl font-semibold"># cotas por operador por etapa</h3>
                </div>
            </div>
            <div className="flex-auto px-6 lg:px-6 py-6">
                <DataGrid
                    autoHeight
                    rows={getRows()}
                    columns={getColumns()}
                    hideFooter
                    disableColumnMenu
                />
            </div>
        </div>
        </>
    )
}