import { useState } from 'react';
import { Link } from 'react-router-dom';
import './card-chats.css'

export default function CardChats() {
    const [showChat, setShowChat] = useState(false);
    
    return (
        <>
            <div className="card chat-app">
                <div id="plist" className="people-list">
                    <ul className="list-unstyled chat-list mt-2 mb-0">
                        <li className={showChat ? "clearfix active" : "clearfix"} onClick={() => setShowChat(true)}>
                            <img src="https://via.placeholder.com/90x90.png?text=RG" alt="avatar"/>
                            <div className="about">
                                <div className="name">Reginaldo - 0215</div>
                                <div className="status"> há 12 minuto(s)</div>                                            
                            </div>
                        </li>
                        <li className={showChat ? "clearfix" : "clearfix active"} onClick={() => setShowChat(false)} >
                            <img src="https://via.placeholder.com/90x90.png?text=PC" alt="avatar"/>
                            <div className="about">
                                <div className="name">Pedro - 0214</div>
                                <div className="status"> há 51 minuto(s) </div>
                            </div>
                        </li>
                        <li className="clearfix">
                            <img src="https://via.placeholder.com/90x90.png?text=JR" alt="avatar"/>
                            <div className="about">
                                <div className="name">Julia - 0213</div>
                                <div className="status"> há 1 hora(s) </div>
                            </div>
                        </li>                                    
                        <li className="clearfix">
                            <img src="https://via.placeholder.com/90x90.png?text=CT" alt="avatar"/>
                            <div className="about">
                                <div className="name">Cristiano - 0212</div>
                                <div className="status"> há 2 hora(s) </div>
                            </div>
                        </li>
                        <li className="clearfix">
                            <img src="https://via.placeholder.com/90x90.png?text=MD" alt="avatar"/>
                            <div className="about">
                                <div className="name">Monica - 0211</div>
                                <div className="status"> há 4 hora(s) </div>
                            </div>
                        </li>
                        <li className="clearfix">
                            <img src="https://via.placeholder.com/90x90.png?text=HL" alt="avatar"/>
                            <div className="about">
                                <div className="name">Herique - 0210</div>
                                <div className="status"> há 4 hora(s) </div>
                            </div>
                        </li>
                    </ul>
                </div>
                    
                    { showChat ? 

                        <div className="chat">
                        <div className="chat-header clearfix">
                            <div className="row">
                                <div className="col-lg-6">
                                    <img src="https://via.placeholder.com/90x90.png?text=RG" alt="avatar"/>
                                    <div className="chat-about">
                                        <h6 className="m-b-0">Reginaldo - 0215</h6>
                                        <small>há 12 minuto(s)</small>
                                    </div>
                                </div>
                                <div className="col-lg-6 hidden-sm text-right">
                                    <i className="fa fa-question"></i>
                                </div>
                            </div>
                        </div>

                        <div className="chat-history">

                        <div className="chat-header clearfix">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="chat-about font-bold">
                                        <h6 className="m-b-0">Ligação de voz</h6>
                                        <small>Duração: 4 minutos e 55 segundos</small>
                                    </div>
                                </div>

                                <div className="col-lg-6 hidden-sm text-right">
                                <Link to="/call-0215-01.mp4" target="_blank" download><button className="self-end text-black active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 my-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                        type="button"
                                    > 
                                        baixar gravação
                                    </button></Link>
                                </div>

                            </div>
                        </div>

                        </div>
                    </div>
                    
                        :
                        
                        <div className="chat">
                        <div className="chat-header clearfix">
                            <div className="row">
                                <div className="col-lg-6">
                                    <img src="https://via.placeholder.com/90x90.png?text=PC" alt="avatar"/>
                                    <div className="chat-about">
                                        <h6 className="m-b-0">Pedro - 0214</h6>
                                        <small>última mensagem há 51 minuto(s)</small>
                                    </div>
                                </div>
                                <div className="col-lg-6 hidden-sm text-right">
                                    <i className="fa fa-question"></i>
                                </div>
                            </div>
                        </div>
                   

                        <div className="chat-history">
                        <ul className="m-b-0">
                            <li className="clearfix">
                                <div className="message-data text-right">
                                    <span className="message-data-time">10:10, Hoje</span>
                                </div>
                                <div className="message other-message float-right"> Olá Pedro, sou a Luise da contemplato, uma empresa parceira da Rodobens. Gostariamos de fazer uma oferta de compra em sua cota 0063-05. Pode conversar agora? </div>
                            </li>
                            <li className="clearfix">
                                <div className="message-data">
                                    <span className="message-data-time">10:12, Hoje</span>
                                </div>
                                <div className="message my-message">Bom dia. Posso sim. Qual o valor da oferta?</div>                                    
                            </li>
                            <li className="clearfix">
                                <div className="message-data text-right">
                                    <span className="message-data-time">10:12, Hoje</span>
                                </div>
                                <div className="message other-message float-right"> Nossa oferta para sua cota é de R$15.420,00. </div>
                            </li>                             
                            <li className="clearfix">
                                <div className="message-data">
                                    <span className="message-data-time">10:25, Hoje</span>
                                </div>
                                <div className="message my-message">Pode ser</div>
                            </li>
                        </ul>
                    </div>
                    </div>
                    }

                
            </div>
        </>
    );
}