import React from "react";
import Chart from "chart.js";

import { v4 as uuidv4 } from 'uuid';

const dataChart = [
  {
    operator: "Luise",
    data: [18, 24, 16, 5, 26, 23, 21, 27, 19, 14],
  },
  {
    operator: "Debora",
    data: [9, 15, 30, 20, 25, 5, 18, 35, 22, 8],
  }
]

export default function CardChartOperatorMessages({operator}) {
  const [data, setData] = React.useState(dataChart[0].data);

  const id = uuidv4();

  React.useEffect(() => { 
    if(operator === "1"){
      setData(dataChart[0].data);
    } else if(operator === "2"){
      setData(dataChart[1].data);
    }
  },[operator])

  React.useEffect(() => {
    let config = {
      type: "bar",
      data: {
        labels: [
          "09:00",
          "10:00",
          "11:00",
          "12:00",
          "13:00",
          "14:00",
          "15:00",
          "16:00",
          "17:00",
          "18:00",
        ],
        datasets: [
          {
            label: '# mensagens',
            data: data,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Orders Chart",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "bottom",
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                borderDash: [2],
                drawBorder: false,
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.2)",
                zeroLineColor: "rgba(33, 37, 41, 0.15)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    let ctx = document.getElementById(id).getContext("2d");
    new Chart(ctx, config);
  }, [id, data]);
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full">
        <div className="p-4 flex-auto">
          <div className="relative h-250-px">
            <canvas id={id}></canvas>
          </div>
        </div>
      </div>
    </>
  );
}
