import TableClusters from 'components/Tables/TableClusters.js';
import TableQuotasAssign from 'components/Tables/TableQuotasAssign';

export default function Attribution() {

  return (
    <>
    <div className="flex flex-wrap">
      <div className="w-full">
        <div className="relative flex flex-col shadow-lg rounded-lg bg-white border-0">
          <TableClusters type="owned" />
          <TableClusters type="assigned" />
          <TableQuotasAssign />
        </div>
      </div>
    </div>
  </>
  );
}
;