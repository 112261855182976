import { useEffect, useState } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from "moment";

import TableOperation from "components/Tables/TableOperation";
import TableUpdates from "components/Tables/TableUpdates";
import PieChart from "components/Charts/PieChart";
import DateFilter from "components/Filters/DateFilter";
import { getFollowUpsAggregated, getOperationDetails, getAggregatedProposals } from '../../services/proposal.service';

export default function Operation() {
  const [operationDetails, setOperationDetails] = useState([]);
  const [proposals, setProposals] = useState();
  const [pieStatesCount, setPieStatesCount] = useState([]);
  const [pieStatesSumMaxValue, setPieStatesSumMaxValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});

  moment.locale('pt-br');
  const [initialDate, setInitialDate] = useState(moment().startOf('day').subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment().startOf('day'));

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const operationDetailsData = await getOperationDetails(initialDate, endDate);
      const proposalsData = await getAggregatedProposals(initialDate, endDate);
      
      setOperationDetails(operationDetailsData);
      setProposals(proposalsData);
      setIsLoading(false);
    }
    getData();
  },[initialDate, endDate]);

  useEffect(() => {
    if (proposals) {
      const labels = proposals.map(proposal => proposal.status) || [];
      const pieCount = {
        labels,
        datasets: [
            {
            label: 'status',
            data: proposals.map(proposal => proposal.quantity) || [],
            hoverOffset: 4,
            borderWidth: 0,
            },
        ],
      };
      setPieStatesCount(pieCount);

      const pieSumMaxValue = {
        labels,
        datasets: [
            {
            label: 'status',
            data: proposals.map(proposal => proposal.maximumValueSum) || [],
            hoverOffset: 4,
            borderWidth: 0,
            },
        ],
      };
      setPieStatesSumMaxValue(pieSumMaxValue);
    }
  },[proposals]);

  if (isLoading) {
    return (
      <div className='h-screen flex justify-center items-center'>
        <CircularProgress />
      </div>
    );
  }

  const handleFiltersChange = (filter) => {
    setFilters(prevState => {
        const filters = { ...prevState };
        filters[filter.key] = filter.value;
        return filters;
    })
  }
  
  let filteredOperationDetails = operationDetails;

  if (filters.groupId) {
    filteredOperationDetails = filteredOperationDetails.filter((detail) => detail.groupId?.toLowerCase().includes(filters.groupId?.toLowerCase()));
  }

  if (filters.quotaId) {
    filteredOperationDetails = filteredOperationDetails.filter((detail) => detail.quotaId?.toLowerCase().includes(filters.quotaId?.toLowerCase()));
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <div className="relative flex flex-col shadow-lg rounded-lg bg-white border-0">
            <div className="rounded-t bg-blueGray-700 mb-0 p-4">
              <div className="text-center flex justify-between">
                  <h6 className="text-white text-xl font-bold">Operação</h6>
                  <DateFilter initialDate={initialDate} setInitialDate={setInitialDate} endDate={endDate} setEndDate={setEndDate}/>
              </div>
            </div>

            <div className="p-4">
              <h6 className="font-bold">dados agregados</h6>
              <div className="flex justify-between">
                <div className="lg:w-6/12 py-2">
                  <TableUpdates initialDate={initialDate} endDate={endDate} />
                </div>
                <div className="flex">
                  <PieChart 
                      title="status"
                      subtitle="# cotas"
                      data={pieStatesCount}
                      showLegend={true}
                      colorScheme='brewer.Accent6'
                  />
                  <PieChart 
                      title="status"
                      subtitle="somátorio valor max. oferta"
                      data={pieStatesSumMaxValue}
                      showLegend={true}
                      colorScheme='brewer.Accent6'
                  />
                </div>
              </div>
            </div>
                              
            <div className="flex-auto p-4">
                <div className="flex flex-row mb-4">
                <div className="flex flex-col">
                  <label
                      className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
                      htmlFor="group"
                  >
                      grupo:
                  </label>
                  <input
                      name="group"
                      type="text"
                      className="border-0 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      value={filters.groupId}
                      onChange={(event) => {
                          handleFiltersChange({
                            key: 'groupId',
                            value: event.target.value,
                          });
                      }}
                  />
                  </div>
                  <div className="flex flex-col ml-4">
                  <label
                      className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
                      htmlFor="quota"
                  >
                      cota:
                  </label>
                  <input
                      name="quota"
                      type="text"
                      className="border-0 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      value={filters.quotaId}
                      onChange={(event) => {
                          handleFiltersChange({
                              key: 'quotaId',
                              value: event.target.value,
                          });
                      }}
                  />
                </div>
                </div>
              <TableOperation operationDetails={filteredOperationDetails}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
