import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function DateFilter({initialDate, setInitialDate, endDate, setEndDate}) {

    return (
        <>
            <div className="bg-white rounded-lg px-2 flex">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="initial-date"
                  label="Data inícial"
                  value={initialDate}
                  onChange={setInitialDate}
                  KeyboardButtonProps={{
                    'aria-label': 'trocar data inicio',
                  }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="end-date"
                  label="Data final"
                  value={endDate}
                  onChange={setEndDate}
                  KeyboardButtonProps={{
                    'aria-label': 'trocar data fim',
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
        </>
    )
}