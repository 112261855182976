import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

import Admin from "layouts/Admin.js";
import Operator from "layouts/Operator.js"
import Auth from "layouts/Auth.js";
import ProtectedRouteAdmin from "components/ProtectedRouteAdmin";
import ProtectedRouteOperator from "components/ProtectedRouteOperator"

import Index from "views/Index.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      
      <ProtectedRouteAdmin path="/admin" component={Admin} />
      <ProtectedRouteOperator path="/operator" component={Operator}/>

      <Route path="/auth" component={Auth} />

      <Route path="/logout" component={Index} />

      <Route path="/" exact component={Index} />

      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
