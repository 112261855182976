import { useState, useEffect } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import 'moment-business-days';

import { getProposalsInTransfer, generateAndDownloadeProposalUrl, setProposalAsTransfered, setCheckedProposalDocuments, addTransferCardNotes, getProposalsInTransferCSV } from '../../services/proposal.service';
import ModalAddNotes from '../../components/Modals/ModalAddNotes';
import ModalSelectDate from '../../components/Modals/ModalSelectDate';
import formatters from '../../utils/formatters';
import { Pagination, Stack } from "@mui/material";
import { Button, Tooltip, Typography } from "@material-ui/core";
import { DebounceInput } from "react-debounce-input";

export default function Transfers() {

    const [isLoading, setIsLoading] = useState(false);

    const [noteOpen, setNoteOpen] = useState();

    const [scheduleOpen, setScheduleOpen] = useState();

    const [proposals, setProposals] = useState([]);

    const [filters, setFilters] = useState({
        orderBy: 'DESC',
        notScheduled: true,
    });

    const [paginated, setPaginated] = useState({
        currentPageNumber: 1,
        totalItems: 0,
        totalPages: 0,
        currentPageSize: 0,
    })

    const PAGE_LIMIT = 50;

    const getData = async (limit = PAGE_LIMIT, page = 0) => {
        setIsLoading(true);
        try {
            const response = await getProposalsInTransfer({
                limit,
                page,
                ...filters
            });

            if (response.status === 200 && response.data) {
                setProposals(response.data.proposalsInTransfer);

                setPaginated({
                    ...paginated,
                    currentPageNumber: response.data.currentPageNumber,
                    totalItems: response.data.totalItems,
                    totalPages: response.data.totalPages,
                    currentPageSize: response.data.currentPageSize
                })
            }
            else {
                alert('erro ao recuperar propostas em transferência');
            }
        } catch (err) {
            alert(err);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getData();
    }, [filters]);

    if (isLoading) {
        return (
            <div className='h-screen flex justify-center items-center'>
                <CircularProgress />
            </div>
        );
    }

    const handleFiltersChange = (filter) => {
        setFilters(prevState => {
            const filters = { ...prevState };
            filters[filter.key] = filter.value;
            return filters;
        })
    }

    const handleSetAsPaid = async (id, scheduledDate) => {
        try {
            await setProposalAsTransfered(id, scheduledDate);
            setScheduleOpen();
            const filteredProposals = proposals.filter((proposal) => proposal.id !== id);
            setProposals(filteredProposals);
            // alert('transferencia marcada como pago e enviado para gestão de carteira');
            alert('pagamento marcada como agendado');
        } catch (err) {
            alert(err);
        }
    }

    const handleDownloadDocuments = async (proposalId, name) => {
        await generateAndDownloadeProposalUrl(proposalId, name);
    }

    const handleSetCheckedDocuments = async (proposalId) => {
        const { documentsChecked } = await setCheckedProposalDocuments(proposalId);

        const newProposals = [...proposals];
        const proposalIndex = newProposals.findIndex((proposal) => proposal.id === proposalId);

        newProposals[proposalIndex] = {
            ...newProposals[proposalIndex],
            documentsChecked: documentsChecked,
        }

        setProposals(newProposals);
    }

    const handleAddNotes = async (proposalId, notes, interact) => {
        try {
            await addTransferCardNotes({
                proposalId,
                interact: 'transfer note - ' + interact,
                details: notes,
                type: 'manual',
                dateHours: moment().format(),
            });

            const newProposals = [...proposals];
            const proposalIndex = newProposals.findIndex((proposal) => proposal.id === proposalId);

            newProposals[proposalIndex] = {
                ...newProposals[proposalIndex],
                documentsChecked: false,
            }

            setProposals(newProposals);

            alert('anotação adicionada com sucesso');
        } catch (err) {
            alert(err);
        } finally {
            setNoteOpen();
        }
    }

    const handleChangePage = async (event, value) => {
        await getData(PAGE_LIMIT, value - 1)
    }

    const handleGenerateCSV = async (event, done) => {
        try {
            const csvData = await getProposalsInTransferCSV(filters);

            const url = window.URL.createObjectURL(
                new Blob([csvData.data]),
            );
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute(
                'download',
                `transferencias.csv`,
            );

            document.body.appendChild(link);

            link.click();

            link.parentNode.removeChild(link);
        } catch (e) {
            console.log(e);
            alert("Erro ao exportar o CSV. Tente novamente.")
        }
    }

    const cardBorderColor = {
        true: '2px solid green',
        false: '2px solid red',
    }

    const TransferCard = ({ proposal }) => {
        const today = moment();
        const transferDate = moment(proposal.quota?.tmsTransfer);

        const businessDays = moment(today).businessDiff(transferDate);

        const MAX_BUSINESS_DELAY_DAYS = 14;

        const average = ((proposal?.ocr?.average || 0) * 100).toFixed(2)

        const [color, setColor] = useState('#000');

        useEffect(() => {
            if (average >= 80) setColor('#0E9652');
            if (average < 80) setColor('#C2C003');
            if (average < 50) setColor('#910909');
        }, [average])

        return (
            <>
                <li
                    className="p-4 w-full mb-6 shadow-lg rounded-lg bg-white flex justify-between items-center"
                    key={proposal.id}
                    style={{ border: cardBorderColor[proposal.documentsChecked] ? cardBorderColor[proposal.documentsChecked] : '2px solid #fff', transition: '0.2s' }}
                >
                    <div className="mr-4 flex flex-col flex-1">
                        <div className="flex justify-between w-full">
                            <div>
                                <p className="text-blueGray-400 text-sm" style={{ width: 282 }}>cliente</p>
                                <p className="text-blueGray-600">{proposal.quota.client.name}</p>
                            </div>
                            <div>
                                <p className="text-blueGray-400 text-sm">contrato</p>
                                <p className="text-blueGray-600">{proposal.quota.contractId}</p>
                            </div>
                            <div>
                                <p className="text-blueGray-400 text-sm">grupo / cota</p>
                                <p className="text-blueGray-600">{proposal.quota.groupId} / {proposal.quota.quotaId}</p>
                            </div>
                            <div>
                                <p className="text-blueGray-400 text-sm">CPF/CNPJ</p>
                                <p className="text-blueGray-600">{proposal.quota.client.nationalId?.length === 11 ? formatters.cpfMaskByValue(proposal.quota.client.nationalId) : formatters.cnpjMaskByValue(proposal.quota.client.nationalId)}</p>
                            </div>
                            <div>
                                <p className="text-blueGray-400 text-sm">valor de compra</p>
                                <p className="text-blueGray-600">{formatters.financeReal(proposal.finalValue)}</p>
                            </div>
                        </div>
                        <div className="flex justify-between mt-5 w-full">
                            <div>
                                <p className="text-blueGray-400 text-sm" style={{ width: 282 }}>código/nome do banco</p>
                                <p className="text-blueGray-600">{proposal.quota.bank} - {proposal.quota.bankName}</p>
                            </div>
                            <div>
                                <p className="text-blueGray-400 text-sm">agência</p>
                                <p className="text-blueGray-600">{proposal.quota.bankAgency}</p>
                            </div>
                            <div>
                                <p className="text-blueGray-400 text-sm">numero da conta</p>
                                <p className="text-blueGray-600">{proposal.quota.bankNumberAccount}</p>
                            </div>
                            <div>
                                <p className="text-blueGray-400 text-sm">tipo</p>
                                <p className="text-blueGray-600">{proposal.quota.bankAccountType}</p>
                            </div>
                            <div>
                                <p className="text-blueGray-400 text-sm">data assinatura contrato</p>
                                {proposal.quota.tmsTransfer ? (
                                    <p className="text-blueGray-600">
                                        {moment(proposal.quota.tmsTransfer).format('DD/MM/YYYY')} <span style={{ fontSize: '14px', color: businessDays > MAX_BUSINESS_DELAY_DAYS ? 'red' : 'black' }}>({businessDays} dias úteis)</span>
                                    </p>
                                ) : 'não disponível'}
                            </div>
                        </div>


                        <div className="flex justify-between w-full mt-6">

                            <div className="flex justify-between border rounded p-2 my-1 cursor-pointer hover:text-lightBlue-600" onClick={() => handleDownloadDocuments(proposal.id, proposal.quota.client.name)}>
                                download documentos
                                <button className='fas fa-download text-xl ml-2'></button>
                            </div>

                            <div className="flex flex-row">

                                <div className="flex justify-between border rounded p-2 my-1 cursor-pointer hover:text-lightBlue-600" onClick={() => handleSetCheckedDocuments(proposal.id)}>
                                    marcar documento válido
                                    <button className='fas fa-clipboard-check text-xl ml-2'></button>
                                </div>

                                <div className="flex flex-col ml-3 mr-2">
                                    <b>Validação Docs</b>
                                    <b style={{ color: color }}>{proposal?.ocr?.average ? `${average}%` : 'N/A'}</b>
                                </div>

                            </div>

                            <div>
                                <div className="flex justify-between border rounded p-2 my-1 cursor-pointer hover:text-lightBlue-600" onClick={() => setNoteOpen(proposal.id)}>
                                    adicionar pendência
                                    <button className='fas fa-bell text-xl ml-2'></button>
                                </div>
                                {(noteOpen === proposal.id) && <ModalAddNotes setIsOpen={setNoteOpen} handleSave={handleAddNotes} proposalId={proposal.id} />}
                            </div>

                            <div>
                                <div className="flex justify-between border rounded p-2 my-1 cursor-pointer hover:text-lightBlue-600" onClick={() => setScheduleOpen(proposal.id)}>
                                    agendar pagamento
                                    <button className='fas fa-calendar-check text-xl ml-2'></button>
                                </div>
                                {(scheduleOpen === proposal.id) && <ModalSelectDate setIsOpen={setScheduleOpen} handleSave={handleSetAsPaid} proposalId={proposal.id} />}
                            </div>

                        </div>
                    </div>
                </li>
            </>
        )
    }

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full px-4">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
                        <div className="rounded-t bg-blueGray-700 mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-white text-xl font-bold">Transferências / Pagamentos Pendentes</h6>
                            </div>
                        </div>

                        <div className="flex flex-col w-full m-4">
                            <h5 className="text-blueGray-500 uppercase font-semibold text-sm">
                                Filtros:
                            </h5>
                            <div className="flex">
                                <div className="flex flex-col pl-4">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
                                        htmlFor="name"
                                    >
                                        nome:
                                    </label>
                                    <DebounceInput
                                        name="name"
                                        type="text"
                                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                                        value={filters.name}
                                        debounceTimeout={400}
                                        onChange={(event) => {
                                            handleFiltersChange({
                                                key: 'name',
                                                value: event.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col pl-4">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
                                        htmlFor="contractId"
                                    >
                                        contrato:
                                    </label>
                                    <DebounceInput
                                        name="contractId"
                                        type="text"
                                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                                        value={filters.contractId}
                                        debounceTimeout={400}
                                        onChange={(event) => {
                                            handleFiltersChange({
                                                key: 'contractId',
                                                value: event.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col pl-4">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
                                        htmlFor="quota"
                                    >
                                        grupo/cota:
                                    </label>
                                    <DebounceInput
                                        name="quota"
                                        type="text"
                                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                                        value={filters.quota}
                                        debounceTimeout={400}
                                        onChange={(event) => {
                                            handleFiltersChange({
                                                key: 'quota',
                                                value: event.target.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col pl-4">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
                                        htmlFor="quota"
                                    >
                                        Ordenar por:
                                    </label>
                                    <select
                                        name="orderBy"
                                        type="text"
                                        className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                                        value={filters.orderBy}
                                        onChange={(event) => {
                                            handleFiltersChange({
                                                key: 'orderBy',
                                                value: event.target.value,
                                            });
                                        }}
                                    >
                                        <option value="DESC">Data da assinatura decrescente</option>
                                        <option value="ASC">Data da assinatura crescente</option>
                                    </select>
                                </div>
                                <div className="flex flex-col pl-4">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
                                        htmlFor="quota"
                                    >
                                        somente não validados e inválidos:
                                    </label>
                                    <input
                                        name="documentsChecked"
                                        type="checkbox"
                                        className="border-0 px-4 py-4 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                                        defaultChecked={filters.documentsChecked}
                                        onChange={(event) => {
                                            handleFiltersChange({
                                                key: 'documentsChecked',
                                                value: event.target.checked,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col pl-4">
                                    <label
                                        className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
                                        htmlFor="quota"
                                    >
                                        somente não agendados:
                                    </label>
                                    <input
                                        name="notScheduled"
                                        type="checkbox"
                                        className="border-0 px-4 py-4 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                                        defaultChecked={filters.notScheduled}
                                        onChange={(event) => {
                                            handleFiltersChange({
                                                key: 'notScheduled',
                                                value: event.target.checked,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-auto p-4">
                            <ul>
                                <Tooltip
                                    title="Você irá exportar a lista de propostas em CSV seguindo os filtros ativos nesta página."
                                    placement="top"
                                >
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: '#66bb6a',
                                            color: "#fff",
                                            fontWeight: "bold",
                                            padding: "0.5rem",
                                            borderRadius: "0.25rem",
                                            marginBottom: "1rem"
                                        }}
                                        onClick={handleGenerateCSV}
                                    >
                                        Export CSV
                                    </Button>

                                </Tooltip>

                                {proposals.map((proposal) => <TransferCard key={proposal.id} proposal={proposal} />)}
                            </ul>
                        </div>
                        <div className="flex items-end justify-between mb-6 ml-4 mr-4">
                            <Typography>Total de Itens: {paginated.totalItems}</Typography>
                            <Stack spacing={2}>
                                <Pagination count={paginated.totalPages} page={paginated.currentPageNumber} onChange={handleChangePage} color="primary" />
                            </Stack>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}
