import React, { useState } from "react";
import { Link } from "react-router-dom";

import { login } from '../../services/auth.service'; 

export default function Login() {
  const [userData, setUserData] = useState({
    email: localStorage.getItem('email'),
    password:'',
  });
  const [rememberEmail, setRemenberEmail] = useState(localStorage.getItem('rememberEmail'))
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);



  const handleInputChange = (e) => {
    setUserData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleCheckChange = (e) => {
    setRemenberEmail(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userData.email === "" || userData.password === "") {
      setErrorMessage("preencha seu email e senha");
    } else {
      try {
        setErrorMessage(null);
        setIsLoading(true);
        const response = await login(userData.email, userData.password);

        if (rememberEmail) {
            localStorage.setItem("email", userData.email);
            localStorage.setItem("rememberEmail", true);
        } else {
          localStorage.removeItem('email');
          localStorage.removeItem('rememberEmail');
        }
        setIsLoading(false);
        if (response.user.role === 'admin' || response.user.role === 'manager') 
          window.location.pathname = "/admin/dashboard";
        else if (response.user.role === 'operator')
          window.location.pathname = "/operator/dashboard";
        else
          setErrorMessage("perfil inválido.");
      } catch (err) {
        setIsLoading(false);
        setErrorMessage(err.message);
      }
    }
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10">
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      defaultValue={userData.email}
                      onChange={handleInputChange}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Senha
                    </label>
                    <input
                      type="password"
                      name="password"
                      onChange={handleInputChange}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Senha"
                    />
                  </div>
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        name="remenberEmail"
                        type="checkbox"
                        defaultChecked={rememberEmail}
                        onChange={handleCheckChange}
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Lembrar email
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <button
                        className="bg-yellow-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Entrar
                      </button>
                  </div>

                  {errorMessage && (
                    <p> {errorMessage} </p>
                  )}

                  {isLoading ? (<p> carregando ...</p>) : null}
                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <a
                  href="/auth/recover-password"
                  className="text-blueGray-200"
                >
                  <small>Esqueci a senha</small>
                </a>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Criar conta</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

