import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { Checkbox } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { getConditionsScheduling } from "services/conditions-scheduling"
import { createTemplateScheduling } from "services/templates-scheduling.service"

export default function ModalCreateTemplateSchedule({ isOpen, onClose, onSubmit, templates }) {
    const [conditions, setConditions] = React.useState([]);
    const [templateId, setTemplateId] = React.useState();
    const [allConditions, setAllConditions] = React.useState([]);
    const [isActive, setIsActive] = React.useState(true);

    React.useEffect(() => {
      async function requestConditionsScheduling() {
        const conditionsScheduling = await getConditionsScheduling();
      
        setAllConditions(conditionsScheduling)
      }

      requestConditionsScheduling();
    }, []);

    const nameRef = React.useRef(null);
    const intervalDaysRef = React.useRef(null);
    const maximumRef = React.useRef(null);

    if (!isOpen) {
      return <div className="absolute" />
    }

    const handleAssigneeChange = (event) => {
      setTemplateId(event.target.value);
  };

    function handleOnChange(condition) {
      setConditions((allConditions) => {
        const conditionsChanged = allConditions.map((itemCondition) => {
          if (condition.id === itemCondition.id) {
            return condition;
          }
          
          return itemCondition;
        });

        return conditionsChanged;
      }); 
    }
    
    function handleAddConditional() {
      const uuid = uuidv4();

      setConditions((allConditions) => {
        return [...allConditions, {
          id: uuid,
          attribute: 1,
          value: 1,
        }];
      });
    }
    
    async function handleDeleteScheduling(conditionId) {
      setConditions((allConditions) => allConditions.filter((condition) => conditionId !== condition.id));
    }

    async function handleOnSubmit() {
      onClose();
      
      if (!intervalDaysRef.current || !maximumRef.current || !nameRef.current) {
        alert('Preencha todos os campos corretamente.');
      } else {
        const createdTemplateScheduling = await createTemplateScheduling({
          name: nameRef.current.value,
          intervalDays: +intervalDaysRef.current.value,
          maximum: +maximumRef.current.value,
          status: isActive,
          templateId: +templateId,
          conditions,
        });

        onSubmit(createdTemplateScheduling);
      }
    }

    return (
    <>
        <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto absolute inset-0 z-50 left-20 outline-none focus:outline-none"
            >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">

                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-2xl px-6 ml-6 font-semibold">
                        Adicionar agendamento
                    </h3>
                    <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" onClick={() => onClose()}>
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                      </span>
                    </button>
                </div>

                <div>
                  <div className="relative w-full mb-3 p-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      nome
                    </label>
                    <input
                      type="text"
                      ref={nameRef}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                  <div className="relative w-full mb-3 p-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-3">
                      condição
                    </label>
                    <div className="flex flex-col justify-center items-center">
                      {conditions?.map((condition) => (
                        <div key={condition.id} className="flex w-full items-center mb-5">
                          <div className="w-full">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                              htmlFor="grid-password"
                            >
                              atributo
                            </label>
                            <FormControl className="w-full" >
                              <Select
                                labelId="assignee-select-label"
                                id="assignee-select"
                                onChange={(e) => handleOnChange({
                                  ...condition,
                                  attribute: e.target.value,
                                })}
                                value={condition.attribute}
                              >
                                {allConditions.map((conditionScheduling) => (
                                  <MenuItem key={conditionScheduling.id} value={conditionScheduling.id}><em>{conditionScheduling.name}</em></MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="w-full ml-6">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                              htmlFor="grid-password"
                            >
                              valor
                            </label>
                            <FormControl className="w-full" >
                              <Select
                                labelId="assignee-select-label"
                                id="assignee-select"
                                onChange={(e) => handleOnChange({
                                  ...condition,
                                  value: e.target.value,
                                })}
                                value={condition.value}
                              >
                                {
                                  allConditions.find((conditionScheduling) => conditionScheduling.id === condition.attribute)?.values.map((conditionValue) => (
                                    <MenuItem key={conditionValue.id} value={conditionValue.id}><em>{conditionValue.name}</em></MenuItem> 
                                  ))
                                }
                              </Select>
                            </FormControl>
                          </div>
                          <i className="fas fa-minus-circle mt-5 text-red-500 cursor-pointer" onClick={() => handleDeleteScheduling(condition.id)}></i>
                        </div>
                      ))}
                      
                      <i className="fas fa-plus-circle text-emerald-500 text-2xl cursor-pointer" onClick={handleAddConditional}></i>
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className="relative w-full mb-3 p-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        periodicidade (dias)
                      </label>
                      <input
                        type="number"
                        defaultValue={1}
                        ref={intervalDaysRef}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                    <div className="relative w-full mb-3 p-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        envio máximo
                      </label>
                      <input
                        type="number"
                        defaultValue={1}
                        ref={maximumRef}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                  <div className="relative w-full mb-3 p-3">
                    <div className="w-full">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                          htmlFor="grid-password"
                        >
                          template
                        </label>
                        <FormControl className="w-full" >
                          <Select
                            labelId="assignee-select-label"
                            value={templateId || templates[0]}
                            onChange={handleAssigneeChange}
                            id="assignee-select"
                          >
                            {
                              templates.map((template) => (
                                <MenuItem key={template.id} value={template.id}><em>{template.name}</em></MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </div>
                  </div>
                  <div>
                    <Checkbox
                      color="primary"
                      onChange={() => setIsActive((active) => !active)}
                      checked={isActive}
                    />
                    <span>Ativo</span>
                  </div>
                </div>

                <div className="flex items-center justify-end px-3 py-3 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={onClose}
                    >
                      Fechar
                    </button>
                    <button
                      className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleOnSubmit}
                    >
                      Criar
                    </button>
                </div>
              </div>
            </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}