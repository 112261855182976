import { getDashboard } from "../services/proposal.service"

const getOverviewData = async (initialDate, EndDate) => {
  const data = await getDashboard(initialDate, EndDate);

  let filterDate ={};
  for (let i = 0; i < data.length; i++) {
    if (data[i]['DATE(updatedAt)']) {
      if (!filterDate[data[i]['DATE(updatedAt)']]) {

        filterDate[data[i]['DATE(updatedAt)']] = [];
      }
      filterDate[data[i]['DATE(updatedAt)']].push(data[i]);
    }
  }

  let arrayFormatter = []
  Object.values(filterDate).forEach((arrDay) => {
    let volume = 0
    let quotas = 0
     arrDay.forEach((day) => {
        volume = volume+ day['ROUND(SUM(finalValue), 2)'];
        quotas = quotas + day['COUNT(id)'];
     })
     arrayFormatter.push({
      date: arrDay[0]['DATE(updatedAt)'],
      volume: volume,
      quotas: quotas,
    })

  })

  return arrayFormatter
}

// const analyzeIntermediation = () => {
//   return {
//     intervalsScore: [
//       {from: 0, to: 2, count: 10},
//       {from: 2, to: 4, count: 20},
//       {from: 4, to: 6, count: 30},
//       {from: 6, to: '*', count: 40},
//     ],
//     intervalsEntryOnCredit: [
//       {from: 0, to: 20, count: 150},
//       {from: 20, to: 45, count: 250},
//       {from: 45, to: 60, count: 350},
//       {from: 60, to: '*', count: 450},
//     ],
//     intervalsPortionOnCredit: [
//       {from: 0, to: 20, count: 140},
//       {from: 20, to: 45, count:250},
//       {from: 45, to: 60, count: 360},
//       {from: 60, to: '*', count: 240},
//     ],
//     intervalsFinanceFee: [
//       {from: 0, to: 20, count: 740},
//       {from: 20, to: 45, count: 520},
//       {from: 45, to: 60, count: 960},
//       {from: 60, to: '*', count: 630},
//     ],
//     intervalsEffectiveCost: [
//       {from: 0, to: 20, count: 740},
//       {from: 20, to: 45, count: 410},
//       {from: 45, to: 60, count: 820},
//       {from: 60, to: '*', count: 930},
//     ],
//     intervalsCredit: [
//       {from: 0, to: '10000.00', count: 450},
//       {from: '10000.00', to: '20000.00', count: 780},
//       {from: '20000.00', to: '40000.00', count: 230},
//       {from: '50000.00', to: '*', count: 120},
//     ],
//   }
// }

const analyzeIntermediation = () => {
  return {
    intervalsScore: [],
    intervalsEntryOnCredit: [],
    intervalsPortionOnCredit: [],
    intervalsFinanceFee: [],
    intervalsEffectiveCost: [],
    intervalsCredit: [],
  }
}

// const generateClusterIntermediation = () => {
//   return [
//     {
//       id: 6564,
//       numberQuotas: 105,
//       quotaIds: [9242, 9255, 9276, 9281, 9322, 9359, 9399, 9430, 9442, 9453, 9477, 9491, 9502, 9532, 9591, 9604, 9609, 9647, 9650, 9655, 9657, 9662, 9683, 9701, 9729, 9746, 9759, 9778, 9820, 9896, 9907, 9913, 9937, 9953, 9966, 9983, 10003, 10009, 10017, 10024, 10063, 10070, 10096, 10097, 10133, 10139, 10164, 10223, 10247, 10297, 10352, 10354, 10357, 10378, 10416, 10424, 10512, 10538, 10549, 10560, 10571, 10595, 10596, 10643, 10648, 10660, 10677, 10681, 10717, 10729, 10734, 10791, 10798, 10811, 10814, 10816, 10874, 10886, 10893, 10901, 10906, 10959, 11005, 11031, 11050, 11067, 11125, 11134, 11136, 11155, 11160, 11176, 11204, 11321, 11348, 11416, 11444, 11462, 11472, 11477],
//       strategy: "Intermediação",
//       intervalScore: '2 - 4',
//       intervalEntryOnCredit: '20 - 45',
//       intervalPortionOnCredit: '0 - 20',
//       intervalFinanceFee: '0 - 20',
//       intervalEffectiveCost: '45 - 60',
//       credit: '50000.00',
//       type: 'Carro',
//     },
//     {
//       id: 6565,
//       numberQuotas: 105,
//       quotaIds: [9242, 9255, 9276, 9281, 9322, 9359, 9399, 9430, 9442, 9453, 9477, 9491, 9502, 9532, 9591, 9604, 9609, 9647, 9650, 9655, 9657, 9662, 9683, 9701, 9729, 9746, 9759, 9778, 9820, 9896, 9907, 9913, 9937, 9953, 9966, 9983, 10003, 10009, 10017, 10024, 10063, 10070, 10096, 10097, 10133, 10139, 10164, 10223, 10247, 10297, 10352, 10354, 10357, 10378, 10416, 10424, 10512, 10538, 10549, 10560, 10571, 10595, 10596, 10643, 10648, 10660, 10677, 10681, 10717, 10729, 10734, 10791, 10798, 10811, 10814, 10816, 10874, 10886, 10893, 10901, 10906, 10959, 11005, 11031, 11050, 11067, 11125, 11134, 11136, 11155, 11160, 11176, 11204, 11321, 11348, 11416, 11444, 11462, 11472, 11477],
//       strategy: "Intermediação",
//       intervalScore: '4 - 6',
//       intervalEntryOnCredit: '20 - 45',
//       intervalPortionOnCredit: '0 - 20',
//       intervalFinanceFee: '0 - 20',
//       intervalEffectiveCost: '45 - 60',
//       credit: '70000.00',
//       type: 'Imóvel',
//     },
//     {
//       id: 6566,
//       numberQuotas: 105,
//       quotaIds: [9242, 9255, 9276, 9281, 9322, 9359, 9399, 9430, 9442, 9453, 9477, 9491, 9502, 9532, 9591, 9604, 9609, 9647, 9650, 9655, 9657, 9662, 9683, 9701, 9729, 9746, 9759, 9778, 9820, 9896, 9907, 9913, 9937, 9953, 9966, 9983, 10003, 10009, 10017, 10024, 10063, 10070, 10096, 10097, 10133, 10139, 10164, 10223, 10247, 10297, 10352, 10354, 10357, 10378, 10416, 10424, 10512, 10538, 10549, 10560, 10571, 10595, 10596, 10643, 10648, 10660, 10677, 10681, 10717, 10729, 10734, 10791, 10798, 10811, 10814, 10816, 10874, 10886, 10893, 10901, 10906, 10959, 11005, 11031, 11050, 11067, 11125, 11134, 11136, 11155, 11160, 11176, 11204, 11321, 11348, 11416, 11444, 11462, 11472, 11477],
//       strategy: "Intermediação",
//       intervalScore: '6 - *',
//       intervalEntryOnCredit: '45 - 60',
//       intervalPortionOnCredit: '20 - 45',
//       intervalFinanceFee: '45 - 60',
//       intervalEffectiveCost: '0 - 20',
//       credit: '90000.00',
//       type: 'Moto',
//     },
//   ]
// }
const generateClusterIntermediation = () => ([])

const adminApi = {
  getOverviewData,
  analyzeIntermediation,
  generateClusterIntermediation,
}

export default adminApi;