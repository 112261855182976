import React, { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import Select from 'react-select';
import '../../assets/styles/lanes.css';
import moment from 'moment';

import Board from "react-trello";

import OperatorNavbar from 'components/Navbars/OperatorNavbar';
import OperatorContext from "../../components/Context/OperatorContext"
import CardOperator from '../../components/Cards/CardOperator';
import ModalConfirmFinalValue from '../../components/Modals/ModalConfirmFinalValue';
import OperatorBoardLaneHeader from '../../components/Headers/OperatorBoardLaneHeader';
import ModalTabulation from '../../components/Modals/ModalTabulation';
import ModalSelectTemplate from '../../components/Modals/ModalSelectTemplate';

import { enrichClientData } from '../../services/proposal.service'
import {GenerateLanes, getAssignedToOperator, updateStatusProposals, createInteract } from "../../services/operator.service"

const styless = {
    select: {
        fontSize: "12px",
        border: 0,
        borderRadius: 6,
        paddingTop: 6,
        paddingBottom: 6,
        lineHeight: '1.25rem'
      },
      box:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        textAlign: "center",
        borderRadius: 10,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      }
}

export default function DashboardOperator() {
    const { selectedCards, setSelectedCards, handleCheckCard, operator } = useContext(OperatorContext);
    const [eventBus, setEventBus] = useState(undefined);
    const [modalTabulationOpen, setModalTabulationOpen] = useState(false);
    const [showModalConfirmFinalValue, setShowModalConfirmFinalValue] = useState(false);
    const [propsCardDrop, setPropsCardDrop] = useState({finalValue: 0});
    const [targetSourceLines, setTargetSourceLines] = useState({})
    const [asyncBoard, setAsyncBoard] = useState(false);
    const [data, setData] = useState();
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem("operatorFilters")) || {});
    const [lanes, setLanes] = useState();
    const [laneCheck, setLaneCheck] = useState("backlog");
    const [assigneeIdForMessage, setAssigneeIdForMessage] = useState();
    const [modalSelectTemplateOpen, setModalSelectTemplateOpen] = useState(false);

    useEffect(() => {
      const getData = async () => {
        try {
          const response = await getAssignedToOperator();
          setData(response);
        } catch (err) {
          window.location.pathname = '/auth/login';
          alert(err);
        } 
      }
      getData();

      const epbxLogins = [
        {login: "adriele.contemplato", senha: "contemplato", ramal: 1003},
        {login: "debora.contemplato", senha: "contemplato", ramal: 1002},
        {login: "luise.contemplato", senha: "contemplato", ramal: 1004},
        {login: "fernando.contemplato", senha: "contemplato", ramal: 1005},
        {login: "dev.contemplato", senha: "contemplato", ramal: 1006},
        {login: "operador.rodobens", senha: "senha", ramal: 1007},
        {login: "marilia.contemplato", senha: "contemplato", ramal: 1008},
        {login: "malu.contemplato", senha: "contemplato", ramal: 1010},
      ]
      
      if(operator.id === 4) {
        let login = epbxLogins[2];

        window.onload = () => {
          window.loginEpbx(login.login, login.senha, login.ramal)
        }
      } else if(operator.id === 1) {
        let login = epbxLogins[4];

        window.onload = () => {
          window.loginEpbx(login.login, login.senha, login.ramal)
        }
      } else if(operator.id === 6) {
        let login = epbxLogins[3];

        window.onload = () => {
          window.loginEpbx(login.login, login.senha, login.ramal)
        }
      } else if(operator.id === 15) {
        let login = epbxLogins[1];

        window.onload = () => {
          window.loginEpbx(login.login, login.senha, login.ramal)
        }
      } else if(operator.id === 18) {
        let login = epbxLogins[0];

        window.onload = () => {
          window.loginEpbx(login.login, login.senha, login.ramal)
        }
      } else if(operator.id === 29) {
        let login = epbxLogins[5];

        window.onload = () => {
          window.loginEpbx(login.login, login.senha, login.ramal)
        }
      } else if(operator.id === 31) {
        let login = epbxLogins[7];

        window.onload = () => {
          window.loginEpbx(login.login, login.senha, login.ramal)
        }
      } else if(operator.id === 32) {
        let login = epbxLogins[6];

        window.onload = () => {
          window.loginEpbx(login.login, login.senha, login.ramal)
        }
      } else { 
        alert("Erro ao realizar login na Talk")
      }
     
    }, []);

    useEffect(() => {
      if (data) {
        setAsyncBoard(false);
        let filtered  = data;

        if (filters.assignees?.length > 0) {
          filtered = filtered.filter((card) => filters.assignees.includes(card.assigneeId));
        }
        if (filters.phone?.length > 0) {
          filtered = filtered.filter((card) => {
            const cardPhones = [card.phone];
            if (card.additionalPhones) {
              cardPhones.push(...card.additionalPhones);
            }
            const match = cardPhones.map(p => p.includes(filters.phone));
            return match?.includes(true);
          });
        }
        if (filters.finalValueMinimum?.length > 0) {
          filtered = filtered.filter((card) => card.finalValue > filters.finalValueMinimum);
        }
        if (filters.finalValueMaximum?.length > 0) {
          filtered = filtered.filter((card) => card.finalValue < filters.finalValueMaximum);
        }
        if (filters.name?.length > 0) {
          filtered = filtered.filter((card) => card.name?.toLowerCase().includes(filters.name?.toLowerCase()));
        }
        setLanes(GenerateLanes(filtered));
        setAsyncBoard(true);
        localStorage.setItem("operatorFilters", JSON.stringify(filters));
      }
    }, [data, filters]);

    const showModalConfirmFinalValuee = useCallback(() => {
      setShowModalConfirmFinalValue((currentShowModalConfirmFinalValue) => !currentShowModalConfirmFinalValue);
    }, []);

    const dropCards = useCallback((cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
      const moveLib = (fromLaneId, toLaneId, cardId) => {
        eventBus.publish({
          type: "MOVE_CARD",
          fromLaneId,
          toLaneId,  
          cardId,
        });
      }

      let status = true;
      if (sourceLaneId === targetLaneId) {
        status = false;
      }
      if (sourceLaneId === "in_progress" && targetLaneId === "backlog") {
        status = false;
      }
      if (sourceLaneId === "in_negotiation" && (targetLaneId === "in_progress" || targetLaneId === "backlog")) {
        status = false;
      }
      if (sourceLaneId === "accepted") {
        status = false;
      }

      if((targetLaneId === "in_negotiation" || targetLaneId === "accepted") && !cardDetails.finalValue  && status) {
        alert ("favor preencher o valor inicial da oferta. caso já tenha preenchido, faça o refresh (F5) dá pagina")
        status = false;
      }

      if((targetLaneId === "accepted") && status){
        setPropsCardDrop(cardDetails);
        setTargetSourceLines({sourceLaneId: sourceLaneId,targetLaneId: targetLaneId})

        showModalConfirmFinalValuee();
        status = false;
      }

      if ((targetLaneId === 'no_response' || targetLaneId === 'denied' || targetLaneId === 'discarted') && status) {
        setPropsCardDrop(cardDetails);
        setTargetSourceLines({sourceLaneId: sourceLaneId,targetLaneId: targetLaneId});
        setModalTabulationOpen(true);
        return false;
      }
      
      if (status === true) {
        async function updateStatus() {
          const response = await updateStatusProposals(targetLaneId, cardId);
          if(response.status === 200){
            moveLib(sourceLaneId, targetLaneId, cardId);
            sendInteract(cardDetails.id, operator.id, moment().format('YYYY-MM-DDTHH:mm:ss'), `alteração de status`, `de ${sourceLaneId} para ${targetLaneId}`, 'auto');
          }else{
            alert("erro ao mover tente novamente mais tarde");
          }
        }
        updateStatus();
      }

      return false;
    }, [eventBus, showModalConfirmFinalValuee, operator]);

    const sendInteract = async (cardId, operatorId, dateHours, interact, detailsInteract, type) => {
      const infosInteract = {
        proposalId: cardId,
        operatorId: operatorId,
        dateHours: dateHours,
        interact: interact,
        details: detailsInteract,
        type,
      }  
      return await createInteract(infosInteract);
    }

    async function moveCard(targetLaneId, type, sourceLaneId, id) {
      const moveLib = (fromLaneId, toLaneId, cardId) => {
        eventBus.publish({
          type: "MOVE_CARD",
          fromLaneId,
          toLaneId,  
          cardId,
        });
      }

      if(type === "marked"){
        selectedCards.forEach(async (card) => {
          const response = await updateStatusProposals(targetLaneId, card.id);
          if(response.status === 200){
            moveLib(card.laneId, targetLaneId, card.id);
            sendInteract(card.id, operator.id, moment().format('YYYY-MM-DDTHH:mm:ss'), `alteração de status`, `de ${card.laneId} para ${targetLaneId}`, 'auto');
          } else {
            alert("erro ao mover tente novamente mais tarde");
          }
          
        });
        setSelectedCards([]);

      } else if(type === "oneMove"){
        const response = await updateStatusProposals(targetLaneId, id);
        if(response.status === 200){
          moveLib(sourceLaneId, targetLaneId, id);
          sendInteract(id, operator.id, moment().format('YYYY-MM-DDTHH:mm:ss'), `alteração de status`, `de ${sourceLaneId} para ${targetLaneId}`, 'auto');
        } else {
          alert("erro ao mover tente novamente mais tarde")
        }
      }
    }

    const assignees = [
      { value: 1, label: 'Contemplato' },
      { value: 4, label: 'BTG/Consort' },
      { value: 5, label: 'Rodobens' },
    ];

    const handleFiltersChange = (filter) => {
      setFilters(prevState => {
        const filters = { ...prevState };
        filters[filter.key] = filter.value;
        return filters;
      })
    }

    const handleOnClickSelectAllCardByColumn = useCallback((cards) => {
      setSelectedCards((allSelectedCards) => {
        const ids = cards.map(card => card.id);
        if (allSelectedCards.some(item => ids.includes(item.id))) {
          return [];
        }
        
        return cards;
      });
    }, []);

    const handleOpenModalSelectTemplate = () => {
      if(selectedCards.length === 0){
        alert("Selecione pelo menos um card")
      } else {
        const uniqueAssigneeIds = [...new Set(selectedCards.map(card => card.assigneeId))];
        if (uniqueAssigneeIds?.length === 1) {
          setAssigneeIdForMessage(uniqueAssigneeIds[0])
          setModalSelectTemplateOpen(true);
        } else {
          alert("Selecione somente um cessionário por vez.")
        }
      }
    }

    const handleEnrichButtonClick = async () => {
      try {
          const response = await enrichClientData(selectedCards.map((card) => card.id));
          if (response.status === 200) {
              alert('contatos enriquecidos com sucesso');
          } else {
              alert('erro ao enriquecer contatos');
          }
      } catch (err) {
          console.log(err);
          alert('erro ao enriquecer contatos');
      }
    }

    const DataBoard = useMemo(() => {
      if (asyncBoard) {
        return (
          <Board 
            style={{
              width: "100%"
            }}
            components={{
              Card: (props) => <CardOperator {...props}  />,
              LaneHeader: (props) => <OperatorBoardLaneHeader {...props} handleOnClickSelectAllCardByColumn={handleOnClickSelectAllCardByColumn}  />
            }}
            data={lanes}
            laneDraggable={false}
            handleDragEnd={dropCards}
            eventBusHandle={(handle) => setEventBus(handle)}
          />
        )
      }
      
      return <h1 className="content-center"> Loading... </h1>
    }, [asyncBoard, lanes, dropCards, handleOnClickSelectAllCardByColumn]);

    return (
        <div>
            <OperatorNavbar/>
            <ModalConfirmFinalValue 
              showModalConfirmFinalValue={showModalConfirmFinalValue} 
              setShowModalConfirmFinalValue={setShowModalConfirmFinalValue}
              showModal={showModalConfirmFinalValuee}
              propsCard={propsCardDrop}
              targetSourceLines={targetSourceLines}
              moveCard={moveCard}
            />

            {modalTabulationOpen && <ModalTabulation
              open={modalTabulationOpen}
              setOpen={setModalTabulationOpen}
              propsCard={propsCardDrop}
              targetSourceLines={targetSourceLines}
              moveCard={moveCard}
              sendInteract={sendInteract}
              operatorId={operator.id}
            />}

            {modalSelectTemplateOpen && <ModalSelectTemplate
              open={modalSelectTemplateOpen}
              setOpen={setModalSelectTemplateOpen}
              proposalIds={selectedCards.map(card => card.id)}
              assigneeId={assigneeIdForMessage}
            />}

          <div>
            <div className='flex items-center flex-col py-2' style={{backgroundColor: "#ecf1f8"}}>
              <div className="flex items-center container mb-2">
                <div>
                  <select
                    id="laneChecked"
                    style={styless.select}
                    value={laneCheck}
                    onChange={(e) => setLaneCheck(e.target.value)}
                  >
                    <option value="in_progress">Em andamento</option>
                  </select>

                  <button
                      className="bg-lightBlue-600 ml-2 text-white active:bg-lightBlue-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        moveCard(laneCheck, "marked")
                      }}
                    >
                      Mover marcados
                  </button>
                </div>
                
                <button
                    className="bg-lightBlue-600 ml-6 text-white active:bg-lightBlue-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleOpenModalSelectTemplate}
                    >
                      Enviar Mensagem
                </button>

                <button
                    className="bg-lightBlue-600 ml-6 text-white active:bg-lightBlue-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleEnrichButtonClick}
                    >
                      Enriquecer Contatos
                </button>
              
              </div>

              <div className="flex items-center container mt-2"> 

                <div className='lg:w-3/12 pr-4' style={{borderRight: '1px dashed #ccc'}}>
                  <p className='text-sm text-blueGray-600'>filtrar cessionários:</p>
                  <Select
                    isMulti
                    options={assignees}
                    placeholder='cessionários'
                    onChange={options => {
                      handleFiltersChange({
                        key: 'assignees',
                        value: options.map(option => option.value),
                      });
                    }}
                    value={
                      assignees.filter(option => filters.assignees?.includes(option.value))
                    }
                    isSearchable={false}
                  />
                </div>

                <div className='lg:w-2/12 px-4' style={{borderRight: '1px dashed #ccc'}}>
                  <p className='text-sm text-blueGray-600'>filtrar por valor da oferta:</p>
                  <div className='flex'>
                    <input
                      type="number"
                      placeholder='valor minimo'
                      className="border-0 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={filters.finalValueMinimum}
                      onChange={(event) => {
                        handleFiltersChange({
                          key: 'finalValueMinimum',
                          value: event.target.value,
                        });
                      }}
                    />
                    <input
                      type="number"
                      placeholder='valor máximo'
                      className="border-0 ml-2 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={filters.finalValueMaximum}
                      onChange={(event) => {
                        handleFiltersChange({
                          key: 'finalValueMaximum',
                          value: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className='lg:w-2/12 px-4' style={{borderRight: '1px dashed #ccc'}}>
                  <p className='text-sm text-blueGray-600'>filtrar por telefone:</p>
                  <input
                    type="number"
                    placeholder='telefone principal ou adicional'
                    className="border-0 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={filters.phone}
                    onChange={(event) => {
                      handleFiltersChange({
                        key: 'phone',
                        value: event.target.value,
                      });
                    }}
                  />
                </div>

                <div className='lg:w-2/12 px-4' style={{borderRight: '1px dashed #ccc'}}>
                  <p className='text-sm text-blueGray-600'>filtrar por nome:</p>
                  <input
                    type="text"
                    placeholder='nome cliente'
                    className="border-0 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={filters.name}
                    onChange={(event) => {
                      handleFiltersChange({
                        key: 'name',
                        value: event.target.value,
                      });
                    }}
                  />
                </div>

                <div className='lg:w-2/12 px-4' style={{borderRight: '1px dashed #ccc'}}>
                  <p className='text-sm text-blueGray-600'>ordenar por:</p>
                  <select
                    id="orderBy"
                    style={styless.select}
                    // value={laneCheck}
                    // onChange={(e) => setLaneCheck(e.target.value)}
                  >
                    <option value="finalValueDesc">Valor de Oferta Decrescente</option>
                  </select>
                </div>

                
              </div>
            </div>
           
            <div className="flex operator-board" style={{height: 'calc(100vh - 140px)'}}>
              {DataBoard}
            </div>

           </div>
        </div>
    )
}