import React from "react";
import { DataGrid } from '@material-ui/data-grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import ModalAssign from "components/Modals/ModalAssign";
import ModalAssignToOperator from "components/Modals/ModalAssignToOperator";

import { createProposals, updateOperator, startOperation } from 'services/proposal.service';
import { getOwnedClusters, getConsultingClusters } from "services/quota.service";

import adminApi from 'services/adminApi';

const columns = [
  {
    field: 'strategy',
    headerName: 'estratégia',
    type: 'string',
    width: 120,
  },
  {
    field: 'rating',
    headerName: 'adesão',
    type: 'string',
    width: 110,
  },
  {
    field: 'intervalVF',
    headerName: 'ticket',
    type: 'string',
    width: 150,
  },
  {
    field: 'intervalDuration',
    headerName: 'prazo (m)',
    type: 'string',
    width: 130,
  },
  {
    field: 'administratorName',
    headerName: 'administradora',
    type: 'string',
    width: 150,
  },
  {
    field: 'numberQuotas',
    headerName: '# cotas',
    type: 'string',
    width: 105,
  },
  {
    field: 'assigneeName',
    headerName: 'cessionário',
    type: 'string',
    width: 130,
  },
];

const columnsOwned = [...columns, {
  field: 'consultantName',
  headerName: 'consultor',
  type: 'string',
  width: 150,
}];

const columnsOperator = [...columns, {
  field: 'operatorName',
  headerName: 'operador',
  type: 'string',
  width: 130,
}];



const columnsIntermediation = [
  {
    field: 'strategy',
    headerName: 'estratégia',
    type: 'string',
    width: 120,
    hide: true,
  },
  {
    field: 'intervalScore',
    headerName: 'score',
    type: 'string',
    width: 95,
  },
  {
    field: 'intervalEntryOnCredit',
    headerName: 'entrada/crédito',
    type: 'string',
    width: 160,
  },
  {
    field: 'intervalPortionOnCredit',
    headerName: 'parcela/crédito',
    type: 'string',
    width: 155,
  },
  {
    field: 'intervalFinanceFee',
    headerName: 'taxa financeira',
    type: 'string',
    width: 150,
  },
  {
    field: 'intervalEffectiveCost',
    headerName: 'custo efetivo',
    type: 'string',
    width: 150,
  },
  {
    field: 'credit',
    headerName: 'crédito',
    type: 'string',
    width: 100,
  },
  {
    field: 'type',
    headerName: 'tipo',
    type: 'string',
    width: 90,
  },
  {
    field: 'numberQuotas',
    headerName: '# cotas',
    type: 'string',
    width: 105,
  },
  {
    field: 'assigneeId',
    headerName: 'cessionário',
    type: 'string',
    width: 130,
  },
  {
    field: 'operatorId',
    headerName: 'operador',
    type: 'string',
    width: 120,
  }
];

export default function TableClusters({ type }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [showModalAssign, setShowModalAssign] = React.useState(false);
  const [showModalOperator, setShowModalOperator] = React.useState(false);
  const [selectedClusters, setSelectedClusters] = React.useState();
  const [clusters, setClusters] = React.useState();
  const [consultingClusters, setConsultingClusters] = React.useState();

  const getData = async () => {
    setIsLoading(true);
    const clusters = await getOwnedClusters();
    const consulting = await getConsultingClusters();
    setClusters(clusters);
    setConsultingClusters(consulting);
    setIsLoading(false);
  }

  const updateData = async ({ quotaIdList, assigneeId, consultantId, operatorId, typeModal }) => {
    setIsLoading(true);
    console.log({ quotaIdList, assigneeId, consultantId, operatorId, typeModal })
    try {
      if (typeModal === 'operator') {
        await updateOperator(quotaIdList, operatorId);
      } else {
        await createProposals(quotaIdList, assigneeId, consultantId);
      }
      await getData();
    } catch {
      console.log('erro')
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    getData();
  }, [])

  const setOwnedSelection = (newSelection) => {
    const selected = [];
    newSelection.forEach(selectedIndex => {
      selected.push(clusters.find(cluster => cluster.id === selectedIndex))
    });
    setSelectedClusters(selected);
  }

  const setConsultingSelection = (newSelection) => {
    const selected = [];
    newSelection.forEach(selectedIndex => {
      selected.push(consultingClusters.find(cluster => cluster.id === selectedIndex))
    });
    setSelectedClusters(selected);
  }

  // deletar essa variável e adaptar no clusters, quando for mandado em produção
  const clustersFAKEIntermediation = adminApi.generateClusterIntermediation();
  // deletar e adaptar quando for mandar para produção
  const setFAKESelectionIntermediation = (newSelection) => {
    const selected = [];
    newSelection.forEach(selectedIndex => {
      selected.push(clustersFAKEIntermediation.find(cluster => cluster.id === selectedIndex))
    });
    setSelectedClusters(selected);
  }

  const handleOpenModalAssign = () => {
    if (selectedClusters?.length > 0)
      setShowModalAssign(true);
    else
      alert('selecione pelo menos um agrupamento')
  }

  const handleOpenModalOperator = () => {
    if (selectedClusters?.length > 0)
      setShowModalOperator(true);
    else
      alert('selecione pelo menos um agrupamento')
  }

  const handleStartOperation = async (quotaIdList) => {
    console.log(quotaIdList);

    try {
      await startOperation(quotaIdList);
      alert('Operação iniciada');
      window.location.reload();
    } catch (err) {
      if (err.status === 400) {
        alert(`${err.data}, por favor, reinicie a página.`);
      } else {
        alert(err.data);
      }
    }
  }

  const handleModalSave = async ({ assigneeId, consultantId, operatorId, numberQuotas, typeModal } = {}) => {
    const totalQuotasSelected = selectedClusters.map(cluster => cluster.numberQuotas).reduce((accumulator, next) => accumulator + next);

    if (numberQuotas === 0) {
      alert('digite o número de cotas');
      return;
    } else if (numberQuotas > totalQuotasSelected) {
      alert(`número de cotas digitado (${numberQuotas}) maior que número disponível (${totalQuotasSelected})`);
      return;
    }
    if (typeModal === 'assignee' && !assigneeId) {
      alert('selecione um cessionário e um consultor');
      return;
    } else if (typeModal === 'operator' && !operatorId) {
      alert('selecione um operador');
      return;
    }

    let numberAssigned = 0;
    const quotaIdList = [];
    for (let i = 0; i < selectedClusters?.length; i++) {
      const cluster = selectedClusters[i];
      for (let j = 0; (j < cluster.quotaIds?.length && numberAssigned < numberQuotas); j++) {
        const quotaId = cluster.quotaIds[j];
        quotaIdList.push(quotaId);
        numberAssigned++;
      }
    }
    if (quotaIdList?.length > 0) {
      await updateData({ quotaIdList, assigneeId, consultantId, operatorId, typeModal });
    }

    setShowModalAssign(false);
    setShowModalOperator(false);
  }

  const handleModalSaveAssignToOperator = async ({ numberQuotas } = {}) => {
    const totalQuotasSelected = selectedClusters.map(cluster => cluster.numberQuotas).reduce((accumulator, next) => accumulator + next);

    if (numberQuotas === 0) {
      alert('digite o número de cotas');
      return;
    } else if (numberQuotas > totalQuotasSelected) {
      alert(`número de cotas digitado (${numberQuotas}) maior que número disponível (${totalQuotasSelected})`);
      return;
    }

    let numberAssigned = 0;
    const quotaIdList = [];
    for (let i = 0; i < selectedClusters?.length; i++) {
      const cluster = selectedClusters[i];
      for (let j = 0; (j < cluster.quotaIds?.length && numberAssigned < numberQuotas); j++) {
        const quotaId = cluster.quotaIds[j];
        quotaIdList.push(quotaId);
        numberAssigned++;
      }
    }
    if (quotaIdList?.length > 0) {
      await handleStartOperation(quotaIdList);
      // await updateData({quotaIdList, assigneeId, consultantId, operatorId, typeModal});
    }

    setShowModalAssign(false);
    setShowModalOperator(false);
  }

  const handleAssignOperator = () => {
    alert('nao implementado');
  }

  const handleInitAutoOperation = () => {
    alert('operação autônoma iniciada para cotas do agrupamento selecionado.');
  }

  if (isLoading) {
    return (
      <div className='h-screen flex justify-center items-center'>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
        <div className="rounded-t bg-blueGray-100 mb-0 px-6 py-6">
          <div className="text-center flex">
            <h6 className="text-blueGray-800 text-left text-xl font-bold flex-grow">agrupamentos {type === "owned" ? 'da minha base' : 'atribuidos a mim'}</h6>
            <div>
              {type === "owned" ?
                <div>
                  <button
                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleOpenModalAssign}
                  >
                    Atribuir agrupamento
                  </button>
                </div>
                :
                <div>
                  <button
                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleAssignOperator}
                  >
                    Atribuir Operador
                  </button>
                  <button
                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleOpenModalOperator}
                  >
                    Iniciar Operação Hibrida
                  </button>
                  <button
                    className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleInitAutoOperation}
                  >
                    Iniciar Operação Autônoma
                  </button>
                </div>
              }
              {showModalAssign ? <ModalAssign setShowModalAssign={setShowModalAssign} handleModalSave={handleModalSave} selectedClusters={selectedClusters} /> : null}
              {showModalOperator ? <ModalAssignToOperator setShowModalOperator={setShowModalOperator} handleModalSave={handleModalSaveAssignToOperator} selectedClusters={selectedClusters} /> : null}
            </div>
          </div>
        </div>
        <div className="flex-auto px-6 lg:px-6 py-6">
          <h2 className="text-blueGray-600 text-sm mb-3 font-bold uppercase">
            Clusterização - Estratégia de quitação e carrego
          </h2>
          <DataGrid
            checkboxSelection={true}
            autoHeight
            rows={type === "owned" ? clusters : consultingClusters}
            columns={type === "owned" ? columnsOwned : columnsOperator}
            hideFooter
            disableColumnMenu
            onSelectionModelChange={type === "owned" ? setOwnedSelection : setConsultingSelection}
          />
          <h2 className="text-blueGray-600 text-sm mt-6 mb-3 font-bold uppercase">
            Clusterização - Estratégia de intermediação
          </h2>
          <DataGrid
            checkboxSelection={true}
            autoHeight
            rows={clustersFAKEIntermediation}
            columns={columnsIntermediation}
            hideFooter
            disableColumnMenu
            onSelectionModelChange={setFAKESelectionIntermediation}
          />
        </div>
      </div>
    </>
  );
}