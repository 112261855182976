import { useEffect } from "react";
import Chart from "chart.js";
import { v4 as uuidv4 } from 'uuid';

export default function DiscountChart() {

    const getAcceptProbability = (discount) => {
        const curv = ((-0.0006 * (discount ** 3)) + (0.1258 * (discount ** 2)) - (8.8253 * discount) + 237.75) / 100;
        return curv > 1 ? 100 : (curv * 100 * 0.5);
    };

    const generateData = () => {
        const data = {
            labels: [],
            datasets: [{
                label: 'probabilidade de aceite por percentual de deságio',
                data: [],
            }]
        };
        for (let i = 10; i <= 100; i = i + 10) {
            data.labels.push(i);
            data.datasets[0].data.push(getAcceptProbability(i));
        }
        return data;
    }

    const data = generateData();
    const id = uuidv4();

    useEffect(() => {
        const config = {
            type: 'line',
            data: data,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Probabilidade de aceite por percentual de deságio'
                    },
                    legend: {
                        position: 'bottom',
                    },
                },
                responsive: true,
            },
        };

        let ctx = document.getElementById(id).getContext("2d");
        new Chart(ctx, config);

    }, [id, data]);

    return (
        <>
        <div className="p-4 h-250-px">
            <canvas id={id}></canvas>
        </div>
        </>
      );
}