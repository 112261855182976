import React from 'react'

import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';

const styless = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    textAlign: "center",
    borderRadius: 10,
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };


export default function CardUser({email, role}) {
    const [showModalDeleteUser, setShowModalDeleteUser] = React.useState(false);
    if(role === "manager"){
        role = "Gerente"
    } else if(role === "operator"){
        role = "Operador"
    } else {
        return role
    }

    const showModal = () => {
        setShowModalDeleteUser(!showModalDeleteUser);
      };

    const ModalDeleteUser = () => {
        return (
            <Modal
              open={showModalDeleteUser}
              onClose={showModal}
            >
              <Box sx={{ ...styless, width: 400 }}>
                <h2 className="text-lg font-bold">Tem Certeza que deseja deletar?</h2> <br/>

                <p> Email: {email}</p>
                <p> Perfil: {role}</p> <br/>
                
                <button 
                className="bg-green-400 h-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                    DeleteUser();
                    showModal();
                }}>
                    SIM 
                </button>   

                <button 
                className="bg-red-500 h-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={showModal}>
                    NÃO
                </button>   
              </Box>
            </Modal>
        )
    }

    const DeleteUser = () => {
        console.log("Usuario Deletado")
    }

    return (
        <>
         <ModalDeleteUser/>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">

                <div className="flex px-4 lg:px-6 py-6">
                    <div className="flex flex-col w-1/2 mr-4">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left py-3"
                            htmlFor="email"
                        >
                            email:
                        </label>
                       <p>{email}</p>
                    </div>
                    <div className="flex flex-col w-1/2">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left py-3"
                            htmlFor="cessionario"
                        >
                            perfil:
                        </label>
                        <p>{role}</p>
                    </div>
                    {/* <div className="flex flex-col w-1/2">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left py-3"
                            htmlFor="cessionario"
                        >
                            Status:
                        </label>
                        <p style={{color: "rgba(205, 194, 20)"}}>Aguardando</p>
                    </div> */}
                    {/* <button
                        className="bg-red-500 h-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={showModal}
                        >
                        Remover
                    </button> */}

                </div>

                

        </div>
    </>
    )
}


