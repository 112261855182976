import api from "./api";

export const getTemplatesScheduling = async () => {
    try {
        const response = await api.get(`/templates-scheduling/`)
        return response?.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const createTemplateScheduling = async (templateScheduling) => {
    try {
        const response = await api.post(`/templates-scheduling/`, templateScheduling);
        return response?.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const deleteTemplatecheduling = async (id) => {
    try {
        const response = await api.delete(`/templates-scheduling/${id}`);
        return response?.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}