import moment from "moment";

const daysBetweenDates = (initialDate, endDate) => {
    const date = [];
    let currentDate = moment(initialDate).format('YYYY-MM-DD');
    while(moment(currentDate) <= moment(endDate)){
        date.push(currentDate);
        currentDate = moment(currentDate).add(1, 'days').format('YYYY-MM-DD');
    }
    return date;
}

const generators = {
    daysBetweenDates,
}
export default generators;