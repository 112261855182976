import { useContext, useEffect, useState } from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import formatters from "../../utils/formatters";

import ModalCardOperator from '../../components/Modals/ModalCardOperator';

import OperatorContext from '../../components/Context/OperatorContext';

const styless = {
    div: {
      background: "#FFF",
      borderRadius: 5,
      marginBottom: 5,
      padding: 10,
      fontSize: 13,
      cursor: 'grab',
      maxWidth: 250,
    },
  };

export default function CardOperator(props) {
  const { selectedCards, handleCheckCard } = useContext(OperatorContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setChecked] = useState(() => {
    return !!selectedCards.find((selectedCard) => selectedCard.id === props.id);
  });
  
  useEffect(() => {
    setChecked(!!selectedCards.find((selectedCard) => selectedCard.id === props.id));
  }, [selectedCards, props.id]);

  const handleCheckChange = () => {
    setChecked(!checked);
    handleCheckCard({
      id: props.id,
      laneId: props.laneId,
      assigneeId: props.assigneeId,
    });
  }
    return (
        <>
          {isModalOpen && <ModalCardOperator
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            card={props}
          /> }

          <div style={ props.laneId === 'accepted' ? { ...styless.div, opacity: 0.6 } : { ...styless.div }}>
            <header className='flex items-center'>

              {props.laneId !== "accepted" && (
                <Checkbox 
                  className="cursor-pointer"
                  color="primary"
                  checked={checked}
                  style={{ padding: 0 }}
                  onChange={handleCheckChange}
                /> 
              )}
              
              <strong>
                {props.name} 
              </strong>
           </header>

           <div style={{ whiteSpace: 'pre-wrap', marginTop: 4 }}
             onClick={() => setIsModalOpen(true)}
           >
              <p><b>telefone:</b> {props.phone}</p>
              {props.finalValue && <p><b>valor da oferta:</b> {formatters.financeReal(props.finalValue)}</p>}
              {props.laneId === 'no_response' &&
                <>
                  <p><b>enriquecido em: </b> {props.dataEnrichmentDate ? moment(props.dataEnrichmentDate).format('DD/MM/YY HH:mm') : 'não enriquecido'}</p>
                </>
              }

              <>
                <p><b>ultimo contato em: </b> {props.lastContactDate ? moment(props.lastContactDate).format('DD/MM/YY HH:mm') : 'não contatado'}</p>
              </>

              <p style={{ marginTop: 6 }}>
                última atualização: {moment(props.updatedAt).format('DD/MM/YY HH:mm')}
              </p>
              
           </div>
         </div>
        </>
    )
}
