import React, { useContext } from "react";
import { DataGrid } from '@material-ui/data-grid';

import AdminContext from "components/Context/AdminContext";
import DiscountChart from "components/Charts/DiscountChart";
import { postCompany } from "../../services/company.service";
import ModalAddRow from "../Modals/ModalAddRow";

const columns = [
  {
    field: 'from',
    headerName: 'De',
    editable: true
  },
  {
    field: 'to',
    headerName: 'Até',
    editable: true,
  },
];

const columnsFine = [
  {
    field: 'from',
    headerName: 'De',
    editable: true
  },
  {
    field: 'to',
    headerName: 'Até',
    editable: true,
  },
  {
    field: 'value',
    headerName: 'Multa (%)',
    type: 'number',
    editable: true,
    width: 150,
  },
];

const columnsDiscount = [
  {
    field: 'from',
    headerName: 'De (m)',
    editable: true,
    width: 100,
  },
  {
    field: 'to',
    headerName: 'Até (m)',
    editable: true,
    width: 110,
  },
  {
    field: 'value',
    headerName: 'Deságio (%)',
    editable: true,
    width: 140,
  },
];

export default function CardSettings() {
  const adminContext = useContext(AdminContext);
  const settings = adminContext?.company.settings;

  const [princing, setPricing] = React.useState(
  {
    estimatedShotPercentage: settings.pricing.estimatedShotPercentage * 100,
    finePercentageIntervals: settings.pricing.finePercentageIntervals,
    discountPercentageIntervals: settings.pricing.discountPercentageIntervals,
    maximumTIR: settings.pricing.maximumTIR * 100,
    minimumTicketOfferValue:  settings.pricing.minimumTicketOfferValue,
    minimumTrasferValue: settings.pricing.minimumTrasferValue,
    minumumTIR: settings.pricing.minumumTIR * 100,
    transferRate: settings.pricing.transferRate * 100,
    transferRateVF: settings.pricing.transferRateVF * 100,
    transferRatePNL: settings.pricing.transferRatePNL * 100,
    // maximumDiscount: settings.pricing.maximumDiscount * 100,
    sourcingRateCancel: settings.pricing.sourcingRateCancel * 100,
    sourcingRateAcquittance: settings.pricing.sourcingRateAcquittance * 100,
  }
);

  const [clusterization, setClusterization] = React.useState(settings.clusterization);
  // indices select values datagrid
  const [intervalValue, setIntervalValue] = React.useState([]);
  const [intervalDiscount, setIntervalDiscount] = React.useState([]);
  const [intervalDuration, setIntervalDuration] = React.useState([]);
  const [intervalScore, setIntervalScore] = React.useState([]);
  const [intervalP1, setIntervalP1] = React.useState([]);
  const [intervalP2, setIntervalP2] = React.useState([]);
  const [intervalP3, setIntervalP3] = React.useState([]);
  const [intervalP4, setIntervalP4] = React.useState([]);
  const [intervalCredit, setIntervalCredit] = React.useState([]);
  const [discountClient, setDiscoutClient] = React.useState([]);
  const [fine, setFine] = React.useState([]);
  // Modal addRow
  const [showModalAddRow, setShowModalAddRow] = React.useState(false);
  const [showModalAddRowInter, setShowModalAddRowInter] = React.useState(false);
  const [showModalAddRowDiscount, setShowModalAddRowDiscount] = React.useState(false);
  const [showModalAddRowFine, setShowModalAddRowFine] = React.useState(false);

  const generateRowsClusters = (type) => {
    const rows = [];
    const items = clusterization.intervals;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type === type)
        rows.push({
          id: i,
          from: items[i].from,
          to: items[i].to
        });
    }
    return rows
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let body = settings;
    body.pricing = princing;
    body.pricing.minumumTIR = princing.minumumTIR / 100;
    body.pricing.maximumTIR = princing.maximumTIR / 100;
    // body.pricing.maximumDiscount = princing.maximumDiscount / 100;
    body.pricing.estimatedShotPercentage = princing.estimatedShotPercentage / 100;
    body.pricing.transferRate = princing.transferRate / 100;
    body.pricing.transferRateVF = princing.transferRateVF / 100;
    body.pricing.transferRatePNL = princing.transferRatePNL / 100;
    body.pricing.sourcingRateCancel = princing.sourcingRateCancel / 100;
    body.pricing.sourcingRateAcquittance = princing.sourcingRateAcquittance / 100;
    body.clusterization = clusterization;

    await postCompany(body);

    window.location.reload(true);
  }
  

  

  const generateRowsFine = () => {
    const rows = [];
    const items = princing.finePercentageIntervals || [];
    for (let i = 0; i < items.length; i++) {
      rows.push({
        id: i,
        from: items[i].from,
        to: items[i].to,
        value: items[i].value,
      });
    }
    return rows
  }

  const generateRowsDiscount = () => {
    const rows = [];
    const items = princing.discountPercentageIntervals || [];
    for (let i = 0; i < items.length; i++) {
      rows.push({
        id: i,
        from: items[i].from,
        to: items[i].to,
        value: items[i].value,
      });
    }
    return rows
  }

  const deleteRow = (type) => {
    
    if(type === "discount"){   
    let items = princing.discountPercentageIntervals || [];
    const arrRows = [];
    const cloneRows = JSON.parse(JSON.stringify(generateRowsDiscount()));

    discountClient.forEach((row) => {
      const rowIndex = cloneRows.findIndex((rowIndex) => rowIndex.id === row);
      cloneRows.splice(rowIndex, 1);
    })

    cloneRows.forEach((row) => {
      arrRows.push({to: row.to, from: row.from, value: row.value})
    })

    items.reverse();

    for (let i = items.length - 1; i >= 0; i--){
        items.splice(i, 1);
    }

      items = arrRows.concat(...Object.values(items))

      setPricing({...princing, discountPercentageIntervals: items})
    }

    if(type === "fine"){   
      let items = princing.finePercentageIntervals || [];
      const arrRows = [];
      const cloneRows = JSON.parse(JSON.stringify(generateRowsFine()));
  
      fine.forEach((row) => {
        const rowIndex = cloneRows.findIndex((rowIndex) => rowIndex.id === row);
        cloneRows.splice(rowIndex, 1);
      })
  
      cloneRows.forEach((row) => {
        arrRows.push({to: row.to, from: row.from, value: row.value})
      })
  
      items.reverse();
  
      for (let i = items.length - 1; i >= 0; i--){
          items.splice(i, 1);
      }
  
        items = arrRows.concat(...Object.values(items))

        setPricing({...princing, finePercentageIntervals: items})
      }   
  } 

  const editCellFine = (cell, items) => {

    items.forEach((item) => {
      if(item.id === cell.id ){
        item[cell.field] = cell.value
      }
    })
    setPricing({...princing, finePercentageIntervals: items})
  }

  const editCellDiscount = (cell, items) => {
    items.forEach((item) => {
      if(item.id === cell.id ){
        item[cell.field] = cell.value
      }
    })
    setPricing({...princing, discountPercentageIntervals: items})
  }

  const editcellClusters = (cell, items, State) => {
    items.forEach((item) => {
      if(item.id === cell.id ){
        item[cell.field] = cell.value
      }
    })

      let arr = []
      if(State === "value") {
        items.forEach((i) => {
          arr.push({to: i.to, from: i.from, type: 'value'})
        })

        const clusters = clusterization.intervals;

        clusters.reverse();
  
        for (let i = clusters.length - 1; i >= 0; i--){
          let item = clusters[i];
          if(item.type === "value"){
            clusters.splice(i, 1);
          }
        }
        let arrMerge = arr.concat(...Object.values(clusters));
        setClusterization({...clusterization, intervals: arrMerge});
      }

      if(State === 'discount'){
        items.forEach((i) => {
          arr.push({to: i.to, from: i.from, type: 'discount'})
        })

        const clusters = clusterization.intervals;
        clusters.reverse();
  
        for (let i = clusters.length - 1; i >= 0; i--){
          let item = clusters[i];
          if(item.type === 'discount'){
            clusters.splice(i, 1);
          }
        }

        let arrMerge = arr.concat(...Object.values(clusters));
        setClusterization({...clusterization, intervals: arrMerge});
      }
      
      if(State === 'duration') {
        items.forEach((i) => {
          arr.push({to: i.to, from: i.from, type: 'duration'})
        })

        const clusters = clusterization.intervals;
        clusters.reverse();
  
        for (let i = clusters.length - 1; i >= 0; i--){
          let item = clusters[i];
          if(item.type === 'duration'){
            clusters.splice(i, 1);
          }
        }

        let arrMerge = arr.concat(...Object.values(clusters));
        setClusterization({...clusterization, intervals: arrMerge});
      }
  }

  const deleteRowDatagrid = (type) => {

    let arrCluster = clusterization.intervals
    
        if(type === 'cancel'){
          if(intervalValue.length > 0) {
            const arrRowsValue = [];
            const cloneRowsValue = JSON.parse(JSON.stringify(generateRowsClusters('value')))
  
            intervalValue.forEach((row) => {
              const rowIndex = cloneRowsValue.findIndex((rowIndex) => rowIndex.id === row);
              cloneRowsValue.splice(rowIndex, 1);
            })
        
            cloneRowsValue.forEach((row) => {
              arrRowsValue.push({from: row.from, to: row.to, type: "value"})
            })
  
                arrCluster.reverse();
          
                for (let i = arrCluster.length - 1; i >= 0; i--){
                  let item = arrCluster[i];
                  if(item.type === 'value'){
                    arrCluster.splice(i, 1);
                  }
                }
  
            arrCluster = arrRowsValue.concat(...Object.values(arrCluster));
          }
  
          if(intervalDiscount.length > 0) {
            const arrRowsDiscount = [];
            const cloneRowsDiscount = JSON.parse(JSON.stringify(generateRowsClusters('discount')))
  
            intervalDiscount.forEach((row) => {
              const rowIndex = cloneRowsDiscount.findIndex((rowIndex) => rowIndex.id === row);
              cloneRowsDiscount.splice(rowIndex, 1);
            })
        
            cloneRowsDiscount.forEach((row) => {
              arrRowsDiscount.push({from: row.from, to: row.to, type: "discount"})
            })
  
                arrCluster.reverse();
          
                for (let i = arrCluster.length - 1; i >= 0; i--){
                  let item = arrCluster[i];
                  if(item.type === 'discount'){
                    arrCluster.splice(i, 1);
                  }
                }
  
            arrCluster = arrRowsDiscount.concat(...Object.values(arrCluster));
          }
  
          if(intervalDuration.length > 0) {
            const arrRowsDuration = [];
            const cloneRowsDuration = JSON.parse(JSON.stringify(generateRowsClusters('duration')))
  
            intervalDiscount.forEach((row) => {
              const rowIndex = cloneRowsDuration.findIndex((rowIndex) => rowIndex.id === row);
              cloneRowsDuration.splice(rowIndex, 1);
            })
        
            cloneRowsDuration.forEach((row) => {
              arrRowsDuration.push({from: row.from, to: row.to, type: "duration"})
            })
  
                arrCluster.reverse();
          
                for (let i = arrCluster.length - 1; i >= 0; i--){
                  let item = arrCluster[i];
                  if(item.type === 'duration'){
                    arrCluster.splice(i, 1);
                  }
                }
  
            arrCluster = arrRowsDuration.concat(...Object.values(arrCluster));
          }
        }

        if(type === 'intermediation'){
          const inter = [{type: 'score', state: intervalScore}, {type: 'p1', state: intervalP1}, {type: 'p2', state: intervalP2}, {type: 'p3', state: intervalP3}, {type: 'p4', state: intervalP4}, {type: 'credit', state: intervalCredit}];

          inter.forEach((itm) => {
            if(itm.state.length > 0){
              const arrRows = [];
              const cloneRows = JSON.parse(JSON.stringify(generateRowsClusters(itm.type)))
              
              itm.state.forEach((row) => {
                const rowIndex = cloneRows.findIndex((rowIndex) => rowIndex.id === row);
                cloneRows.splice(rowIndex, 1);
              })
            
              cloneRows.forEach((row) => {
                arrRows.push({from: row.from, to: row.to, type: itm.type})
              })
  
                arrCluster.reverse();
          
                for (let i = arrCluster.length - 1; i >= 0; i--){
                  let item = arrCluster[i];
                  if(item.type === itm.type){
                    arrCluster.splice(i, 1);
                  }
                }
  
            arrCluster = arrRows.concat(...Object.values(arrCluster));
            }
          })
  
        }

        setClusterization({...clusterization, intervals: arrCluster})
    }

    const handleAddRow = (table, from, to, value) => {
        let arrCluster = clusterization.intervals
        if(table === "value"){
          arrCluster.push({to: to, from: from, type: 'value'})
        }else if(table === "discount"){
          arrCluster.push({to: to, from: from, type: 'discount'})
        } else if(table === "duration"){
          arrCluster.push({to: to, from: from, type: 'duration'})
        } else if(table === "score"){
          arrCluster.push({to: to, from: from, type: 'score'})
        } else if(table === "p1"){
          arrCluster.push({to: to, from: from, type: 'p1'})
        } else if(table === "p2"){
          arrCluster.push({to: to, from: from, type: 'p2'})
        } else if(table === "p3"){
          arrCluster.push({to: to, from: from, type: 'p3'})
        } else if(table === "p4"){
          arrCluster.push({to: to, from: from, type: 'p4'})
        } else if(table === "credit"){
          arrCluster.push({to: to, from: from, type: 'credit'})
        } else if(table === "tableDiscount"){
          const pricingg = princing.discountPercentageIntervals
          pricingg.push({to: to, from: from, value: value})
        } else if(table === "tableFine"){
          const pricingg = princing.finePercentageIntervals
          pricingg.push({to: to, from: from, value: value})
        }
    }

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
        <div className="rounded-t bg-blueGray-700 mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-white text-xl font-bold">Configurações</h6>
            <button
              className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleSubmit}
            >
              Salvar
            </button>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10">
          <form>
            <h2 className="text-blueGray-600 text-sm mt-3 font-bold uppercase">
              Clusterização - Estratégias de quitação e carrego
            </h2>
            
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <h6 className="text-blueGray-600 text-sm mt-3 mb-6">
                  Intervalos de Valor (R$)
                </h6>
                <DataGrid
                  autoHeight
                  rows={generateRowsClusters('value')}
                  columns={columns}
                  checkboxSelection
                  hideFooter
                  disableColumnSelector
                  disableColumnMenu
                  onCellEditCommit={(cell) => editcellClusters(cell, Object.values(generateRowsClusters('value')), "value")}
                  onSelectionModelChange={(itm) => setIntervalValue(itm)}
                />
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <h6 className="text-blueGray-600 text-sm mt-3 mb-6">
                  Intervalos de Deságio (%)
                </h6>
                <DataGrid
                  autoHeight
                  rows={generateRowsClusters('discount')}
                  columns={columns}
                  checkboxSelection
                  hideFooter
                  disableColumnSelector
                  disableColumnMenu
                  onCellEditCommit={(i) => editcellClusters(i, Object.values(generateRowsClusters('discount')), 'discount')}
                  onSelectionModelChange={itm => {setIntervalDiscount(itm)}}
                />
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <h6 className="text-blueGray-600 text-sm mt-3 mb-6">
                  Intervalos de Prazo (meses)
                </h6>
                <DataGrid
                  autoHeight
                  rows={generateRowsClusters('duration')}
                  columns={columns}
                  checkboxSelection
                  hideFooter
                  disableColumnSelector
                  disableColumnMenu
                  onCellEditCommit={(i) => editcellClusters(i, Object.values(generateRowsClusters('duration')), 'duration')}
                  onSelectionModelChange={itm => {setIntervalDuration(itm)}}
                />
              </div>

            </div>
              <div className="mt-4 flex justify-end">
                {showModalAddRow && <ModalAddRow setShowModalAddRow={setShowModalAddRow} handleAddRow={handleAddRow} options={[{value: "value", text: "Intervalos de Valor"}, {value: "discount", text: "Intervalos de Deságio"}, {value: "duration", text: "Intervalos de Prazo"}]} type={"select"}/>}
              <button
                       className="self-end bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 my-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                       type="button"
                       onClick={() => {
                          if(intervalValue.length > 0 || intervalDiscount > 0 || intervalDuration > 0) {
                            deleteRowDatagrid('cancel')
                          }else alert("Selecione pelo menos um intervalo")
                        }}
                     >
                       Excluir
                       </button>
                       <button
                       className="self-end bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 my-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                       type="button"
                       onClick={() => setShowModalAddRow(!showModalAddRow)}
                     >
                       Adicionar novo
                     </button>
              </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <h6 className="text-blueGray-600 text-sm mt-3 mb-3 font-bold uppercase">
              Clusterização - Estratégia de intermediação
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-4/12 px-4">
                <h6 className="text-blueGray-600 text-sm mt-3 mb-6">
                  Intervalos do score (0-9)
                </h6>
                <DataGrid
                  autoHeight
                  rows={generateRowsClusters('score')}
                  columns={columns}
                  checkboxSelection
                  hideFooter
                  disableColumnSelector
                  disableColumnMenu
                  onCellEditCommit={(cell) => editcellClusters(cell, Object.values(generateRowsClusters('score')), "score")}
                  onSelectionModelChange={itm => {setIntervalScore(itm)}}
                />
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <h6 className="text-blueGray-600 text-sm mt-3 mb-6">
                  Entrada/Crédito (%)
                </h6>
                <DataGrid
                  autoHeight
                  rows={generateRowsClusters('p1')}
                  columns={columns}
                  checkboxSelection
                  hideFooter
                  disableColumnSelector
                  disableColumnMenu
                  onCellEditCommit={(i) => editcellClusters(i, Object.values(generateRowsClusters('p1')), 'p1')}
                  onSelectionModelChange={itm => {setIntervalP1(itm)}}
                />
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <h6 className="text-blueGray-600 text-sm mt-3 mb-6">
                  Parcela/Crédito (%)
                </h6>
                <DataGrid
                  autoHeight
                  rows={generateRowsClusters('p2')}
                  columns={columns}
                  checkboxSelection
                  hideFooter
                  disableColumnSelector
                  disableColumnMenu
                  onCellEditCommit={(i) => editcellClusters(i, Object.values(generateRowsClusters('p2')), 'p2')}
                  onSelectionModelChange={itm => {setIntervalP2(itm)}}
                />
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <h6 className="text-blueGray-600 text-sm mt-3 mb-6">
                  Taxa financeira (%)
                </h6>
                <DataGrid
                  autoHeight
                  rows={generateRowsClusters('p3')}
                  columns={columns}
                  checkboxSelection
                  hideFooter
                  disableColumnSelector
                  disableColumnMenu
                  onCellEditCommit={(i) => editcellClusters(i, Object.values(generateRowsClusters('p3')), 'p3')}
                  onSelectionModelChange={itm => {setIntervalP3(itm)}}
                />
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <h6 className="text-blueGray-600 text-sm mt-3 mb-6">
                  Custo efetivo (%)
                </h6>
                <DataGrid
                  autoHeight
                  rows={generateRowsClusters('p4')}
                  columns={columns}
                  checkboxSelection
                  hideFooter
                  disableColumnSelector
                  disableColumnMenu
                  onCellEditCommit={(i) => editcellClusters(i, Object.values(generateRowsClusters('p4')), 'p4')}
                  onSelectionModelChange={itm => {setIntervalP4(itm)}}
                />
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <h6 className="text-blueGray-600 text-sm mt-3 mb-6">
                  Intervalos do Crédito (R$)
                </h6>
                <DataGrid
                  autoHeight
                  rows={generateRowsClusters('credit')}
                  columns={columns}
                  checkboxSelection
                  hideFooter
                  disableColumnSelector
                  disableColumnMenu
                  onCellEditCommit={(i) => editcellClusters(i, Object.values(generateRowsClusters('credit')), 'credit')}
                  onSelectionModelChange={itm => {setIntervalCredit(itm)}}
                />
              </div>
              
            </div>
              <div className="mt-4 flex justify-end">
              <button
                       className="self-end bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 my-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                       type="button"
                       onClick={() => {
                         if(intervalScore.length > 0 || intervalP1 > 0 || intervalP2 > 0 || intervalP3 > 0 || intervalP4 > 0 || intervalCredit > 0) {
                            deleteRowDatagrid('intermediation')
                          }else alert("Selecione pelo menos um intervalo")
                        }}
                     >
                       Excluir
                       </button>
                       <button
                       className="self-end bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 my-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                       type="button"
                       onClick={() => setShowModalAddRowInter(!showModalAddRowInter)}
                     >
                       Adicionar novo
                     </button>
            {showModalAddRowInter && <ModalAddRow setShowModalAddRow={setShowModalAddRowInter} handleAddRow={handleAddRow} options={
              [{value: 'score', text: "Intervalos de Score"}, {value: "p1", text: "Entrada/Crédito"}, {value: "p2", text: "Parcela/Crédito"}, {value: "p3", text: "Taxa financeira"}, {value: "p4", text: "Custo efetivo"}, {value: "credit", text: "Intervalos do Crédito"}]} type={"select"}/>}     
              </div>
                  
            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <h6 className="text-blueGray-600 text-sm mt-3 mb-3 font-bold uppercase">
              Clusterização - Estratégia de cobrança
            </h6>
            <span className="text-blueGray-600 text-sm mt-3 mb-6">
              EM BREVE
            </span>
            
            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <h6 className="text-blueGray-600 text-sm mt-3 mb-3 font-bold uppercase">
              Clusterização - Geral
            </h6>

            <div className="flex flex-wrap">
              <div className="w-full lg:w-3/12 px-4 flex justify-around items-center">
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold">
                    Somente Carrego
                  </label>
                  <input
                    type="checkbox"
                    className="border-0 px-3 mx-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    checked={clusterization.cancelOnly}
                    onChange={(e) => setClusterization({...clusterization, cancelOnly: e.target.checked})}
                  />
              </div>
              {/* <div className="w-full lg:w-3/12 px-4 flex justify-around items-center">
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold">
                    Priorizar Carrego
                  </label>
                  <input
                    type="checkbox"
                    className="border-0 px-3 mx-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    checked={clusterization.prioritizeCancel}
                    onChange={(e) => setClusterization({...clusterization, prioritizeCancel: e.target.checked})}
                  />
              </div>

              <div className="w-full lg:w-3/12 px-4 flex justify-around items-center">
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold">
                    Priorizar Quitação
                  </label>
                  <input
                    type="checkbox"
                    className="border-0 px-3 mx-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    checked={clusterization.prioritizeAcquittance}
                    onChange={(e) => setClusterization({...clusterization, prioritizeAcquittance: e.target.checked})}
                  />
              </div> */}
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <h6 className="text-blueGray-600 text-sm mt-3 mb-3 font-bold uppercase">
              Limites
            </h6>
            <div className="flex flex-wrap">
              
              <div className="w-1/2 flex flex-wrap px-2">

                
                <div className="w-full lg:w-4/12 px-2">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"  >
                      Taxa de Transf. Valor de Face (%)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={princing.transferRateVF}
                      onChange={(e) => setPricing({...princing, transferRateVF: parseFloat(e.target.value)})}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-2">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"  >
                      Taxa de Transf. PNL (%)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={princing.transferRatePNL}
                      onChange={(e) => setPricing({...princing, transferRatePNL: parseFloat(e.target.value)})}
                    />
                  </div>
                </div>

                <div className="w-full">
                  <DiscountChart />
                </div>
              </div>

              <div className="w-1/2 px-2">
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Deságio ao cliente (%)
                  </label>
                <h6 className="text-blueGray-600 text-sm mt-3 mb-6">
                  Percentual de deságio por intervalo de duration das cotas
                </h6>
                <DataGrid
                  autoHeight
                  rows={generateRowsDiscount()}
                  columns={columnsDiscount}
                  checkboxSelection
                  hideFooter
                  disableColumnSelector
                  disableColumnMenu
                  onCellEditCommit={(i) => editCellDiscount(i, Object.values(generateRowsDiscount()))}
                  onSelectionModelChange={itm => {setDiscoutClient(itm)}}
                />
              </div>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                       className="self-end bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 my-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                       type="button"
                       onClick={() => {
                         if(discountClient.length > 0) {
                            deleteRow("discount")
                          }else alert("Selecione pelo menos um intervalo")
                        }}
                     >
                       Excluir
                       </button>
                       <button
                       className="self-end bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 my-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                       type="button"
                       onClick={() => setShowModalAddRowDiscount(!showModalAddRowDiscount)}
                     >
                       Adicionar novo
                     </button>
            {showModalAddRowDiscount && <ModalAddRow setShowModalAddRow={setShowModalAddRowDiscount} handleAddRow={handleAddRow} options={[]} type={"discount"}/>}     
              </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <h6 className="text-blueGray-600 text-sm mt-3 mb-6 font-bold uppercase">
              Configurações ADM
            </h6>
            <div className="flex flex-wrap">

              <div className="w-1/2 flex flex-wrap px-2">


                <div className="w-full lg:w-4/12 px-2">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"  >
                      T.I.R. mínima (%)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={princing.minumumTIR}
                      onChange={(e) => setPricing({...princing, minumumTIR: parseFloat(e.target.value)})}
                    />
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-2">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"  >
                      T.I.R. target (%)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={princing.maximumTIR}
                      onChange={(e) => setPricing({...princing ,maximumTIR: parseFloat(e.target.value)})}
                    />
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-2">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"  >
                      Lance Estimado (%)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={princing.estimatedShotPercentage}
                      onChange={(e) => setPricing({...princing, estimatedShotPercentage: parseFloat(e.target.value)})}
                    />
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-2">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"  >
                    Taxa de Transferência (%)
                  </label>
                  <input
                    type="number"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    value={princing.transferRate}
                    onChange={(e) => setPricing({...princing, transferRate: parseFloat(e.target.value)})}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-2">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"  >
                      Valor Mínimo de Transferência (R$)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={princing.minimumTrasferValue}
                      onChange={(e) => setPricing({...princing, minimumTrasferValue: parseFloat(e.target.value)})}
                    />
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-2">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"  >
                      Taxa de Originação - Carrego (%)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={princing.sourcingRateCancel}
                      onChange={(e) => setPricing({...princing, sourcingRateCancel: parseFloat(e.target.value)})}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-4/12 px-2">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"  >
                      Taxa de Originação - Quitação (%)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={princing.sourcingRateAcquittance}
                      onChange={(e) => setPricing({...princing, sourcingRateAcquittance: parseFloat(e.target.value)})}
                    />
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-2">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"  >
                      Ticket mínimo na oferta (R$)
                    </label>
                    <input
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={princing.minimumTicketOfferValue}
                      onChange={(e) => setPricing({...princing, minimumTicketOfferValue: parseFloat(e.target.value)})}
                    />
                  </div>
                </div>
                
              </div>

              <div className="w-1/2 px-2">
                <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                    Multa (%)
                  </label>
                <h6 className="text-blueGray-600 text-sm mt-3 mb-6">
                  Intervalos percentual: fundo comum pago / valor do crédito
                </h6>
                <DataGrid
                  autoHeight
                  rows={generateRowsFine()}
                  columns={columnsFine}
                  checkboxSelection
                  hideFooter
                  disableColumnSelector
                  disableColumnMenu
                  onCellEditCommit={(i) => editCellFine(i, Object.values(generateRowsFine()))}
                  onSelectionModelChange={itm => {setFine(itm)}}
                />
              </div>
              
            </div>
            <div className="mt-4 flex justify-end">
              {showModalAddRowFine && <ModalAddRow setShowModalAddRow={setShowModalAddRowFine} handleAddRow={handleAddRow} options={[]} type={"fine"}/>}     
              <button
                       className="self-end bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 my-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                       type="button"
                       onClick={() => {
                         if(fine.length > 0) {
                            deleteRow("fine")
                          }else alert("Selecione pelo menos um intervalo")
                        }}
                     >
                       Excluir
                       </button>
                       <button
                       className="self-end bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 my-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                       type="button"
                       onClick={() => setShowModalAddRowFine(!showModalAddRowFine)}
                     >
                       Adicionar novo
                     </button>
              </div>

          </form>
        </div>
      </div>
    </>
  );
}
