import { useEffect, useState } from "react";
import { DataGrid } from '@material-ui/data-grid';
import moment from "moment";
import { CSVLink } from "react-csv";

import formatters from '../../utils/formatters';

const pageSize = 25;
const columns = [
  {
    field: 'contractId',
    headerName: 'contrato',
    type: 'string',
    width: 110,
  },
  {
    field: 'date',
    headerName: 'data',
    type: 'date',
    valueFormatter: (params) => {
      return moment(params.value).format('DD/MM/yyyy');
    },
    width: 120,
  },
  {
    field: 'administrator',
    headerName: 'administradora',
    type: 'string',
    width: 160,
  },
  {
    field: 'consultant',
    headerName: 'consultor',
    type: 'string',
    width: 160,
  },
  {
    field: 'assignee',
    headerName: 'cessionario',
    type: 'string',
    width: 160,
  },
  {
    field: 'group',
    headerName: 'grupo',
    type: 'string',
    width: 100,
  },
  {
    field: 'quota',
    headerName: 'cota',
    type: 'string',
    width: 90,
  },
  {
    field: 'groupEndDate',
    headerName: 'data encerramento',
    type: 'date',
    valueFormatter: (params) => {
      return moment(params.value).format('DD/MM/yyyy');
    },
    width: 180,
  },
  {
    field: 'creditValue',
    headerName: 'valor do crédito',
    type: 'number',
    valueFormatter: (params) => {
      return formatters.financeReal(params.value);
    },
    width: 160,
  },
  {
    field: 'faceValue',
    headerName: 'valor a receber',
    type: 'number',
    valueFormatter: (params) => {
      return formatters.financeReal(params.value);
    },
    width: 160,
  },
  {
    field: 'remainingValue',
    headerName: 'saldo devedor',
    type: 'number',
    valueFormatter: (params) => {
      return formatters.financeReal(params.value);
    },
    width: 160,
  },
  {
    field: 'finalValue',
    headerName: 'valor de compra',
    type: 'number',
    valueFormatter: (params) => {
      return formatters.financeReal(params.value);
    },
    width: 160,
  }
  ,
  {
    field: 'strategy',
    headerName: 'estratégia',
    type: 'string',
    width: 120,
  }
  ,
  {
    field: 'crmStatus',
    headerName: 'status',
    type: 'string',
    width: 160,
  },
];

export default function TableAcquisition({acquisitions}) {
  const [rows, setRows] = useState([]);
    
    useEffect(() => {
      const rowsData = [];
      acquisitions?.forEach((acquisition, index) => {
        const row = {
          id: index,
          contractId: acquisition.contractId,
          date: acquisition.updatedAt,
          owner: acquisition.owner,
          administrator: acquisition.administrator,
          consultant: acquisition.consultant,
          assignee: acquisition.assignee,
          group: acquisition.groupId,
          quota: acquisition.quotaId,
          groupEndDate: acquisition.groupEndDate,
          creditValue: acquisition.creditValue,
          faceValue: acquisition.faceValue,
          remainingValue: acquisition.remainingValue,
          finalValue: acquisition.finalValue,
          strategy: acquisition.strategy,
          crmStatus: acquisition.crmStatus,
        }
        rowsData.push(row);
      });
      setRows(rowsData);
    },[acquisitions]);
          
    return (
        <>
        <div>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            disableColumnMenu
          />
        </div>
        <div  className="flex justify-end items-center mt-4">
            <i className="fas fa-file-download"></i>
            <CSVLink style={{ marginLeft: '4px' }} data={rows} filename={"aquisicoes.csv"} separator={";"}>Export CSV</CSVLink>
          </div>
        </>
    )
}
