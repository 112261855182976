import api from './api';

export const updateFinalValueProposals = async (proposals, id) => {
  try {
      const response = await api.patch(`/proposals/${id}/finalValue`, {
          finalValue: proposals,
      });
      return response?.data;
  } catch (err) {
      console.log(err)
      throw err;
  }
}

export const openVollChat = async (proposalId) => {
  try {
    const response = await api.post(`/messaging/init-chat`, {
      proposalId,
    });
    return response?.data;
  } catch (err) {
      console.log(err)
      throw err;
  }
}


export const updateStatusProposals = async (proposals, id) => {
  try {
      const response = await api.patch(`/proposals/${id}/status`, {
          status: proposals,
      });
      return response;
  } catch (err) {
    const message = err.response;
    return(message)
  }
}

export const updateClientPhoneProposal = async (phone, id) => {
  try {
      const response = await api.patch(`/proposals/${id}/clientPhone`, {
        phone,
      });
      return response?.data;
  } catch (err) {
      console.log(err)
      throw err;
  }
}

export const getAssignedToOperator = async () => {
    try {
        const response = await api.get(`/proposals/assignedToOperator`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const createInteract = async (interact) => {
  try {
    const response = await api.post(`/follow-up/`, interact);
    return response;
  } catch (err) {
    const message = err.response;
    return(message)
  }
}

export const getAllInteracts = async (proposalId) => {
  try {
    const response = await api.get(`/follow-up?proposalId=${proposalId}`);
    return response?.data;
  } catch (err) {
    throw err;
  }
}

export const getOperatorsByStep = async () => {
    try {
      const response = await api.get(`/proposals/operatorByStep`);
      return response?.data;
  } catch (err) {
      console.log(err)
      throw err;
  }
}

export const getTemplatesMessage = async () => {
  try {
    const response = await api.get('/messaging/templates');
    return response?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
}

export const sendMessage = (proposalIds, templateName, assigneeId) => api.post('/messaging/send-message', { proposalIds, templateName, assigneeId })

export function GenerateLanes(props) {
    
    let backlog = props.filter((i) => i.status === "backlog") || [];
  
    let progress = props.filter((i) => i.status === "in_progress") || [];

    let noResponse = props.filter((i) => i.status === "no_response") || [];
  
    let negotiation = props.filter((i) => i.status === "in_negotiation") || [];
  
    let accepted = props.filter((i) => i.status === "accepted") || [];
  
    let denied = props.filter((i) => i.status === "denied") || [];

    let discarted = props.filter((i) => i.status === "discarted") || [];

    return {
      lanes: [
        {
          id: "backlog",
          title: 'Atuar',
          label: 'selecionar todos',
          style: {
            padding: "5px 5px 0px 5px",
          },
          cards: backlog,
        },
        {
          id: "in_progress",
          title: 'Em andamento',
          label: 'selecionar todos',
          style: {
            padding: "5px 5px 0px 5px",
          },
          cards: progress,
        },
        {
          id: "no_response",
          title: 'Sem resposta',
          label: 'selecionar todos',
          style: {
            padding: "5px 5px 0px 5px",
          },
          cards: noResponse,
        },
        {
          id: "in_negotiation",
          title: 'Em negociação',
          label: 'selecionar todos',
          style: {
            padding: "5px 5px 0px 5px",
          },
          cards: negotiation,
        },
        {
          id: "accepted",
          title: 'Aceito',
          label: "",
          style: {
            padding: "5px 5px 0px 5px",
          },
          cards: accepted,
        },
        {
          id: "denied",
          title: 'Recusado',
          label: "",
          style: {
            padding: "5px 5px 0px 5px",
          },
          cards: denied,
        },
        {
          id: "discarted",
          title: 'Descartado',
          label: "",
          style: {
            padding: "5px 5px 0px 5px",
          },
          cards: discarted,
        },
      ],
    };
  }

  export const getChats = async () => new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve([{
        chatId: 4,
        from: 'Luise',
        to: 'Marcos',
        createdAt: '2021-11-04T19:23:21.000Z',
        message: 'Olá Marcos, sou a Luise da contemplato, uma empresa parceira da Rodobens. Gostariamos de fazer uma oferta de compra em sua cota xyz. Pode conversar agora?',
      },
      {
        chatId: 4,
        from: 'Marcos',
        to: 'Luise',
        createdAt: '2021-11-04T19:34:29.000Z',
        message: 'Bom dia. Posso sim. Qual o valor da oferta?',
      },
      {
        chatId: 4,
        from: 'Luise',
        to: 'Marcos',
        createdAt: '2021-11-04T19:35:19.000Z',
        message: 'Nossa oferta para sua cota é de R$ 15.420,00.',
      },
      {
        chatId: 4,
        from: 'Marcos',
        to: 'Luise',
        createdAt: '2021-11-04T19:57:39.000Z',
        message: 'Pode ser.',
      }]);
    }, 300);
  });