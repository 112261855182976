import {useState} from 'react';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal'
import { sendMessage } from '../../services/operator.service';


export default function ModalSelectTemplate(props) {

  const styless = {
    select: {
        fontSize: "14px",
        width: '85%',
        border: "1px solid black",
        borderRadius: 5,
        margin: 10,
        padding: 4,
      },
      box: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        textAlign: "center",
        borderRadius: 10,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      }
  }

  const templates = [
    {
        value: 'initial-contact',
        description: "Primeiro Contato",
    },
    {
        value: 'first-proposal',
        description: "Primeira Proposta",
    },
    {
        value: 'new-proposal',
        description: "Nova Proposta",
    },
    {
        value: 'proposal-remainder',
        description: "Lembrete de Proposta",
    },
    {
        value: 'proposal-lead',
        description: "Proposta Lead Aquecido",
    },
    {
        value: 'proposal-btg-accepted',
        description: "Proposta Previamente Aceita",
    },
  ];

  const [selected, setSelected] = useState(templates[0].value);

  const handleSendMessage = async () => {
      try {
          const response = await sendMessage(props.proposalIds, selected, props.assigneeId);
          if (response.status === 200) {
              alert('mensagem enviada com sucesso');
              if (selected === 'initial-contact' || selected === 'proposal-lead' || selected === 'first-proposal')
                window.location.reload();
          } else {
              alert('erro ao enviar mensagem');
          }
      } catch (err) {
          console.log(err);
          alert('erro ao enviar mensagem');
      }
  }

  return (
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
      >
        <Box  sx={{ ...styless.box, width: 400 }}>
          <p>Selecione o template da mensagem</p> <br/>
          <p className='text-xs'>selecione no máximo 65 cards por disparo (para evitar bloqueio do número)</p>

          <select
            style={styless.select}
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
          >
            {templates.map((option, index) => (
              <option key={index} value={option.value}> {option.description} </option>
            ))}
          </select>

          { selected === 'initial-contact' && <>
              <p className='text-xs'>obs: para primeiro contato, serão retirados da lista os clientes que já foram impactados por essa mensagem, mantendo
                  clientes que tiveram telefone enriquecido após primeiro contato.</p>
              <p className='text-xs'>geralmente usado para clientes na raia 'atuar', a fim de move-los para 'em andamento' depois</p>
            </>
          }
          { selected === 'first-proposal' && <>
              <p className='text-xs'>obs: para primeira proposta será atribuido por padrao o valor minimo para o valor da oferta e, se contato com sucesso, o card será movido para em negociacao automaticamente</p>
              <p className='text-xs'>geralmente usado para clientes na raia 'em andamento', a fim de move-los para 'em negociação' depois</p>
            </>
          }
          { selected === 'new-proposal' && <>
              <p className='text-xs'>obs: para nova proposta, serão retirados da lista os clientes cujo novo valor de oferta seja menor que o anterior. 
                  o campos de valor de oferta será automaticamente atualizado para o valor máximo após o envio da mensagem</p>
              <p className='text-xs'>geralmente usado para clientes que estavam na raia 'recusado' mas tiveram novos preços e voltaram para 'em negociação', a fim de move-los para 'aceito' depois</p>
            </>
          }
          { selected === 'proposal-remainder' && <>
              <p className='text-xs'>obs: para lembrete de proposta, serão retirados da lista os clientes que já foram impactados por alguma comunicação
                  em massa nos últimos 2 dias</p>
              <p className='text-xs'>geralmente usado para clientes na raia 'em negociação' a certo tempo, a fim de move-los para 'aceito' depois</p>
            </>
          }
          { selected === 'proposal-lead' && <>
              <p className='text-xs'>obs: para lembrete de proposta Lead Aquecido, será enviado somente para contatos que nunca receberam comunicação em massa</p>
            </>
          }

          <div className='mt-4'>
            <button 
              className="bg-blueGray-600 h-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => props.setOpen(false)}>
                Cancelar 
            </button> 

            <button 
              className="bg-lightBlue-600 h-8 ml-4 text-white active:bg-lightBlue-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                handleSendMessage();
                props.setOpen(false);
              }}>
                  Enviar 
              </button>  
          </div> 
      </Box>
    </Modal>
  )
}

