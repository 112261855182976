import React from "react";

import { getUsers } from "services/user.service"

import InviteUserForm from "components/Forms/InviteUserForm";
import CardUser from "components/Cards/CardUser";

export default function ManageUsers() {
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    getUsers().then(users => setUsers(users));
  }, []);  

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <InviteUserForm />

          {users.map((user => (
              <CardUser key={user.id} email={user.email} role={user.role}/>
          )))}
         
        </div>
      </div>
    </>
  );
}
