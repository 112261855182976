import React from "react";

import { getTemplatesByCompanyId, createTemplate, updateTemplate, deleteTemplateById } from "services/templates.service"
import { getTemplatesScheduling, deleteTemplatecheduling } from "services/templates-scheduling.service"
import { getConditionsScheduling } from "services/conditions-scheduling"
import ModalCreateTemplateSchedule from "../../components/Modals/ModalCreateTemplateSchedule";
import ModalCreateTemplate from "../../components/Modals/ModalCreateTemplate";
 
import { DataGrid } from '@material-ui/data-grid';
import { CircularProgress, Checkbox } from "@material-ui/core";

export default function MessageSettings() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = React.useState(false);
  const [isEmailChecked, setIsEmailChecked] = React.useState(false);
  const [isWhatsappChecked, setIsWhatsappChecked] = React.useState(false);
  const [currentTemplate, setCurrentTemplate] = React.useState({});
  const [templates, setTemplates] = React.useState([]);
  const [conditions, setConditions] = React.useState([]);
  const [schedulingTemplates, setSchedulingTemplates] = React.useState([]);
  const [selectRows, setSelectRows] = React.useState([]);
  const [rowTableLength, setRowTableLength] = React.useState(0);

  const nameRef = React.useRef(null);
  const titleRef = React.useRef(null);
  const messageRef = React.useRef(null);
  
  const generateRow = (id, name, condition, frequency, max_attempts, template, status) => ({
    id,
    name,
    condition,
    frequency,
    max_attempts,
    template,
    status,
  });
  
  React.useEffect(() => {
    async function requestAll() {
      const companyTemplates = await getTemplatesByCompanyId();
      const companyTemplatesScheduling = await getTemplatesScheduling();
      const companyConditionsScheduling = await getConditionsScheduling();
      
      let maxConditionsLength = 0;

      const rows = companyTemplatesScheduling?.map(templateScheduling => {
        maxConditionsLength = Math.max(maxConditionsLength, templateScheduling.conditions.length);

        return generateRow(templateScheduling.id, templateScheduling.name, templateScheduling.conditions, templateScheduling.intervalDays, templateScheduling.maximum, templateScheduling.template.name, templateScheduling.status === 1 ? 'Ativado' : 'Desabilitado');
      });

      setRowTableLength(50 * maxConditionsLength);
      // var max = companyConditionsScheduling.reduce(function(a, b) {
      //   return Math.max(a, b);
      // });

      setConditions(companyConditionsScheduling);
      setIsLoading(false);
      setTemplates(companyTemplates);
      setCurrentTemplate(companyTemplates[0]);
      setSchedulingTemplates(rows);
    }

    requestAll();
  }, []);

  const columns = [
    {
      field: 'name',
      headerName: 'nome',
      type: 'string',
      width: 150,
    },
    {
      field: 'condition',
      headerName: 'condição',
      type: 'string',
      width: 190,
      autoHeight: true,
      renderCell: (cell) => {
        console.log(cell.value);
        const conditionsValue = cell.value?.map((itemCell) => {
          const attributeValues = conditions.find((itemCondition) => itemCondition.id === itemCell.attribute);
          const conditionValue = attributeValues.values.find((attributeValue) => attributeValue.id === itemCell.value);

          return (
            <div key={conditionValue?.name} className="flex items-center justify-center leading-none rounded-lg bg-emerald-400 text-trueGray-100 h-8 my-1" style={{ width: '150px' }}>
              <span>{conditionValue?.name}</span>
            </div>
          )
        });
        return (
          <div className="flex flex-col items-center justify-center">
            {
              conditionsValue
            }
          </div>
        )
      }
    },
    {
      field: 'frequency',
      headerName: 'periodicidade (d)',
      type: 'string',
      width: 180,
    },
    {
      field: 'max_attempts',
      headerName: 'envio max',
      type: 'string',
      width: 140,
    },
    {
      field: 'template',
      headerName: 'template',
      type: 'string',
      width: 170,
    },
    {
      field: 'status',
      headerName: 'status',
      type: 'string',
      width: 170,
    },
    {
      field: 'actions',
      headerName: 'ações',
      type: 'string',
      width: 170,
      renderCell: () => (
        <div>
          <i className="fas fa-pen cursor-pointer text-lg"></i>
        </div>
      )
    },
  ];

  React.useEffect(() => {
    if (nameRef.current && titleRef.current && messageRef.current && currentTemplate) {
      nameRef.current.value = currentTemplate.name;
      titleRef.current.value = currentTemplate.title;
      messageRef.current.value = currentTemplate.message;
      setIsEmailChecked(!!currentTemplate.email);
      setIsWhatsappChecked(!!currentTemplate.whatsapp);
    }
  }, [currentTemplate]);

  const deleteRowDatagrid = async (arrayRows) => {
      setIsLoading(true);
      
      const cloneRows = JSON.parse(JSON.stringify(schedulingTemplates))
      
      arrayRows.forEach((row) => {
        const rowIndex = cloneRows.findIndex((rowIndex) => rowIndex.id === row);
        cloneRows.splice(rowIndex, 1);
      });
      
      await deleteTemplatecheduling(arrayRows);
      setSchedulingTemplates(cloneRows); 
      setIsLoading(false);
  }

  const handleOnCreateTemplate = async (template) => {
    setIsTemplateModalOpen(!isTemplateModalOpen);
    setIsLoading(true);
    const { id } = await createTemplate(template);
    
    const createdTemplate = {
      id,
      ...template
    }
    
    setTemplates([...templates, createdTemplate]);
    setCurrentTemplate(createdTemplate);
    setIsLoading(false);
  }
  
  const handleOnSaveTemplate = async () => {
    setIsLoading(true);
    
    const templatesUpdated = templates.map((template) => {
      if (template.id === currentTemplate.id) {
        template.id = currentTemplate.id;
        template.name = nameRef.current.value;
        template.title = titleRef.current.value;
        template.message = messageRef.current.value;
        template.email = isEmailChecked;
        template.whatsapp = isWhatsappChecked;
      }
      
      return template;
    });
    
    await updateTemplate({
      id: currentTemplate.id,
      name: nameRef.current.value,
      title: titleRef.current.value,
      message: messageRef.current.value,
      email: isEmailChecked,
      whatsapp: isWhatsappChecked,
    });
    setTemplates(templatesUpdated);
    setIsLoading(false);
  }
  
  const handleOnSubmit = (templateSchedule) => {
    const row = generateRow(templateSchedule.id, templateSchedule.name, templateSchedule.conditions, templateSchedule.intervalDays, templateSchedule.maximum, templateSchedule.template.id, templateSchedule.status);
    setSchedulingTemplates((allTemplateScheduling) => [...allTemplateScheduling, row]);
  }

  const handleOnDeleteTemplate = async (id) => {
    setIsLoading(true);
    const filteredTemplates = templates.filter((template) => template.id !== id);
    setTemplates(filteredTemplates);
    setCurrentTemplate(filteredTemplates[0]);
    await deleteTemplateById(id);
    const filteredSchedulingTemplates = schedulingTemplates.filter((schedulingTemplate) => schedulingTemplate.template !== id);
    setSchedulingTemplates(filteredSchedulingTemplates);
    setIsLoading(false);
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div className="rounded-t bg-blueGray-700 mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-white text-xl font-bold">Configurações - Comunicação</h6>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-4">
              <form>
                <h2 className="text-blueGray-600 text-sm mt-3 font-bold uppercase">
                  Templates de mensagens
                </h2>

                <hr className="my-4 md:min-w-full" />

                {
                  isLoading ? (
                    <div className="flex flex-col justify-center items-center w-full">
                      <CircularProgress />
                    </div>
                  ) : (
                      !templates?.length ? (
                        <div className="flex flex-col justify-center items-center">
                          <span>Você não possuí templates criados, clique no botão abaixo para criar</span>
                          <button
                            className="my-2 bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() => setIsTemplateModalOpen(!isTemplateModalOpen)}
                          >
                            Criar primeiro template
                          </button>
                          <ModalCreateTemplate
                            onClose={() => setIsTemplateModalOpen(!isTemplateModalOpen)}
                            onCreate={handleOnCreateTemplate}
                            isOpen={isTemplateModalOpen}
                          />
                        </div>
                      ) : (
                        <div className="flex flex-row mt-3">
                          <div className="flex flex-col w-full max-w-210-px border-r max-h-500-px h-full">
                            <ul className="flex w-full flex-col md:overflow-y-auto max-w-210-px min-h-440 h-full">
                              {
                                templates.map((template) => (
                                  <li className="items-center" role="button" key={template.id} onClick={
                                    () => setCurrentTemplate(template)
                                  }>
                                    <span
                                      className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (currentTemplate.id === template.id
                                          ? "text-lightBlue-500 hover:text-lightBlue-600"
                                          : "text-blueGray-700 hover:text-blueGray-500")
                                      }
                                    >
                                      {template.name}
                                    </span>
                                  </li>
                                ))
                              }
                            </ul>
                            <button
                              className="w-full max-w-210-px mx-5 self-end my-2 bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                              type="button"
                              onClick={() => setIsTemplateModalOpen(!isTemplateModalOpen)}
                            >
                              Criar template
                            </button>
                            <ModalCreateTemplate
                              onClose={() => setIsTemplateModalOpen(!isTemplateModalOpen)}
                              onCreate={handleOnCreateTemplate}
                              isOpen={isTemplateModalOpen}
                            />
                          </div>

                          {
                            currentTemplate?.name && (
                            <div className="flex flex-col w-full px-4">
                            <div className="flex w-full flex-row">
                              <div className="w-full">
                                <h6 className="w-full text-blueGray-600 text-sm mt-2 mb-3">Nome do template:</h6>
                                <input type="text" className="w-full" ref={nameRef} defaultValue={currentTemplate?.name}></input>
                              </div>
                            </div>
                            <div className="flex w-full flex-row">
                              <div className="w-full">
                                <h6 className="w-full text-blueGray-600 text-sm mt-6 mb-3">Título:</h6>
                                <input type="text" className="w-full" ref={titleRef} defaultValue={currentTemplate?.title}></input>
                              </div>
                            </div>
                            <div className="flex flex-col">
                              <h6 className="text-blueGray-600 text-sm mt-6 mb-3">Conteúdo:</h6>
                              <textarea rows="4" cols="50" ref={messageRef} defaultValue={currentTemplate?.message} />
                              <div className="flex">
                                <div className="flex flex-col w-full">
                                  <h6 className="text-blueGray-600 text-sm mt-6 mb-3">Canal/meio:</h6>
                                  <div>
                                    <Checkbox
                                      color="primary"
                                      checked={isEmailChecked}
                                      onChange={() => setIsEmailChecked(!isEmailChecked)}
                                    />
                                    <span>E-mail</span>
                                  </div>
                                  <div>
                                    <Checkbox
                                      color="primary"
                                      checked={isWhatsappChecked}
                                      onChange={() => setIsWhatsappChecked(!isWhatsappChecked)}
                                    />
                                    <span>Whatsapp</span>
                                  </div>
                                </div>
                                <div className="flex">
                                  <button
                                    className="self-end bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 my-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={() => handleOnDeleteTemplate(currentTemplate.id)}
                                  >
                                    Excluir
                                  </button>
                                  <button
                                    className="self-end my-2 bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={handleOnSaveTemplate}
                                  >
                                    Salvar
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                            )
                          }
                          
                        </div>
                      )
                  )
                }
                <hr className="mt-6 border-b-1 border-blueGray-300" />
                <h6 className="text-blueGray-600 text-sm mt-6 mb-3 font-bold uppercase">
                  Agendamento / Régua de comunicação
                </h6>

                <div className="flex flex-wrap">
                  <div className="flex flex-col flex-auto px-6 lg:px-6 py-6">
                    <DataGrid
                      autoHeight
                      rows={schedulingTemplates || []}
                      columns={columns}
                      hideFooter
                      rowHeight={rowTableLength}
                      disableColumnMenu
                      checkboxSelection
                      onSelectionModelChange={itm => setSelectRows(itm)}
                    />
                    <div className="flex-row">
                       <button
                       className="self-end bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 my-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                       type="button"
                       onClick={() => {
                         if(selectRows.length > 0){
                            deleteRowDatagrid(selectRows)
                         }else if(selectRows.length === 0){
                            alert("selecione pelo menos um agendamento")
                         }
                       }}
                     >
                      Excluir
                      </button>
                      <ModalCreateTemplateSchedule
                        isOpen={isModalOpen}
                        templates={templates}
                        onClose={() => setIsModalOpen(!isModalOpen)}
                        onSubmit={handleOnSubmit}
                      />
                      <button
                        className="self-end bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 my-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setIsModalOpen(!isModalOpen)}
                      >
                       Adicionar novo
                     </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
