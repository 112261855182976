import {useState} from 'react';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal'
import moment from 'moment';


export default function ModalTabulation(props) {

  const styless = {
    select: {
        fontSize: "12px",
        width: '85%',
        border: "5px solid transparent",
        borderRadius: 5,
        padding: 0,
      },
      box: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        textAlign: "center",
        borderRadius: 10,
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      }
  }

  const [selected, setSelected] = useState();
  const [details, setDetails] = useState();

  const noResponsetabulationOptions = [
    { description: "Sem resposta - Telefone inválido/não corresponde" },
    { description: "Sem resposta - Ligação" },
    { description: "Sem resposta - Mensagem" },
    { description: "Sem resposta - Outro" },
  ];

  const deniedtabulationOptions = [
    { description: "Recusada - Valor baixo" },
    { description: "Recusada - Grupo perto da data de encerramento" },
    { description: "Recusada - Não quis receber proposta" },
    { description: "Recusada - Não contatar novamente" },
    { description: "Recusada - Cliente irá reativar consórcio" },
    { description: "Recusada - Recebeu a proposta e parou de responder" },
    { description: "Recusada - Outros" },
  ];

  const discardedtabulationOptions = [
    { description: "Descartada - Cota já vendida" },
    { description: "Descartada - Já recebeu/vai receber valor" },
    { description: "Descartada - Outros" },
  ];

  const getOptionsByLane = {
    no_response: noResponsetabulationOptions,
    denied: deniedtabulationOptions,
    discarted: discardedtabulationOptions,
  }

  return (
      <Modal
        open={props.open}
        onClose={() => props.setOpen(false)}
      >
        <Box  sx={{ ...styless.box, width: 400 }}>
          <p>Selecione o motivo da alteração de status</p> <br/>

          <select
            style={styless.select}
            value={selected}
            onChange={(e) => setSelected(e.target.value)}
          >
            {getOptionsByLane[props.targetSourceLines.targetLaneId] && getOptionsByLane[props.targetSourceLines.targetLaneId].map((option, index) => (
              <option key={index} value={option.description}> {option.description} </option>
            ))}
          </select>

          <textarea
            value={details}
            style={{padding: 5, width: 250}}
            onChange={e => {setDetails(e.target.value)}}
          />

          <div className='mt-4'>
            <button 
              className="bg-blueGray-600 h-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => props.setOpen(false)}>
                Cancelar 
            </button> 

            <button 
              className="bg-lightBlue-600 h-8 ml-4 text-white active:bg-lightBlue-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => {
                props.sendInteract(props.propsCard.id, props.operatorId, moment().format('YYYY-MM-DDTHH:mm:ss'), selected, details, 'manual');
                props.moveCard(props.targetSourceLines.targetLaneId, "oneMove", props.targetSourceLines.sourceLaneId, props.propsCard.id);
                props.setOpen(false);
              }}>
                  Registrar 
              </button>  
          </div> 
      </Box>
    </Modal>
  )
}

