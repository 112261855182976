/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Link } from "react-router-dom";

export default function OperatorNavbar() {

    return (
        <>
        <nav className="flex justify-center flex-wrap items-center py-3 navbar-expand-lg bg-white shadow">
          <div className="container flex flex-wrap items-center justify-between">
            <div className="">
              <Link
                to="/"
              >
                <img
                    alt="logo"
                    src={require("assets/img/logo_contemplato_azul.png").default}
                    className="w-1/2"
                  />
              </Link>
            </div>
            
            <div
              className={
                "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none"}
              id="example-navbar-warning"
            >
              <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
  
                <li className="flex items-center">
                  <a
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    href="https://www.linkedin.com/company/contemplato?ref=saas-index-navbar"
                    target="_blank"
                  >
                    <i className="text-blueGray-400 fab fa-linkedin text-lg leading-lg " />
                    <span className="lg:hidden inline-block ml-2">LinkedIn</span>
                  </a>
                </li>
  
                <li className="flex items-center">
                  <a
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    href="https://www.facebook.com/contemplato?ref=saas-index-navbar"
                    target="_blank"
                  >
                    <i className="text-blueGray-400 fab fa-facebook text-lg leading-lg " />
                    <span className="lg:hidden inline-block ml-2">Share</span>
                  </a>
                </li>
  
                <li className="flex items-center">
                  <a
                    className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                    href="https://www.instagram.com/contemplato?ref=saas-index-navbar"
                    target="_blank"
                  >
                    <i className="text-blueGray-400 fab fa-instagram text-lg leading-lg " />
                    <span className="lg:hidden inline-block ml-2">Instagram</span>
                  </a>
                </li>
  
                <li className="flex items-center">
                  <Link
                    to="/"
                  >
                      <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                        type="button"
                      >
                        Sair
                      </button>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </>
    )
}


