import api from './api';

export const getTemplatesByCompanyId = async () => {
    try {
        const response = await api.get(`/templates`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const createTemplate = async (template) => {
    try {
        const response = await api.post(`/templates`, template);
        return response?.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const updateTemplate = async (template) => {
    try {
        const response = await api.put(`/templates`, template);
        return response?.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const deleteTemplateById = async (id) => {
    try {
        const response = await api.delete(`/templates/${id}`);
        return response?.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}