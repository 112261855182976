import { useEffect, useState } from "react";
import { DataGrid } from '@material-ui/data-grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getFollowUpsAggregated } from '../../services/proposal.service';
import { CSVLink } from "react-csv";

const columns = [
  {
    field: 'update',
    headerName: 'atualização',
    type: 'number',
    width: 360,
  },
  {
    field: 'count',
    headerName: 'quantidade',
    type: 'string',
    width: 130,
  },
];

export default function TableUpdates({initialDate, endDate}) {
  const [rows, setRows] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const aggregatedFollowUpsData = await getFollowUpsAggregated(initialDate, endDate);
      setData(aggregatedFollowUpsData);
      setIsLoading(false);
    }
    getData();
  },[initialDate, endDate]);

  
    
    useEffect(() => {
      const rowsData = [];
      data?.forEach((item, index) => {
        const row = {
          id: index,
          update: item.interact,
          count: item.quantity,
        }
        rowsData.push(row);
      });
      setRows(rowsData);
    },[data]);

    if (isLoading) {
      return (
        <div className='h-screen flex justify-center items-center'>
          <CircularProgress />
        </div>
      );
    }
          
    return (
        <>
        <div>
          <DataGrid
            autoHeight
            density="compact"
            rows={rows}
            columns={columns}
            disableColumnMenu
            hideFooter
          />
          <div  className="flex justify-end items-center mt-4">
            <i className="fas fa-file-download"></i>
            <CSVLink style={{ marginLeft: '4px' }} data={rows} filename={"operacao.csv"} separator={";"}>Export CSV</CSVLink>
          </div>
        </div>
        </>
    )
}
