import React, { useState } from "react";

import { verifyRecoverPassword } from '../../services/auth.service';

export default function VerifyRecoverPassword() {
  const [userData, setUserData] = useState({
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const queryParams = new URLSearchParams(window.location.search);

  const handleInputChange = (e) => {
    setUserData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userData.password === "") {
      setErrorMessage("preencha sua senha");
    } else {
      try {
        setIsLoading(true);
        await verifyRecoverPassword(queryParams.get('token'), userData.password);
        setIsLoading(false);
        setErrorMessage("nova senha criada com sucesso");
      } catch (err) {
        setIsLoading(false);
        setErrorMessage(err.message);
      }
    }
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="flex-auto px-4 lg:px-10 py-10">
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      password
                    </label>
                    <input
                      type="password"
                      name="password"
                      defaultValue={userData.password}
                      onChange={handleInputChange}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="password"
                    />
                  </div>

                  <div className="text-center mt-6">
                    <button
                        className="bg-yellow-500 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Criar nova senha
                      </button>
                  </div>

                  {errorMessage && (
                    <p> {errorMessage} </p>
                  )}

                  {isLoading ? (<p> carregando ...</p>) : null}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

