import api from "./api";

export const getConditionsScheduling = async () => {
    try {
        const response = await api.get(`/conditions-scheduling/`);
        return response?.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}