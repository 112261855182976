import api from './api';

export const getQuotas = async () => {
    try {
        const response = await api.get(`/quotas`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getQuotasToAssignOperator = async () =>  api.get(`/quotas?toAssignOperator=true`);

export const deleteQuotas = async (quotaIds) => {
    try {
        const response = await api.post(`/quotas/bulk-delete`, { quotaIds });
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const analyseQuotas = async () => {
    try {
        const response = await api.get(`/quotas/analyse`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getOwnedClusters = async () => {
    try {
        const response = await api.get(`/quotas/owned/clusterize`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getConsultingClusters = async () => {
    try {
        const response = await api.get(`/quotas/consulting/clusterize`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

  