import { useRef, useState } from "react";


export default function ModalAddNotes({setIsOpen, handleSave, proposalId}) {
    const interactRef = useRef(null);
    const [notes, setNotes] = useState();

    const handleSaveButton = () => {
        if (notes) {
          const interact = interactRef.current?.value;
          
          handleSave(proposalId, notes, interact);
        }
        else {
          alert('preencha com algum valor para salvar')
        }
    }

  return (
    <>
        <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto absolute inset-0 z-50 left-20 outline-none focus:outline-none"
            >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">

                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-2xl px-6 font-semibold">
                    Adicionar Nota
                    </h3>
                    <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setIsOpen()}
                    >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                    </span>
                    </button>
                </div>

                <div className="relative px-3 py-6 flex-auto">
                <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"  >
                      Problema em:
                    </label>
                    <select ref={interactRef}>
                      <option value="documento de identificação">Documento de identificação</option>
                      <option value="comprovante bancário">Comprovante bancário</option>
                      <option value="contrato de cessão">Contrato de cessão</option>
                      <option value="dados bancários cadastrados">Dados bancários cadastrados</option>
                      <option value="dados cadastrais">Dados cadastrais</option>
                      <option value="selfie">Selfie</option>
                    </select>
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold my-2"  >
                      Anotação
                    </label>
                    <textarea
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </div>
                    
                </div>

                <div className="flex items-center justify-end px-3 py-3 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setIsOpen()}
                    >
                    Fechar
                    </button>
                    <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSaveButton}
                    >
                    Salvar
                    </button>
                </div>
                </div>
            </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}