import { useEffect, useState } from "react";
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';

import formatters from '../../utils/formatters';
import TableAcquisition from "components/Tables/TableAcquisition";
import DateFilter from "components/Filters/DateFilter";

import { getOwnedAcquisitions } from '../../services/proposal.service';

export default function Acquisitions() {
  moment.locale('pt-br');
  const [initialDate, setInitialDate] = useState(moment().startOf('day').subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment().startOf('day'));

  const [ownedAcquisitions, setOwnedAcquisitions] = useState([]);
  const [headlines, setHeadlines] = useState({
    totalQuotasCount: 0,
    totalOfferValue: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const owned = await getOwnedAcquisitions(initialDate, endDate);
      setHeadlines({
        totalQuotasCount: owned.length,
        totalOfferValue: owned.map(item => item.finalValue).length > 0 ? owned.map(item => item.finalValue).reduce((prev, next) => prev + next) : 0,
      });
      setOwnedAcquisitions(owned);
      setIsLoading(false);
    }
    getData();
  },[initialDate, endDate]);

  if (isLoading) {
    return (
      <div className='h-screen flex justify-center items-center'>
        <CircularProgress />
      </div>
    );
  }
  
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
            <div className="relative flex flex-col shadow-lg rounded-lg bg-white border-0">
              <div className="rounded-t bg-blueGray-700 mb-0 p-4">
                <div className="flex justify-between">
                  <div className="text-white">
                    <h6 className="text-xl font-bold">Aquisição</h6>
                    <div>
                      <p># total cotas: <b>{headlines.totalQuotasCount}</b></p>
                      <p>$ total comprado: <b>{formatters.financeReal(headlines.totalOfferValue)}</b></p>
                    </div>
                  </div>
                  <DateFilter initialDate={initialDate} setInitialDate={setInitialDate} endDate={endDate} setEndDate={setEndDate}/>
                </div>
              </div>
              <div className="flex-auto p-4">
                  <TableAcquisition acquisitions={ownedAcquisitions}/>
              </div>
            </div>
        </div>
      </div>
    </>
  );
}
