import React, { useCallback, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import OperatorContext from "../components/Context/OperatorContext";

import DashboardOperator from 'views/operator/DashboardOperator';

export default function Operator() {
  const [operator] = useState(JSON.parse(localStorage.getItem("user")));
  const [selectedCards, setSelectedCards] = useState([]);

  const handleCheckCard = useCallback((card) => {
    setSelectedCards((allSelectedCards) => {
      const selectedIds = allSelectedCards.map(selected => selected.id);
      if (selectedIds.includes(card.id)) {
        return [...allSelectedCards.filter((selected) => selected.id !== card.id)];
      }

      return [...allSelectedCards, card];
    })
  }, []);

  const operatorData = {
    operator,
    handleCheckCard,
    setSelectedCards,
    selectedCards,
  }

  return (
    <OperatorContext.Provider value={operatorData}>
      <Switch>
        <Route path="/operator/dashboard" exact component={DashboardOperator} />
        <Redirect from="/operator" to="/operator/dashboard" />
      </Switch>
    </OperatorContext.Provider>
  )
}

