import { useState, useEffect, useContext } from "react";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import formatters from "../../utils/formatters";
import { getProposalById } from "../../services/proposal.service";
import OperatorContext from "../../components/Context/OperatorContext"
import {
  updateFinalValueProposals,
  getAllInteracts,
  createInteract,
  updateClientPhoneProposal,
  openVollChat,
} from "../../services/operator.service";

const styless = {
  box: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    textAlign: "center",
    borderRadius: 10,
    boxShadow: 24,
    fontSize: 14,
    pt: 2,
    px: 4,
    pb: 3,
  },
  input: {
    color: "#000",
    backgroundColor: "#EEE",
    border: "5px solid transparent",
    borderRadius: 5,
    padding: 5,
  },
  select: {
    fontSize: "12px",
    width: "100%",
    backgroundColor: "#EEE",
    border: "5px solid transparent",
    borderRadius: 5,
    padding: 0,
  },
};

let renderOnly = true;

export default function ModalCardOperator(props) {
  const { operator } = useContext(OperatorContext);
  const [showHistoryNocontent, setShowHistoryNocontent] = useState("");
  const [showHistoryInteract, setShowHistoryInteract] = useState(false);
  const [historyInteract, setHistoryInteract] = useState([]);
  const [finalValue, setFinalValue] = useState(0);
  const [truePhone, setTruePhone] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [callPhone, setCallPhone] = useState("");
  const [hasCall, setHasCall] = useState(false);

  useEffect(() => {
    if (data.id && data.operatorId && renderOnly) {
      renderOnly = false;
      window.windowHandler.on(
        "atendimento-onChamadaGlobalId",
        function (event, ramal, chamada, globalId) {
          // console.log(`event: `, event)
          // console.log(`ramal: `, ramal)
          // console.log(`chamada: `, chamada)
          // console.log(`globalId: `, globalId)
          // console.log("windowHandlerrr onChamada function consultar")
          // setHasCall(true);
          sendInteract(
            data.id,
            operator.id,
            moment().format("YYYY-MM-DDTHH:mm:ss"),
            `ligação realizada`,
            `${globalId}`,
            "manual"
          );
        }
      );
      window.windowHandler.on(
        "atendimento-onAtendido",
        function (event, ramal, chamada) {
          // console.log(`event: `, event)
          // console.log(`ramal: `, ramal)
          // console.log(`chamada: `, chamada)
          // console.log(`globalId: `, chamada.GlobalId)
          // console.log("windowHandlerrr onAtendido")
          sendInteract(
            data.id,
            operator.id,
            moment().format("YYYY-MM-DDTHH:mm:ss"),
            `ligação atendida`,
            `${chamada.GlobalId}`,
            "manual"
          );
        }
      );
      window.windowHandler.on(
        "atendimento-onDesliga",
        function (event, ramal, chamada) {
          // console.log(`event: `, event)
          // console.log(`ramal: `, ramal)
          // console.log(`chamada: `, chamada)
          // console.log(`globalId: `, chamada.GlobalId)
          // console.log("windowHandlerrr onDesliga")
          // setHasCall(false);
          sendInteract(
            data.id,
            operator.id,
            moment().format("YYYY-MM-DDTHH:mm:ss"),
            `ligação finalizada`,
            `${chamada.GlobalId}`,
            "manual"
          );
        }
      );
    }
  }, [data.id, operator]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const response = await getProposalById(props.card.id);

      setData(response);
      setCallPhone(response.quota.client.phone);
      setTruePhone(response.quota.client.phone);
      setFinalValue(() => {
        if (response.finalValue == null) {
          return 0;
        } else {
          return response.finalValue;
        }
      });
      setIsLoading(false);
    };
    getData();
  }, [props]);

  const getFollowUps = async (proposalId) => {
    const response = await getAllInteracts(proposalId);
    setHistoryInteract(response);
    if (response.length > 0) {
      setShowHistoryInteract(!showHistoryInteract);
      setShowHistoryNocontent("");
    }
    if (response.length <= 0) {
      setShowHistoryInteract(!showHistoryInteract);
      setShowHistoryNocontent("Nenhuma interação existente");
    }
  };

  const handleInputFinalValueChange = (e) => {
    const inputValue = e?.target?.value;
    const numberValue = parseFloat(inputValue.replace(/\D/g, "")) / 100;
    setFinalValue(formatters.financeReal(numberValue));
  };
  const handleInputTruePhoneChange = (e) => {
    const inputValue = e?.target?.value;
    setTruePhone(inputValue);
  };

  const sendInteract = async (
    cardId,
    operatorId,
    dateHours,
    interact,
    detailsInteract,
    type
  ) => {
    const infosInteract = {
      proposalId: cardId,
      operatorId: operatorId,
      dateHours: dateHours,
      interact: interact,
      details: detailsInteract,
      type,
    };
    return await createInteract(infosInteract);
  };

  const handleSubmit = () => {
    if (data.finalValue !== finalValue) {
      updateFinalValueProposals(finalValue, data.id);
      sendInteract(
        data.id,
        data.operatorId,
        moment().format("YYYY-MM-DDTHH:mm"),
        `alteração valor de oferta`,
        `de ${
          data.finalValue ? formatters.financeReal(data.finalValue) : 0
        } para ${finalValue}`,
        "manual"
      );
    }
    if (data.quota.client.phone !== truePhone) {
      updateClientPhoneProposal(truePhone, data.id);
      sendInteract(
        data.id,
        data.operatorId,
        moment().format("YYYY-MM-DDTHH:mm"),
        `alteração de telefone principal`,
        `de ${data.quota.client.phone || ""} para ${truePhone}`,
        "manual"
      );
    }
    setSnackbarMessage("valor alterado com sucesso");
    setSnackbarOpen(true);
  };

  const handleCopyLinkOnboarding = () => {
    navigator.clipboard.writeText(data.urlOnboarding);
    setSnackbarMessage("link onboarding copiado, já pode colar");
    setSnackbarOpen(true);
  };

  const handleClose = () => {
    props.setIsModalOpen(!props.isModalOpen);
  };

  const handleChatButton = async () => {
    try {
      await openVollChat(data.id);
      setSnackbarMessage("chat aberto com sucesso");
    } catch {
      setSnackbarMessage("erro ao abrir chat, contate suporte");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const assignees = {
    1: "Contemplato",
    4: "BTG/Consort",
    5: "Rodobens",
  };

  if (isLoading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        message={snackbarMessage}
      />

      <Modal
        open={props.isModalOpen}
        onClose={handleClose}
        style={{ overflow: "scroll" }}
      >
        <Box sx={{ ...styless.box }}>
          <div>
            <span className="font-bold">{data.quota.client.name}</span>
            <p>telefone principal: {data.quota.client.phone}</p>
            <p>
              telefones adicionais:{" "}
              {data.quota.client.additionalPhones?.join(" / ")}
            </p>
            <br />
            <p>Administradora: {data.quota.administrator}</p>
            <p>
              Grupo: {data.quota.groupId} / Cota: {data.quota.quotaId}
            </p>
            <p>
              Fundo comum a receber:{" "}
              {formatters.financeReal(data.quota.toReceiveCommonFundValue)}
            </p>
            <p>
              Montante já pago: {formatters.financeReal(data.quota.paidValue)}
            </p>
            <p>
              Valor do crédito: {formatters.financeReal(data.quota.creditValue)}
            </p>
            <p>
              Valor da parcela:{" "}
              {formatters.financeReal(data.quota.monthlyInstallmentValue)}
            </p>
            <p>
              Saldo Devedor: {formatters.financeReal(data.quota.remainingValue)}
            </p>
            <p>
              Data de vencimento:{" "}
              {moment(data.quota.groupEndDate).format("DD/MM/YYYY")}
            </p>
            <p>Tipo: {data.quota.assetType}</p>
            <br />
            <p>Oferta mínima: {formatters.financeReal(data.minimumValue)}</p>
            <p>Oferta máxima: {formatters.financeReal(data.maximumValue)}</p>
            <p>Cessionário: {assignees[data.assigneeId]}</p>
            {data.urlOnboarding ? (
              <>
                <p
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    marginTop: 10,
                  }}
                  onClick={handleCopyLinkOnboarding}
                >
                  copiar link onboarding
                </p>
                {data.crmStatus ? (
                  <p>status CRM: {data.crmStatus}</p>
                ) : (
                  <p>sem status CRM</p>
                )}
              </>
            ) : (
              <p style={{ marginTop: 10 }}>sem onboarding</p>
            )}
          </div>{" "}
          <br />
          <div className="flex justify-evenly">
            <div className="flex flex-col">
              <h3 style={{ fontWeight: "bold" }}>Atualizar dados:</h3>
              <p>atualize abaixo sempre que houver alteração:</p>
              <div className="flex flex-col pt-2">
                <span>Valor da oferta: </span>
                <input
                  type="text"
                  name="finalValue"
                  style={{ ...styless.input, marginBottom: 10 }}
                  value={formatters.financeReal(finalValue)}
                  onChange={handleInputFinalValueChange}
                />
              </div>
              <div className="flex flex-col">
                <span>Telefone com retorno: </span>
                <input
                  type="text"
                  name="truePhone"
                  style={{ ...styless.input }}
                  value={truePhone}
                  onChange={handleInputTruePhoneChange}
                />
              </div>
            </div>

            <div className="flex flex-col">
              <h3 style={{ fontWeight: "bold" }}>Acesso para o chat voll</h3>
              {/* <p>escolha o operador da lista:</p>
              <select
                  id="operatorName"
                  className='mt-4'
                  style={styless.select}
                  value={chatOperatorName}
                  onChange={(e) => setChatOperatorName(e.target.value)}
                >
                  <option value="0">Adriele</option>
                  <option value="1">Debora</option>
                  <option value="2">Fernando</option>
                  <option value="3">Luise</option>
                  <option value="4">Dev</option>
                </select>

              <select
                  id="operatorName"
                  className='mt-4'
                  style={styless.select}
                  value={callPhone}
                  onChange={(e) => setCallPhone(e.target.value)}
                >
                  <option value={parseInt(data.quota.client.phone)}>{data.quota.client.phone}</option>
                  {data.quota.client.additionalPhones?.map((phone, index) => (
                      <option key={index} value={parseInt(phone)}>{phone}</option>
                  ))}
                </select> */}

              <button
                style={{ fontSize: 10 }}
                className="bg-blueGray-600 mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleChatButton}
              >
                abrir Voll chat
              </button>

              <h3 className="mt-4" style={{ fontWeight: "bold" }}>
                Ligação via Talk
              </h3>
              <div className="flex-row">
                <button
                  style={{ fontSize: 10, width: 50, marginRight: 20 }}
                  className="mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={async () => {
                    window.hasCall
                    ? 
                    alert("Uma ligação em andamento")
                    : window.startCall(callPhone);
                  }}
                >
                  <img src="/images/assets/iconPhone.png" alt="phone" />
                </button>

                <button
                  style={{ fontSize: 10, width: 50 }}
                  className=" mt-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs p-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={async () => {
                    window.hasCall
                      ? 
                      window.desligarCall()
                      : alert("Nenhuma ligação em andamento")
                  }}
                >
                  <img
                    src="/images/assets/iconPhoneRed.png"
                    alt="phone"
                    style={{ color: "red" }}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="py-4">
            <button
              className="h-8 active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={() => getFollowUps(data.id)}
            >
              {showHistoryInteract ? (
                <> ocultar históricos de interações {historyInteract.length}</>
              ) : (
                <> exibir históricos de interações {historyInteract.length}</>
              )}
            </button>

            {showHistoryInteract && (
              <div style={{height: 90, overflow: "auto"}}>

                {showHistoryNocontent !== "" && (
                  <p style={{ marginBottom: 10 }}> {showHistoryNocontent} </p>
                )}
                {historyInteract.length > 0 && (
                  <ul style={{ marginBottom: 10 }}>
                    {historyInteract.map((followup, index) => (
                      <li key={index}>
                        {" "}
                        <strong>{followup.interact}</strong> {followup.details}{" "}
                        em{" "}
                        {moment(followup.dateHours).format(
                          "HH:mm:ss DD/MM/YYYY"
                        )}
                      </li>
                    ))}
                  </ul>
                )}


              </div>
            )}
          </div>
          <div>
            <button
              className="bg-blueGray-600 h-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleClose}
            >
              FECHAR
            </button>

            <button
              disabled={data.status === "accepted" || data.status === "denied"}
              className="bg-lightBlue-600 h-8 ml-4 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleSubmit}
            >
              Salvar
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
