import React, { useState } from "react";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { inviteUser } from 'services/user.service';

export default function InviteUserForm() {
    const [userData, setUserData] = useState({
        email: 'email@dominio.com',
        role: 'operator',
      });
      const [errorMessage, setErrorMessage] = useState('');
      const [isLoading, setIsLoading] = useState(false);
    
      const handleInputChange = (e) => {
        console.log(e)
        setUserData((prevState) => {
          return {
            ...prevState,
            [e.target.name]: e.target.value,
          };
        });
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (userData.email === "" || userData.role === "") {
          setErrorMessage("preencha o email e perfil");
        } else {
          try {
            setErrorMessage(null);
            setIsLoading(true);
            await inviteUser(userData.email, userData.role);
            setIsLoading(false);
          } catch (err) {
            setIsLoading(false);
            setErrorMessage(err.message);
          }
        }
      };

    return (
    <>
    <form onSubmit={handleSubmit}>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div className="rounded-t bg-blueGray-700 mb-0 px-6 py-6">
                <div className="text-center flex justify-between">
                    <h6 className="text-white text-xl font-bold">convidar usuário</h6>
                    <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="submit"
                        >
                        Enviar
                    </button>
                </div>
            </div>
            
                <div className="flex px-4 lg:px-6 py-6">
                    <div className="flex flex-col w-1/2 mr-4">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left py-3"
                            htmlFor="email"
                        >
                            email:
                        </label>
                        <input
                            name="email"
                            type="email"
                            className="border-0 px-3 mx-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                            defaultValue={userData.email}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="flex flex-col w-1/2">
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left py-3"
                            htmlFor="role"
                        >
                            perfil
                        </label>
                        <FormControl className="w-full" >
                            <Select
                              id="role"
                              name="role"
                              onChange={handleInputChange}
                              value={userData.role}
                            >
                              <MenuItem value='operator'>operador</MenuItem>
                              <MenuItem value='manager'>gerente</MenuItem>
                            </Select>
                          </FormControl>
                    </div>
                </div>
            
            {errorMessage && (
                    <p> {errorMessage} </p>
                  )}
            {isLoading ? (<p> carregando ...</p>) : null}
        </div>
        </form>
    </>
    )
}