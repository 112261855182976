const financeReal = number => number?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

const toNumber = number => financeReal(number).substring(3);

const percentage = number => `${(number * 100).toFixed(2)}%`

const toDecimalNumber = number => Number(Math.round(number+'e2')+'e-2');

const jsonToQuerystring = (jsonObject) => {
    let querystring = '';
    for (let [key, value] of Object.entries(jsonObject)) {
        querystring = querystring.concat(`${key}=${value}&`)
    }
    return querystring;
}

const cnpjMaskByValue = (cnpjValue) => {
    let cnpj = cnpjValue;
  
    cnpj = cnpj.replace(/\D/g, '');
  
    cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2');
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
    cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2');
  
    return cnpj;
  }
  
  const cpfMaskByValue = (cpfValue) => {
    let cpf = cpfValue;
  
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  
    return cpf;
  }


const formatters = {
    financeReal,
    toDecimalNumber,
    toNumber,
    jsonToQuerystring,
    cpfMaskByValue,
    cnpjMaskByValue,
    percentage
}
export default formatters;