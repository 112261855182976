import React, {useContext} from "react";
// import moment from "moment";

import CircularProgress from '@material-ui/core/CircularProgress';

// import CardStats from "components/Cards/CardStats.js";
import AdminContext from "components/Context/AdminContext";

// import adminApi from "../../services/adminApi";

export default function HeaderStats() {
  const adminContext = useContext(AdminContext);

  const [isLoading, setIsLoading] = React.useState(true);
  // const [overviewData, setOverviewData] = React.useState();

  const operators = adminContext.operators;

  React.useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      // const today = moment().startOf('day');
      // const yesterday = moment().startOf('day').subtract(1, 'days');
      // const data =  await adminApi.getOverviewData(yesterday.format('YYYY-MM-DD'), today.format('YYYY-MM-DD'));

      // const todayData = data.find(item => moment(item.date).isSame(today));
      // const todayQuotas = todayData?.quotas || 0;
      // const todayVolume = todayData?.volume || 0;
      // const numberOperators = operators.length || 0;

      // const yesterdayData = data.find(item => moment(item.date).isSame(yesterday));
      // const yesterdayQuotas = yesterdayData?.quotas || 0;
      // const yesterdayVolume = yesterdayData?.volume || 0;

      // const evolutionQuotas = yesterdayQuotas !== 0 ? ((todayQuotas - yesterdayQuotas) / yesterdayQuotas * 100).toPrecision(3) : 0;
      // const evolutionVolume = yesterdayVolume !==0 ? ((todayVolume - yesterdayVolume) / yesterdayVolume * 100).toPrecision(3): 0;

      // const overviewData = {
      //   todayQuotas,
      //   todayVolume,
      //   evolutionQuotas,
      //   evolutionVolume,
      //   numberOperators,
      // }

      // setOverviewData(overviewData);
      
      setIsLoading(false);
    }
    getData();
  },[operators]);


  if (isLoading) {
    return (
      <div className='h-screen flex justify-center items-center'>
        <CircularProgress />
      </div>
    );
  }
  
  return (
    <>
      {/* Header */}
      <div className="relative bg-lightBlue-600 pb-32 pt-12">
        <div className={`px-4 md:px-10 mx-auto w-full ${window.location.pathname === "/admin/dashboard" ? "" : "hidden"}`}>
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              {/* <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="# COTAS HOJE"
                  statTitle={overviewData.todayQuotas}
                  statArrow={overviewData.evolutionQuotas > 0 ? "up" : "down"}
                  statPercent={overviewData.evolutionQuotas}
                  statPercentColor={overviewData.evolutionQuotas > 0 ? "text-emerald-500" : "text-red-500"}
                  statDescripiron="sobre dia anterior"
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-red-500"
                  statTooltip="# cotas fechadas no dia de hoje até o momento"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="VOLUME HOJE (R$)"
                  statTitle={overviewData.todayVolume}
                  statArrow={overviewData.evolutionVolume > 0 ? "up" : "down"}
                  statPercent={overviewData.evolutionVolume}
                  statPercentColor={overviewData.evolutionVolume > 0 ? "text-emerald-500" : "text-red-500"}
                  statDescripiron="sobre dia anterior"
                  statIconName="fas fa-money-bill"
                  statIconColor="bg-orange-500"
                  statTooltip="volume financeiro fechado no dia de hoje até o momento"
                />
              </div> */}
              {/* <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="# OPERADORES HOJE"
                  statTitle={overviewData.numberOperators}
                  statArrow=""
                  statPercent=""
                  statPercentColor="text-orange-500"
                  statDescripiron=""
                  statIconName="fas fa-users"
                  statIconColor="bg-yellow-500"
                  statTooltip="operadores atuando hoje"
                />
              </div> */}
              {/* <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="PERFORMANCE"
                  statTitle="9,65%"
                  statArrow="up"
                  statPercent="2"
                  statPercentColor="text-emerald-500"
                  statDescripiron="sobre dia anterior"
                  statIconName="fas fa-percent"
                  statIconColor="bg-lightBlue-500"
                  statTooltip="# cotas fechadas sobre # cotas na mesa"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
