import React, { useContext } from "react";
import AdminContext from "components/Context/AdminContext";


export default function ManageAssignees() {
  const adminContext = useContext(AdminContext);
  const partners = adminContext?.companyPartners;
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div className="rounded-t bg-blueGray-700 mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-white text-xl font-bold">Configurações - Cessionários</h6>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-4">
              <ul>
                { partners.map( (partner,index) => (<li className="p-4 w-full mb-6 shadow-lg rounded-lg bg-white" key={index} >{partner.name}</li>)) }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
