import { useState, useEffect } from "react";
import { DataGrid } from '@material-ui/data-grid';
import { CSVLink } from "react-csv";
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from "moment";

import { getQuotas, deleteQuotas } from "../../services/quota.service";
import formatters from "utils/formatters";

const pageSize = 25;

const columns = [
  {
    field: 'createdAt',
    headerName: 'data upload',
    type: 'date',
    width: 170,
  },
  {
    field: 'administrator',
    headerName: 'administradora',
    type: 'string',
    width: 180,
  },
  {
    field: 'administrator',
    headerName: 'administradora',
    type: 'string',
    width: 180,
  },
  {
    field: 'contractId',
    headerName: 'contrato',
    type: 'string',
    width: 120,
  },
  {
    field: 'quotaId',
    headerName: 'cota',
    type: 'string',
    width: 110,
  },
  {
    field: 'clientName',
    headerName: 'nome cliente',
    type: 'string',
    width: 250,
  },
  // {
  //   headerName: 'Prop. Recompra',
  //   width: 220,
  //   field: 'proprecompra',
  //   renderCell: (row) => {
  //   return <span>{formatters.percentage(row.value)}</span>
  //   }
  // },
  // {
  //   headerName: 'Receita Recompra',
  //   width: 220,
  //   field: 'receitarecompra',
  //   renderCell: (row) => <span>{formatters.financeReal(row.value)}</span>
  // },
  // {
  //   headerName: 'Retorno Esperado Recompra',
  //   width: 220,
  //   field: 'retornoesperadorecompra',
  //   renderCell: (row) => <span>{formatters.financeReal(row.value)}</span>
  // },
  // {
  //   headerName: 'Prop Intermediação',
  //   width: 220,
  //   field: 'propintermediacao',
  //   renderCell: (row) => {
  //   return <span>{formatters.percentage(row.value)}</span>
  //   }
  // },
  // {
  //   headerName: 'Receita Intermediação',
  //   width: 220,
  //   field: 'receitaintermediacao',
  //   renderCell: (row) => <span>{formatters.financeReal(row.value)}</span>
  // },
  // {
  //   headerName: 'Retorno Esperado Intermediação',
  //   width: 220,
  //   field: 'retornoesperadointermediacao',
  //   renderCell: (row) => <span>{formatters.financeReal(row.value)}</span>
  // },
  // {
  //   headerName: 'Prop Reativação',
  //   width: 220,
  //   field: 'propreativacao',
  //   renderCell: (row) => {
  //   return <span>{formatters.percentage(row.value)}</span>
  //   }
  // },
  // {
  //   headerName: 'Receita Reativação',
  //   width: 220,
  //   field: 'receitareativacao',
  //   renderCell: (row) => <span>{formatters.financeReal(row.value)}</span>
  // },
  // {
  //   headerName: 'Retorno Esperado Reativação',
  //   width: 220,
  //   field: 'retornoesperadoreativacao',
  //   renderCell: (row) => <span>{formatters.financeReal(row.value)}</span>
  // },
  // {
  //   headerName: 'Estratégia Recomendada',
  //   width: 220,
  //   field: '1',
  //   renderCell: (row) => {
  //     const { retornoesperadorecompra, retornoesperadointermediacao, retornoesperadoreativacao } = row.row;
  //     console.log(Math.max(retornoesperadorecompra, retornoesperadointermediacao, retornoesperadoreativacao));

  //     if(retornoesperadorecompra>retornoesperadointermediacao && retornoesperadorecompra>retornoesperadoreativacao) {
  //       return "Recompra"
  //     }

  //     if(retornoesperadointermediacao>retornoesperadorecompra && retornoesperadointermediacao>retornoesperadoreativacao){
  //       return "Intermediação"
  //     }
  //     return "Reativação";
  //   }
  // },
];

export default function TableQuotas() {
  const [isLoading, setIsLoading] = useState(false);
  const [quotas, setQuotas] = useState([]);
  const [rows, setRows] = useState([]);
  const [filters, setFilters] = useState({});
  const [selectedQuotas, setSelectedQuotas] = useState();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const retrievedQuotas = await getQuotas();
      // const quotasmockadasas = retrievedQuotas.map((quota) => {
      //   const proprecompra = Math.random() * 0.2;
      //   const propreativacao = Math.random() * 0.2;
      //   const propintermediacao = Math.random() * 0.2;
      //   const receitarecompra = Math.random() * 2000 + 1000;
      //   const receitareativacao = Math.random() * 2000 + 1000;
      //   const receitaintermediacao = Math.random() * 2000 + 1000;

      //   return {
      //     ...quota,
      //     proprecompra,
      //     receitarecompra,
      //     retornoesperadorecompra: proprecompra * receitarecompra,
      //     propintermediacao,
      //     receitaintermediacao,
      //     retornoesperadointermediacao: propintermediacao * receitaintermediacao,
      //     propreativacao,
      //     receitareativacao,
      //     retornoesperadoreativacao: propreativacao * receitareativacao,
      //   }
      // });

      setQuotas(retrievedQuotas);
      setIsLoading(false);
    }
    getData()

  }, []);

  useEffect(() => {
    const items = [];

    let filtered = quotas;
    if (filters.name?.length > 0) {
      filtered = filtered.filter((card) => card.client.name?.toLowerCase().includes(filters.name?.toLowerCase()));
    }
    if (filters.contractId?.length > 0) {
      filtered = filtered.filter((card) => card.contractId?.toLowerCase().includes(filters.contractId?.toLowerCase()));
    }
    if (filters.administrator?.length > 0) {
      filtered = filtered.filter((card) => card.administrator?.toLowerCase().includes(filters.administrator?.toLowerCase()));
    }

    filtered.forEach(quota => {
      const row = {
        // ...quota,
        id: quota.id,
        createdAt: moment(quota.createdAt).format('DD/MM/yyyy'),
        administrator: quota.administrator,
        contractId: quota.contractId,
        groupId: quota.groupId,
        quotaId: quota.quotaId,
        clientName: quota.client.name,
        clientDocument: quota.client.nationalId,
        clientPhone: quota.client.phone,
      };
      items.push(row);
    })
    setRows(items);
  }, [quotas, filters]);

  const deleteSelected = async () => {
    setIsLoading(true);
    await deleteQuotas(selectedQuotas);
    const retrievedQuotas = await getQuotas();
    setQuotas(retrievedQuotas);
    setIsLoading(false);
  }

  const handleFiltersChange = (filter) => {
    setFilters(prevState => {
      const filters = { ...prevState };
      filters[filter.key] = filter.value;
      return filters;
    })
  }

  if (isLoading) {
    return (
      <div className='h-screen flex justify-center items-center'>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="shadow-lg rounded-lg my-4">
      <div className="rounded-t bg-blueGray-200 p-4">
        {/* <h6 className="text-blueGray-800 text-left text-xl font-bold flex-grow uppercase">Propensões</h6> */}
        <h6 className="text-blueGray-800 text-left text-xl font-bold flex-grow uppercase">Base</h6>
        <span className="text-blueGray-500 text-left text-xs font-bold flex-grow">Filtre, delete, faça download da sua base enviado ao sistema.</span>
      </div>
      <div className="flex flex-col w-full m-4">
        <h5 className="text-blueGray-500 uppercase font-semibold text-sm">
          Filtros:
        </h5>
        <div className="flex">
          <div className="flex flex-col">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
              htmlFor="name"
            >
              nome:
            </label>
            <input
              name="name"
              type="text"
              className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
              value={filters.finalValueMinimum}
              onChange={(event) => {
                handleFiltersChange({
                  key: 'name',
                  value: event.target.value,
                });
              }}
            />
          </div>
          <div className="flex flex-col px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
              htmlFor="contractId"
            >
              contrato:
            </label>
            <input
              name="contractId"
              type="text"
              className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
              value={filters.finalValueMinimum}
              onChange={(event) => {
                handleFiltersChange({
                  key: 'contractId',
                  value: event.target.value,
                });
              }}
            />
          </div>
          <div className="flex flex-col px-4">
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
              htmlFor="administrator"
            >
              administradora:
            </label>
            <input
              name="administrator"
              type="text"
              className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
              value={filters.finalValueMinimum}
              onChange={(event) => {
                handleFiltersChange({
                  key: 'administrator',
                  value: event.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex-auto px-4 lg:px-4 py-4">
        <DataGrid
          autoHeight
          checkboxSelection={true}
          rows={rows}
          pageSize={pageSize}
          columns={columns}
          onSelectionModelChange={(newSelection) => setSelectedQuotas(newSelection)}
        />
        <div className="flex justify-between items-center mt-4">
          <button
            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
            onClick={deleteSelected}
          >
            Remover da base
          </button>
          <div className="">
            <i className="fas fa-file-download"></i>
            <CSVLink style={{ marginLeft: '4px' }} data={rows} filename={"quotas.csv"} separator={";"}>Export CSV</CSVLink>
          </div>
        </div>

      </div>
      </div>
    </>
  )
}