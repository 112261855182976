import React from "react";

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function ModalAssign({setShowModalAddRow, handleAddRow, options, type}) {

    const [title, setTitle] = React.useState("Adicionar");

    const [selectAddRow, setSelectAddRow] = React.useState("");
    const [from, setFrom] = React.useState(0);
    const [to, setTo] = React.useState(0);

    const [discount, setDiscount] = React.useState(0);
    const [fine, setFine] = React.useState(0);

    React.useEffect(() => {
       if(type === "discount"){
           setSelectAddRow("tableDiscount");
       } else if(type === "fine"){
           setSelectAddRow("tableFine");
       }

       if(type === "select"){
           setTitle("Adicionar intervalo");
       }
    }, [type])

    const handleSave = () => {
        if(type === "discount"){
            handleAddRow(selectAddRow, from, to, discount);
        }else if(type === "fine") {
            handleAddRow(selectAddRow, from, to, fine);
        } else {
            handleAddRow(selectAddRow, from, to);
        }
        setShowModalAddRow(false)
    } 
    
    const handleClose = () => {
        setShowModalAddRow(false);
    }
        
    return (
    <>
        <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto absolute inset-0 z-50 left-20 outline-none focus:outline-none"
            >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">

                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-2xl px-6 font-semibold">
                        {title}
                    </h3>
                    <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={handleClose}
                    >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                    </span>
                    </button>
                </div>

                <div className="relative px-3 py-6 flex-auto">
                    {type === "select" && 
                        <>
                        <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left"
                        htmlFor="grid-password"
                        >
                            Onde deseja adicionar
                        </label>
                        <FormControl className="w-full" >
                            <Select
                                labelId="assignee-select-label"
                                id="assignee-select"
                                value={selectAddRow}
                                onChange={(e) => setSelectAddRow(e.target.value)}
                            >
                                {options.map((option, index) => (
                                    <MenuItem key={index} value={option.value}><em>{option.text}</em></MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </>
                    }

                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left py-3"
                        htmlFor="grid-password"
                    >
                        De
                    </label>
                    <input
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={from}
                        onChange={(e) => setFrom(e.target.value)}
                    />
                     <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left py-3"
                        htmlFor="grid-password"
                    >
                        Até
                    </label>
                    <input
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        value={to}
                        onChange={(e) => setTo(e.target.value)}
                    />

                    {type === "discount" &&
                        <>
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left py-3"
                            htmlFor="grid-password"
                        >
                            Deságio
                        </label>
                        <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={discount}
                            onChange={(e) => setDiscount(e.target.value)}
                        />
                        </>
                    }

                    {type === "fine" &&
                        <>
                        <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left py-3"
                            htmlFor="grid-password"
                        >
                            Multa
                        </label>
                        <input
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={fine}
                            onChange={(e) => setFine(e.target.value)}
                        />
                        </>
                    }
                </div>

                <div className="flex items-center justify-end px-3 py-3 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleClose}
                    >
                    Fechar
                    </button>
                    <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSave}
                    >
                    Salvar
                    </button>
                </div>
                </div>
            </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}