import axios from 'axios';

const api = axios.create({
    baseURL: String(process.env.REACT_APP_API_URL),
});

api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        config.headers.authorization = `Bearer ${token}`;
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

export default api;