import api from './api';
import formatters from '../utils/formatters';
import moment from "moment";

export const createProposals = async (quotaIdList, assigneeId, consultantId) => {
    try {
        const response = await api.post(`/proposals/bulk`, {
            quotaIdList,
            assigneeId,
            consultantId,
        });
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const updateOperator = async (quotaIdList, operatorId) => {
    try {
        const response = await api.post(`/proposals/operator`, {
            quotaIdList,
            operatorId,
        });
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const startOperation = async (quotaIdList, assigneeId) => {
    try {
        const response = await api.post(`/proposals/operation`, {
            quotaIdsList: quotaIdList,
            assigneeId,
        });
        return response?.data;
    } catch (err) {
        throw err.response;
    }
}

export const getOwnedProposals = async (filters) => {
    try {
        const querystring = formatters.jsonToQuerystring(filters);
        const response = await api.get(`/proposals/owned?${querystring}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getOwnedAcquisitions = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/ownedAcquisitions?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getAggregatedProposals = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/aggregated?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getDashboard = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/dashboard?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getDashboardAssignee = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/dashboardAssignee?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getDashboardConsultant = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/dashboardConsultant?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getFollowUpsAggregated = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/aggregatedFollowUps?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getOperationDetails = async (initialDate, endDate) => {
    try {
        const response = await api.get(`/proposals/operationDetails?initialDate=${moment(initialDate).format('YYYY-MM-DD')}&endDate=${moment(endDate).format('YYYY-MM-DD')}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getProposalById = async (proposalId) => {
    try {
        const response = await api.get(`/proposals/find/${proposalId}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const setCheckedProposalDocuments = async (proposalId) => {
    try {
        const response = await api.patch(`/proposals/${proposalId}/checkDocument`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const addTransferCardNotes = async (interact) => {
    try {
        const response = await api.post(`/proposals/addTransferCardNotes`, interact);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const generateAndDownloadeProposalUrl = async (proposalId, name) => {
    try {
        const response = await api.get(`/proposals/${proposalId}/downloadTransfer`, {
            responseType: 'arraybuffer'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const generateProposalZipAndDownloadData = async (proposalId, contract, quota, group) => {
    try {
        const response = await api.get(`/proposals/${proposalId}/download`, {
            responseType: 'arraybuffer'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${proposalId} - ${quota} - ${contract} - ${group}.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const enrichClientData = async (proposalIds) => api.post('/proposals/enrich', { proposalIds });

export const getProposalsInTransfer = async (params) => api.get(`/proposals/transfers`, { params });
export const getProposalsInTransferCSV = async (params) => api.get(`/proposals/transfers/csv`, { params });
export const setProposalAsTransfered = async (proposalId, scheduledDate) => api.patch(`/proposals/${proposalId}/transfered`, { scheduledDate });
