import React from 'react';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';

const styless = {
    box:{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      textAlign: "center",
      borderRadius: 10,
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
    },
    input: {
      color: "#000",
      backgroundColor: "#DCDCDC",
      border: "10px solid transparent",
      borderRadius: 5,
      padding: 5,
      width: "100%",
    },
}


export default function ModalConfirmFinalValue(props) {

    return (
        <Modal
        open={props.showModalConfirmFinalValue}
        onClose={props.showModal}
      >
        <Box sx={{ ...styless.box, width: 400 }}>
        <h2 className="text-lg font-bold">este é o valor final da oferta?</h2>
            <p>caso o valor não estiver correto, altere o valor no card</p> <br/>
            <p>obs: se necessário, faça o refresh da página (F5) para atualizar os valores salvos</p> <br/>

            <p>valor ofertado: {props.propsCard.finalValue}</p> <br/>

         <div>
         <button 
          className="bg-green-400 h-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => {
            // console.log(props.propsCard.id,props.targetSourceLines.sourceLaneId, props.targetSourceLines.targetLaneId, "Id CArd")
            props.moveCard(props.targetSourceLines.targetLaneId, "oneMove", props.targetSourceLines.sourceLaneId, props.propsCard.id);
            props.showModal();
          }}>
              Sim 
          </button>  

           <button 
          className="bg-red-600 h-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          onClick={props.showModal}>
              Não 
          </button>       
        </div> 

        </Box>
      </Modal>
    )
}

