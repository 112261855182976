import React, {useContext} from "react";

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AdminContext from "components/Context/AdminContext";

export default function ModalAssignToOperator({setShowModalOperator, handleModalSave, selectedClusters}) {

    const adminContext = useContext(AdminContext);

    const [operator, setOperator] = React.useState('');
    const [numberQuotas, setNumberQuotas] = React.useState(selectedClusters.map(cluster => cluster.numberQuotas).reduce((accumulator, next) => accumulator + next));

    const handleOperatorChange = (event) => {
        setOperator(event.target.value);
    };

    const handleNumberQuotasChange = event => {
        setNumberQuotas(event.target.value);
    };

    const handleSave = () => {
        handleModalSave({operatorId: operator, numberQuotas, typeModal: 'operator'});
    }

    const handleClose = () => {
        setShowModalOperator(false);
    }

  return (
    <>
        <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto absolute inset-0 z-50 left-20 outline-none focus:outline-none"
            >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">

                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-2xl px-6 font-semibold">
                    Operação
                    </h3>
                    <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={handleClose}
                    >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                    </span>
                    </button>
                </div>

                <div className="relative px-3 py-6 flex-auto">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left py-3"
                        htmlFor="grid-password"
                    >
                        Quantidade de cotas
                    </label>
                    <input
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleNumberQuotasChange}
                        value={numberQuotas}
                    />
                </div>

                <div className="flex items-center justify-end px-3 py-3 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleClose}
                    >
                    Fechar
                    </button>
                    <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSave}
                    >
                    Salvar
                    </button>
                </div>
                </div>
            </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}