import React from "react";

import FunnelChart from "components/Charts/FunnelChart";
import PieChart from "components/Charts/PieChart";

import formatters from '../../utils/formatters'

import adminApi from 'services/adminApi';

export default function CardBaseAnalysis({
        operationalBase,
        data,
    }) {
    const [optionChart, setOptionChart] = React.useState('2');

    const generateLabels = (intervals) => {
        let rows = []
        intervals.forEach(interval => {
            rows.push(interval.from + ' a ' + interval.to);
        });
        return rows;
    }

    const generateValues = (intervals) => {
        let rows = []

        intervals.forEach(interval => {
            rows.push(interval.count);
        });

        return rows;
    }

    const generateValuesFace = (intervals) => {
        let rows = []

        intervals.forEach(interval => {
            rows.push(interval.faceValue); 
        });
      
        return rows;
    }

    const pieIntervals = {
        labels: generateLabels(data.intervalsVF),
        datasets: [
            {
            label: 'valor de face',
            data: generateValues(data.intervalsVF),
            hoverOffset: 4,
            borderWidth: 0,
            },
        ],
    };

    const pieIntervalsVF = {
        labels: generateLabels(data.intervalsVF),
        datasets: [
            {
            label: 'valor de face',
            data: generateValuesFace(data.intervalsVF),
            hoverOffset: 4,
            borderWidth: 0,
            },
        ],
    };

    const pieIntervalsDuration = {
        labels: generateLabels(data.intervalsDuration),
        datasets: [
            {
            label: 'prazo',
            data: generateValues(data.intervalsDuration),
            hoverOffset: 4,
            borderWidth: 0,
            },
        ],
    };

    const pieIntervalsDurationVF = {
        labels: generateLabels(data.intervalsDuration),
        datasets: [
            {
            label: 'prazo',
            data: generateValuesFace(data.intervalsDuration),
            hoverOffset: 4,
            borderWidth: 0,
            },
        ],
    };

    const pieIntervalsDiscount = {
        labels: generateLabels(data.intervalsDiscount),
        datasets: [
            {
            label: 'deságio',
            data: generateValues(data.intervalsDiscount),
            hoverOffset: 4,
            borderWidth: 0,
            },
        ],
    };

    const pieIntervalsDiscountVF = {
        labels: generateLabels(data.intervalsDiscount),
        datasets: [
            {
            label: 'deságio',
            data: generateValuesFace(data.intervalsDiscount),
            hoverOffset: 4,
            borderWidth: 0,
            },
        ],
    };

    // const dataStrategies = () => {
    //     if(optionChart === "1"){
    //         return data.strategies.map(strategy => strategy.faceValue)
    //     }else {
    //         return data.strategies.map(strategy => strategy.count)
    //     }
    // }

    const pieStrategies = {
        // labels: data.strategies.map(strategy => strategy.strategy),
        labels: [
            'quitação',
            'carrego',
            // 'cobrança',
            // 'intermediação'
        ],
        datasets: [
            {
            label: 'strategy',
            data: data.strategies.map(strategy => strategy.count),
            hoverOffset: 4,
            borderWidth: 0,
            },
        ],
    };

    const pieStrategiesVF = {
        // labels: data.strategies.map(strategy => strategy.strategy),
        labels: [
            'quitação',
            'carrego',
            // 'cobrança',
            // 'intermediação'
        ],
        datasets: [
            {
            label: 'strategy',
            data: data.strategies.map(strategy => strategy.faceValue),
            hoverOffset: 4,
            borderWidth: 0,
            },
        ],
    };

    const pieIntervalsScore = {
        labels: generateLabels(adminApi.analyzeIntermediation().intervalsScore),
        datasets: [
          {
            label: 'score',
            data: generateValues(adminApi.analyzeIntermediation().intervalsScore),
            hoverOffset: 4,
            borderWidth: 0,
          },
        ],
      };

      const pieIntervalsEntryOnCredit = {
        labels: generateLabels(adminApi.analyzeIntermediation().intervalsEntryOnCredit),
        datasets: [
          {
            label: 'entrada/crédito',
            data: generateValues(adminApi.analyzeIntermediation().intervalsEntryOnCredit),
            hoverOffset: 4,
            borderWidth: 0,
          },
        ],
      };

      const pieIntervalsPortionOnCredit = {
        labels: generateLabels(adminApi.analyzeIntermediation().intervalsPortionOnCredit),
        datasets: [
          {
            label: 'parcela/crédito',
            data: generateValues(adminApi.analyzeIntermediation().intervalsPortionOnCredit),
            hoverOffset: 4,
            borderWidth: 0,
          },
        ],
      };

      const pieIntervalsFinanceFee = {
        labels: generateLabels(adminApi.analyzeIntermediation().intervalsFinanceFee),
        datasets: [
          {
            label: 'taxa financeira',
            data: generateValues(adminApi.analyzeIntermediation().intervalsFinanceFee),
            hoverOffset: 4,
            borderWidth: 0,
          },
        ],
      };

      const pieIntervalsEffectiveCost = {
        labels: generateLabels(adminApi.analyzeIntermediation().intervalsEffectiveCost),
        datasets: [
          {
            label: 'custo efetivo',
            data: generateValues(adminApi.analyzeIntermediation().intervalsEffectiveCost),
            hoverOffset: 4,
            borderWidth: 0,
          },
        ],
      };

      const pieIntervalsCredit = {
        labels: generateLabels(adminApi.analyzeIntermediation().intervalsCredit),
        datasets: [
          {
            label: 'crédito',
            data: generateValues(adminApi.analyzeIntermediation().intervalsCredit),
            hoverOffset: 4,
            borderWidth: 0,
          },
        ],
      };


    const funnelVF = {
        datasets: [{
            data: [
                formatters.toDecimalNumber(data.volumeVF.total),
                formatters.toDecimalNumber(data.volumeVF.negotiable),
                formatters.toDecimalNumber(data.volumeVF.accepted)
            ],
            backgroundColor: [
                "#006394",
                "#2293fe",
                "#02a4f5"
            ]
        }],
        labels: [
            "Total",
            "Negociável",
            "Aceito*"
        ]	
    };

    const funnelQuotas = {
        datasets: [{
            data: [
                data.numberQuotas.total,
                data.numberQuotas.negotiable,
                Math.round(data.numberQuotas.accepted)
            ],
            backgroundColor: [
                "#CC9B23",
                "#DDBE45",
                "#FFCE56"
            ]
        }],
        labels: [
            "Total",
            "Negociável",
            "Aceito*"
        ]	
    };

    const handleClickIntervalVF = (label) => {
        console.log(label);
    }

    return (
        <>
            <div className="shadow-lg rounded-lg my-4">

            <div className="rounded-t bg-blueGray-200 p-4">
                <h6 className="text-blueGray-800 text-left text-xl font-bold flex-grow uppercase">análise - recompra</h6>
                <span className="text-blueGray-500 text-left text-xs font-bold flex-grow">Clique nos gráficos de pizza para filtrar os dados do funil. <button className="text-blue-100 underline">(REMOVER FILTROS)</button></span>
            </div>
            <div className="flex w-full m-4">
                <h5 className="text-blueGray-500 uppercase font-semibold text-sm">
                    Carrego e Quitação:
                </h5>
            </div>
            <div className="flex w-full px-4 justify-between">
                <div className="bg-white rounded shadow-lg py-4 px-2">
                    <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                    PnL potencial Fundo
                    </h5>
                    <span className="font-semibold text-xl text-blueGray-700">
                        {formatters.financeReal(data.potentialRevenue)}
                    </span>
                </div>
                <div className="bg-white rounded shadow-lg py-4 px-2">
                    <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                    PnL potencial Tx. ADM
                    </h5>
                    <span className="font-semibold text-xl text-blueGray-700">
                        {formatters.financeReal(data.totalAdministrationRemainingValue)}
                    </span>
                </div>
                <div className="bg-white rounded shadow-lg py-4 px-2">
                    <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                    PnL potencial Tx. Transf.
                    </h5>
                    <span className="font-semibold text-xl text-blueGray-700">
                        {formatters.financeReal(data.totalTransferValue)}
                    </span>
                </div>
                {/* <div className="bg-white rounded shadow-lg py-4 px-2">
                    <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                    PnL potencial Contemplato
                    </h5>
                    <span className="font-semibold text-xl text-blueGray-700">
                        {formatters.financeReal(data.totalSourcingFee)}
                    </span>
                </div> */}
                <div className="bg-white rounded shadow-lg py-4 px-2">
                    <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                    prazo médio
                    </h5>
                    <span className="font-semibold text-xl text-blueGray-700">
                        {data.averageDuration.toFixed(0)} meses
                    </span>
                </div>
                <div className="bg-white rounded shadow-lg py-4 px-2">
                    <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                    investimento necessário
                    </h5>
                    <span className="font-semibold text-xl text-blueGray-700">
                        {formatters.financeReal(data.requiredInvestment)}
                    </span>
                </div>
            </div>
        
            <div className="flex my-2 p-2">
            <div className="flex flex-col w-1/2 pr-4">

                <select
                  id="laneChecked"
                  className="ml-2 w-full"
                  value={optionChart}
                  onChange={(e) => setOptionChart(e.target.value)}
                >
                  <option value="1">Valor de face</option>
                  <option value="2">Número de cotas</option>
                </select>

                {optionChart === "1" && <div>
                     <FunnelChart
                        data={funnelVF}
                        dimension="Volume VF (R$)"
                     />
                </div> }

                {optionChart === "2" && <div> 
                    <FunnelChart
                     data={funnelQuotas}
                     dimension="# Cotas"
                    />
                </div> }
                </div>


                <div className="flex flex-col w-1/2">
                     
                    {optionChart === "1" && 
                        <>
                            <div className="flex justify-evenly">
                                <PieChart 
                                    title="intervalos VF (R$)"
                                    subtitle="valor de face"
                                    data={pieIntervalsVF}
                                    showLegend={true}
                                    colorScheme='brewer.Paired4'
                                    handleClick={handleClickIntervalVF}
                                />
                                <PieChart 
                                    title="intervalos prazo (m)"
                                    subtitle="valor de face"
                                    data={pieIntervalsDurationVF}
                                    showLegend={true}
                                    colorScheme='brewer.RdYlGn6'
                                    handleClick={handleClickIntervalVF}
                                />
                            </div>
                            <div className="flex justify-evenly">
                               <PieChart 
                                   title="intervalos deságio (%)"
                                   subtitle="valor de face"
                                   data={pieIntervalsDiscountVF}
                                   showLegend={true}
                                   colorScheme='brewer.PRGn4'
                                   handleClick={handleClickIntervalVF}
                               />
                               <PieChart 
                                   title="estratégia"
                                   subtitle="valor de face"
                                   data={pieStrategiesVF}
                                   showLegend={true}
                                   colorScheme='brewer.YlGn4'
                                   handleClick={handleClickIntervalVF}
                               />
                            </div>
                        </>
                    }

                    {optionChart === "2" &&
                        <>
                            <div className="flex justify-evenly">
                                 <PieChart 
                                     title="intervalos VF (R$)"
                                     subtitle="# cotas"
                                     data={pieIntervals}
                                     showLegend={true}
                                     colorScheme='brewer.Paired6'
                                     handleClick={handleClickIntervalVF}
                                 />
                                 <PieChart 
                                     title="intervalos prazo (m)"
                                     subtitle="# cotas"
                                     data={pieIntervalsDuration}
                                     showLegend={true}
                                     colorScheme='brewer.RdYlGn6'
                                     handleClick={handleClickIntervalVF}
                                 />
                            </div>
                            <div className="flex justify-evenly">
                               <PieChart 
                                   title="intervalos deságio (%)"
                                   subtitle="# cotas"
                                   data={pieIntervalsDiscount}
                                   showLegend={true}
                                   colorScheme='brewer.PRGn4'
                                   handleClick={handleClickIntervalVF}
                               />
                               <PieChart 
                                   title="estratégia"
                                   subtitle="# cotas"
                                   data={pieStrategies}
                                   showLegend={true}
                                   colorScheme='brewer.YlGn4'
                                   handleClick={handleClickIntervalVF}
                               />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>


            <div className="shadow-lg rounded-lg my-4">

                <div className="rounded-t bg-blueGray-200 p-4">
                    <h6 className="text-blueGray-800 text-left text-xl font-bold flex-grow uppercase">análise - intermediação</h6>
                    <span className="text-blueGray-500 text-left text-xs font-bold flex-grow">Clique nos gráficos de pizza para filtrar os dados do funil. <button className="text-blue-100 underline">(REMOVER FILTROS)</button></span>
                </div>
                <div className="flex w-full m-4">
                    <h5 className="text-blueGray-500 uppercase font-semibold text-sm">
                        intermediação - Canceladas:
                    </h5>
                </div>
                <div className="flex w-full px-4 justify-between pb-6">
                    <div className="bg-white rounded shadow-lg py-4 px-2">
                        <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                            # cotas
                        </h5>
                        <span className="font-semibold text-xl text-blueGray-700">
                            {data.chargeQuantity}
                        </span>
                    </div>
                    <div className="bg-white rounded shadow-lg py-4 px-2">
                        <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                            fundo comum pago
                        </h5>
                        <span className="font-semibold text-xl text-blueGray-700">
                            {formatters.financeReal(data.chargePaidFund)}
                        </span>
                    </div>
                    <div className="bg-white rounded shadow-lg py-4 px-2">
                        <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                            PnL Tx. Transf.
                        </h5>
                        <span className="font-semibold text-xl text-blueGray-700">
                            {formatters.financeReal(data.chargePnlTransferFee)}
                        </span>
                    </div>
                    <div className="bg-white rounded shadow-lg py-4 px-2">
                        <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                            PnL Tx. Adm.
                        </h5>
                        <span className="font-semibold text-xl text-blueGray-700">
                            {formatters.financeReal(data.chargePnlAdmFee)}
                        </span>
                    </div>
                </div>


                {/* <div className="flex w-full m-4">
                    <h5 className="text-blueGray-500 uppercase font-semibold text-sm">
                        Intermediação - Contempladas:
                    </h5>
                </div>
                <div className="flex my-2 p-2">
                    <PieChart 
                        title="Intervalos do score (0-9)"
                        subtitle="# cotas"
                        data={pieIntervalsScore}
                        showLegend={false}
                        colorScheme='brewer.Paired6'
                    />
                    <PieChart 
                        title="entrada/crédito (%)"
                        subtitle="# cotas"
                        data={pieIntervalsEntryOnCredit}
                        showLegend={false}
                        colorScheme='brewer.YlOrRd9'
                    />
                    <PieChart 
                        title="parcela/crédito (%)"
                        subtitle="# cotas"
                        data={pieIntervalsPortionOnCredit}
                        showLegend={false}
                        colorScheme='brewer.RdYlGn11'
                    />
                </div>

                <div className="flex my-2 p-2">
                    <PieChart 
                        title="taxa financeira (%)"
                        subtitle="# cotas"
                        data={pieIntervalsFinanceFee}
                        showLegend={false}
                        colorScheme='brewer.Greys3'
                    />
                    <PieChart 
                        title="custo efetivo (%)"
                        subtitle="# cotas"
                        data={pieIntervalsEffectiveCost}
                        showLegend={false}
                        colorScheme='brewer.PRGn5'
                    />
                    <PieChart 
                        title="Intervalos do Crédito (R$)"
                        subtitle="# cotas"
                        data={pieIntervalsCredit}
                        showLegend={false}
                        colorScheme='brewer.YlGn4'
                    />
                </div> */}
            </div>

            {/* <div className="shadow-lg rounded-lg my-4">
                <div className="rounded-t bg-blueGray-200 p-4">
                    <h6 className="text-blueGray-800 text-left text-xl font-bold flex-grow uppercase">análise - retenção</h6>
                    <span className="text-blueGray-500 text-left text-xs font-bold flex-grow">Clique nos gráficos de pizza para filtrar os dados do funil. <button className="text-blue-100 underline">(REMOVER FILTROS)</button></span>
                </div>
                <div className="flex w-full px-4 justify-between pb-6">
                    <div className="bg-white rounded shadow-lg py-4 px-2">
                        <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                            # cotas
                        </h5>
                        <span className="font-semibold text-xl text-blueGray-700">
                            270000
                        </span>
                    </div>
                    <div className="bg-white rounded shadow-lg py-4 px-2">
                        <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                            propensão esperada
                        </h5>
                        <span className="font-semibold text-xl text-blueGray-700">
                            {formatters.percentage(0.04)}
                        </span>
                    </div>
                    <div className="bg-white rounded shadow-lg py-4 px-2">
                        <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                            receita potencial
                        </h5>
                        <span className="font-semibold text-xl text-blueGray-700">
                            {formatters.financeReal(270000000)}
                        </span>
                    </div>
                    <div className="bg-white rounded shadow-lg py-4 px-2">
                        <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                            receita estimada
                        </h5>
                        <span className="font-semibold text-xl text-blueGray-700">
                            {formatters.financeReal(10800000)}
                        </span>
                    </div>
                </div>
            </div> */}

            {/* <div className="flex w-full m-4">
                <h5 className="text-blueGray-500 uppercase font-semibold text-sm">
                    operação de crédito:
                </h5>
            </div>
            <div className="flex w-full px-4 justify-between pb-6">
                <div className="bg-white rounded shadow-lg py-4 px-2">
                    <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                        valor de face
                    </h5>
                    <span className="font-semibold text-xl text-blueGray-700">
                        R$ 1.562.500,00
                    </span>
                </div>
                <div className="bg-white rounded shadow-lg py-4 px-2">
                    <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                        desembolso
                    </h5>
                    <span className="font-semibold text-xl text-blueGray-700">
                        R$ 1.000.000,00
                    </span>
                </div>
                <div className="bg-white rounded shadow-lg py-4 px-2">
                    <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                        receita
                    </h5>
                    <span className="font-semibold text-xl text-blueGray-700">
                        R$ 562.500,00
                    </span>
                </div>
                <div className="bg-white rounded shadow-lg py-4 px-2">
                    <h5 className="text-blueGray-400 uppercase font-semibold text-xs">
                        prazo médio (m)
                    </h5>
                    <span className="font-semibold text-xl text-blueGray-700">
                        24
                    </span>
                </div>
            </div> */}
            
        </>
    )
}