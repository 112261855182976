import React from "react";
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import CardBaseAnalysis from "components/Cards/CardBaseAnalysis";
import TableAnalysis from "components/Cards/TableAnalysis";
import UploadFiles from 'components/upload-files.component';
import TableQuotaAnalysis from 'components/Tables/TableQuotasAnalysis';

import AdminContext from "components/Context/AdminContext";

import { analyseQuotas } from "services/quota.service";


class Data extends React.Component {

  static contextType  = AdminContext;

  constructor(props) {
    super(props);
    this.state = {
      databaseQuotaAnalysis: null,
      fileQuotaAnalysis: null,
      isLoading: true,
    };
  }

  setFileQuotaAnalysis = (fileQuotaAnalysis) => {
    this.setState({
      fileQuotaAnalysis,
    });
  }

  updateAnalisys = async () => {
    this.setState({
      isLoading: true,
    })
    const databaseQuotaAnalysis = await analyseQuotas();
    this.setState({
      databaseQuotaAnalysis,
      isLoading: false,
    })
  }

  async componentDidMount() {
    await this.updateAnalisys();
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className='shadow-lg rounded-lg my-6 py-4 flex justify-center'>
          <CircularProgress />
        </div>);
    }
    return (
      <>
        <div className="flex flex-col flex-wrap">
            <div className="relative w-full bg-blueGray-600 p-5 pb-6 rounded-lg">
              <h6 className="block uppercase text-white text-xs font-bold mb-2"> Upload de nova Base</h6>
              
              <p className="block text-white text-xs mb-4">Baixe o template <Link className="underline" to="/template-upload.xlsx" target="_blank" download>aqui</Link>, coloque os dados e exporte como .csv separado por ';' e padrão de data e números brasileiro.</p>
              
              <UploadFiles setFileQuotaAnalysis={this.setFileQuotaAnalysis} updateAnalisys={this.updateAnalisys}/>

              {
                this.state.fileQuotaAnalysis && !this.state.isLoading ? 
                  <CardBaseAnalysis
                    operationalBase={false}
                    data={this.state.fileQuotaAnalysis}
                  />
                : null
              }
            </div>
        </div>
        {/* <div className="shadow-lg rounded-lg my-6">
          <TableAnalysis
            operationalBase={false}
          />
        </div> */}
        {
          this.state.isLoading ?
          <div className='shadow-lg rounded-lg my-6 py-4 flex justify-center'>
            <CircularProgress />
           </div>
          : null
        }
  
          <CardBaseAnalysis
            operationalBase={true}
            data={this.state.databaseQuotaAnalysis}
          />
        <div>
          <TableQuotaAnalysis />
        </div>
      </>
    );
  }

}

export default Data;