import React from "react";
import Chart from "chart.js";
import 'chartjs-plugin-colorschemes';
import formatters from '../../utils/formatters'

import { v4 as uuidv4 } from 'uuid';

export default function PieChart({data, title, subtitle, showLegend, colorScheme, handleClick}) {
  const id = uuidv4();
  
  React.useEffect(() => {
    const config = {
      type: "pie",
      data: data,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: showLegend,
          position: "bottom",
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return formatters.toNumber(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
            }
          }
        },
        plugins: {
          colorschemes: {
            scheme: colorScheme,
            override: true,
          }
        },
      },
    };
    const canvas = document.getElementById(id);
    const ctx = canvas.getContext("2d");
    const chart = new Chart(ctx, config);

    canvas.onclick = (evt) => {
      const activePoints = chart.getElementsAtEvent(evt);
      if (activePoints[0]) {
        const chartData = activePoints[0]['_chart'].config.data;
        const idx = activePoints[0]['_index'];

        const label = chartData.labels[idx];
        // const value = chartData.datasets[0].data[idx];

        handleClick(label);
      }
    }
  });

  return (
    <>
      <div className="flex flex-col min-w-0 break-words bg-white  m-2 shadow-lg rounded">
        <div className="rounded-t mb-0 p-4 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 text-xs font-semibold">
                {subtitle}
              </h6>
              <h2 className="text-blueGray-700 text-l font-semibold">
                {title} 
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          <div className="h-250-px">
            <canvas id={id}></canvas>
          </div>
        </div>
      </div>
    </>
  );
}
