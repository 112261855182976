export default function Analytics() {
  const companyId = JSON.parse(localStorage.getItem("user"))?.companyId;
  let dataStudioEmbedURL = '';
  if (companyId === 1) {
    dataStudioEmbedURL = 'https://datastudio.google.com/embed/reporting/995098ea-3015-4cbe-ae45-3b37c9cb9bbf/page/p_4f6w76hvvc'
  } else if (companyId === 5) {
    dataStudioEmbedURL = 'https://datastudio.google.com/embed/reporting/8f19057d-2eaa-41ef-a17c-da6401372a38/page/p_4f6w76hvvc'
  } else {
    return (
      <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div className="rounded-t bg-blueGray-700 mb-0 px-6 py-6">
              <h6 className="text-white text-xl font-bold">Acesso Inválido</h6>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div className="rounded-t bg-blueGray-700 mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-white text-xl font-bold">Data Studio</h6>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10">
              <iframe title="data-studio" width="100%" height="1400" src={dataStudioEmbedURL} frameBorder="0" allowFullScreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}