export default function OperatorBoardLaneHeader(props) {   
    const handleSelectAll = () => {
        // const ids = props.cards.map(card => card.id);
        const selectedCards = props.cards.map(card => ({
            id: card.id,
            laneId: card.laneId,
            assigneeId: card.assigneeId,
        }))
        props.handleOnClickSelectAllCardByColumn(selectedCards);
    } 

    return (
        <>
            <header className="flex items-center justify-between" >
                <span><b>{props.title}</b> ({props.cards?.length})</span>
                <span
                    onClick={handleSelectAll}
                    style={{fontSize: 12, textDecoration: 'underline', cursor: 'pointer'}}>
                        {props.label}
                </span>
            </header>
        </>
    )
}