import React from "react";
// import Chart from "chart.js";
import Chart from 'chartjs-funnel/dist/chart.funnel.bundled';
import formatters from '../../utils/formatters'

import { v4 as uuidv4 } from 'uuid';

export default function FunnelChart({data, dimension}) {

  const id = uuidv4();

  React.useEffect(() => {
    const config = {
      type: 'funnel',
      data: data,
      options: {
        sort: "desc",
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return formatters.toNumber(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
            }
          }
        },
      },
    }
    const canvas = document.getElementById(id)
    const ctx = canvas.getContext("2d");
    new Chart(ctx, config);
  });
  return (
    <>
      <div className="h-680-px flex flex-col min-w-0 break-words bg-white w-full shadow-lg roundedm m-2"
      >
        <div className="rounded-t p-4 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-400 font-semibold">
                {dimension}
              </h6>
            </div>
          </div>
        </div>
        <div className="p-4">
          <canvas className="h-250-px" id={id}></canvas>
          <h6 className="text-blueGray-400 text-xs" style={{height: "42px" }}>
                * estimativa
            </h6>
        </div>
      </div>
    </>
  );
}
