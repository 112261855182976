import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import CircularProgress from '@material-ui/core/CircularProgress';

import operatorsApi from "../services/operatorsApi";

import { getCompany, getCompanyPartners, getCompanyConsultants } from 'services/company.service';
import { getUsers } from 'services/user.service';

import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";
import Dashboard from "views/admin/Dashboard.js";
import Settings from "views/admin/Settings.js";
import MessageSettings from "views/admin/MessageSettings.js";
import Attribution from "views/admin/Attribution.js";
import Data from "views/admin/Data";
import ManageUsers from "views/admin/ManageUsers";
import ManageAssignees from "views/admin/ManageAssignees"
import OperatorsMessages from "views/admin/OperatorsMessages";
import Acquisitions from "views/admin/Acquisitions";
import Operation from "views/admin/Operation";
import Transfers from "views/admin/Transfers";
import AdminContext from "components/Context/AdminContext";
import Analytics from "views/admin/Analytics";

export default function Admin() {

  const [isLoading, setLoading] = useState(true);

  const [company, setCompany] = useState();
  const [companyPartners, setCompanyPartners] = useState();
  const [companyConsultants, setCompanyConsultants] = useState();
  const [operators, setOperators] = useState();
  const [steps, setSteps] = useState();


  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const company = await getCompany();
        const users = await getUsers();
        const partners = await getCompanyPartners();
        const consultants = await getCompanyConsultants();
        
        const operators = users.filter(user => user.role === 'operator');
        const steps = operatorsApi.getSteps();

        setCompany(company);
        setOperators(operators);
        setCompanyPartners(partners);
        setCompanyConsultants(consultants);
        setSteps(steps);
        setLoading(false);
        
      } catch (err) {
        window.location.pathname = '/auth/login';
        alert(err);
      } finally {
        setLoading(false);
      } 
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const adminData = {
    steps,
    operators,
    company,
    companyPartners,
    companyConsultants,
  };

  if (isLoading) {
    return (
      <div className='h-screen flex justify-center items-center'>
        <CircularProgress />
      </div>
    );
  }

  return (
    <AdminContext.Provider value={adminData}>
      <Sidebar />
      <div className="relative md:ml-64">
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24 bg-blueGray-500">
          <Switch>
            <Route path="/admin/dashboard" exact component={Dashboard} />
            <Route path="/admin/settings" exact component={Settings} />
            <Route path="/admin/message-settings" exact component={MessageSettings} />
            <Route path="/admin/attribution" exact component={Attribution} />
            <Route path="/admin/data" exact component={Data} />
            <Route path="/admin/manage-assignees" exact component={ManageAssignees} />
            <Route path="/admin/manage-users" exact component={ManageUsers} />
            <Route path="/admin/operators-messages" exact component={OperatorsMessages} />
            <Route path="/admin/acquisitions" exact component={Acquisitions} />
            <Route path="/admin/transfers" exact component={Transfers} />
            <Route path="/admin/operation" exact component={Operation} />
            <Route path="/admin/analytics" exact component={Analytics} />
            <Redirect from="/admin" to="/admin/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </AdminContext.Provider>
  );
}