import React from "react";

import CardChats from "components/Cards/CardChats";
import CardChartOperatorMessages from 'components/Cards/CardChartOperatorMessages';

export default function OperatorsMessages() {
  const [selectOperator, setSelectOperator] = React.useState("1");
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4">

          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div className="rounded-t bg-blueGray-700 mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-white text-xl font-bold">Auditoria de Mensagens</h6>
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10">
              Selecione o operador para carregar seu histórico:
              <select className="mx-4" name="operator" id="operator"
                value={selectOperator}
                onChange={(e) => setSelectOperator(e.target.value)}
              >
                <option value="1">Luise</option>
                <option value="2">Debora</option>
              </select>
              Selecione o dia:
              <select className="mx-4" name="date" id="date"
                value="yesterday"
                // onChange={(e) => setSelectOperator(e.target.value)}
              >
                <option value="yesterday">ontem</option>
                <option value="today">hoje</option>
              </select>
            </div>
            <CardChartOperatorMessages operator={selectOperator}/>
          </div>

          <div className="py-6">
            <CardChats />
          </div>
          
        </div>
      </div>
    </>
  );
}
