import { useState } from "react";
import moment from "moment";
moment.locale('pt-br');

export default function ModalSelectDate({setIsOpen, handleSave, proposalId}) {
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

    const handleSaveButton = () => {
        if (date) {
          handleSave(proposalId, date);
        }
        else {
          alert('preencha com algum valor para salvar')
        }
    }

  return (
    <>
        <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto absolute inset-0 z-50 left-20 outline-none focus:outline-none"
            >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">

                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-lg font-semibold">
                    agendar pagamento
                    </h3>
                    <button
                    className="text-xl"
                    onClick={() => setIsOpen()}
                    >
                    <span className="">
                        ×
                    </span>
                    </button>
                </div>

                <div className="relative px-3 py-6 flex-auto">
                  <input type='date' value={date} onChange={(event) => setDate(moment(event.target.value).format('YYYY-MM-DD'))} />
                </div>

                <div className="flex items-center justify-end px-3 py-3 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setIsOpen()}
                    >
                    Fechar
                    </button>
                    <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSaveButton}
                    >
                    Salvar
                    </button>
                </div>
                </div>
            </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}