import api from "./api";

export const getUsers = async () => {
    try {
        const response = await api.get(`/users/`)
        return response?.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const inviteUser = async (email, role) => {
    try {
        const response = await api.post(`/users/invite`, {
            email,
            role,
        });
        return response?.data;
    } catch (err) {
        console.log(err);
        throw err;
    }
}