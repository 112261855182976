import api from './api';

export const getCompany = async () => {
    try {
        const { companyId } = JSON.parse(localStorage.getItem("user"));
        const response = await api.get(`/companies/${companyId}`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getCompanyPartners = async () => {
    try {
        const { companyId } = JSON.parse(localStorage.getItem("user"));
        const response = await api.get(`/companies/${companyId}/partners/`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const getCompanyConsultants = async () => {
    try {
        const { companyId } = JSON.parse(localStorage.getItem("user"));
        const response = await api.get(`/companies/${companyId}/consultants/`);
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}

export const postCompany = async (body) => {
    try {
        const { companyId } = JSON.parse(localStorage.getItem("user"));
        const response = await api.patch(`/companies/${companyId}`,{
            settings: body
        });
        return response?.data;
    } catch (err) {
        console.log(err)
        throw err;
    }
}