import api from './api';

export const login = async (email, password) => {
    try {
        const response = await api.post('/auth/login', {
            email,
            password,
        });
        //to do: do not use localStorage! just for prototyping and speed => use http secure cookies with same domain for production
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        
        return response?.data;
    } catch (err) {
        console.log(err)
        err.message = err.status === 401 ? 'email ou senha inválidos' : 'erro ao fazer login, tente novamente mais tarde';
        throw err;
    }
    
}

export const recoverPassword = async (email) => {
    try {
        const response = await api.post('/auth/recover-password', {
            email,
        });
        
        return response?.data;
    } catch (err) {
        console.log(err)
        err.message = 'erro ao recuperar senha, tente novamente mais tarde';
        throw err;
    }
    
}
export const verifyRecoverPassword = async (token, password) => {
    try {
        const response = await api.post('/auth/verify-recover-password', {
            token,
            password
        });
        
        return response?.data;
    } catch (err) {
        console.log(err)
        err.message = 'erro ao criar nova senha, tente novamente mais tarde';
        throw err;
    }
    
}

export const acceptInvitation = async (token, name, password) => {
    try {
        const response = await api.post('/auth/accept-invitation', {
            token,
            name,
            password
        });
        
        return response?.data;
    } catch (err) {
        console.log(err)
        err.message = 'erro ao criar nova senha, tente novamente mais tarde';
        throw err;
    }
    
}

export const logout = async () => {
    // localStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.pathname = '/';
    return await api.post('/auth/logout');
}