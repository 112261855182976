import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import '../assets/styles/dropzone.css';

import CircularProgress from '@material-ui/core/CircularProgress';

import FileService from '../services/file.service';

export default class UploadFiles extends Component {
  constructor(props) {
    super(props);
    this.upload = this.upload.bind(this);
    this.analyse = this.analyse.bind(this);
    this.onDrop = this.onDrop.bind(this);

    this.state = {
      isLoading: false,
      selectedFile: undefined,
      currentFile: undefined,
      progress: 0,
      message: '',
    };
  }

  upload() {
    this.setState({
      progress: 0,
      isLoading: true,
    });
    this.props.setFileQuotaAnalysis(undefined);

    FileService.upload(this.state.selectedFile, (event) => {
      this.setState({
        progress: Math.round((100 * event.loaded) / event.total),
      });
    })
      .then(() => {
        this.setState({
          isLoading: false,
        });
        this.props.updateAnalisys();
      })
      .catch(() => {
        this.setState({
          progress: 0,
          isLoading: false,
          message: `Erro ao enviar arquivo ao servidor, verifique se o arquivo escolhido atende aos requisitos, como não haver duplicidade de contratos. Em caso de continuidade do problema, favor contatar o suporte.`,
        });
      });
  }

  analyse() {
    this.setState({
      progress: 0,
      isLoading: true,
    });

    FileService.analyse(this.state.selectedFile, (event) => {
      this.setState({
        progress: Math.round((100 * event.loaded) / event.total),
      });
    })
      .then((response) => {
        this.props.setFileQuotaAnalysis(response.data);
        this.setState({
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          progress: 0,
          isLoading: false,
          message: `Erro ao validar arquivo, verifique se o arquivo escolhido atende aos requisitos. Em caso de continuidade do problema, favor contatar o suporte.`,
          currentFile: undefined,
        });
      });
  }

  onDrop(files) {
    if (files.length > 0) {
      this.setState({ selectedFile: files[0] });
    }
  }

  render() {
    const { selectedFile, progress, message, isLoading } =
      this.state;

    if (isLoading) {
      return (
        <div className='h-screen flex flex-col justify-center items-center'>
          <CircularProgress color="primary" />
          <p>verificando arquivo...</p>
        </div>
      );
    }

    return (
      <div>
        {selectedFile && progress > 0 && (
          <div className="progress mb-3">
            <div
              className="progress-bar progress-bar-info progress-bar-striped bg-lightBlue-500"
              role="progressbar"
              aria-valuenow={progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: progress + '%' }}
            >
              {progress}%
            </div>
          </div>
        )}

        <Dropzone onDrop={this.onDrop} multiple={false}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {selectedFile && selectedFile.name ? (
                  <div className="selected-file">
                    {selectedFile && selectedFile.name}
                  </div>
                ) : (
                  'Arraste e solte seus arquivos .csv aqui. Ou clique para escolher.'
                )}
              </div>
              <aside className="selected-file-wrapper">
                <div className="mt-6 flex justify-end">
                    <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        disabled={!selectedFile}
                        onClick={this.analyse}
                    >
                        fazer análise
                    </button>
                    <button
                        className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                        disabled={!selectedFile}
                        onClick={this.upload}
                    >
                        upload
                    </button>
                </div>
              </aside>
            </section>
          )}
        </Dropzone>

        <div className="alert alert-light text-white" role="alert">
          {message}
        </div>

      </div>
    );
  }
}
