import {useState , useEffect} from "react";
import { DataGrid } from '@material-ui/data-grid';
import { CSVLink } from "react-csv";
import CircularProgress from '@material-ui/core/CircularProgress';

import moment from "moment";

import ModalChoseOperator from "components/Modals/ModalChoseOperator";
import { getQuotasToAssignOperator } from "../../services/quota.service";
import { updateOperator, startOperation } from "../../services/proposal.service";

const pageSize = 25;

const columns = [
  {
    field: 'createdAt',
    headerName: 'data upload',
    type: 'date',
    width: 170,
  },
  {
    field: 'administrator',
    headerName: 'administradora',
    type: 'string',
    width: 180,
  },
  {
    field: 'administrator',
    headerName: 'administradora',
    type: 'string',
    width: 180,
  },
  {
    field: 'contractId',
    headerName: 'contrato',
    type: 'string',
    width: 120,
  },
  {
    field: 'quotaId',
    headerName: 'cota',
    type: 'string',
    width: 110,
  },
  {
    field: 'clientName',
    headerName: 'nome cliente',
    type: 'string',
    width: 250,
  },
  {
    field: 'clientDocument',
    headerName: 'CPF cliente',
    type: 'string',
    width: 190,
  },
];

export default function TableQuotasAssign() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [quotas, setQuotas] = useState([]);
    const [rows, setRows] = useState([]);
    const [filters, setFilters] = useState({});
    const [selectedQuotas, setSelectedQuotas] = useState();
    const [showModalChoseOperator, setShowModalChoseOperator] = useState(false);

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true);
            try {
              const response = await getQuotasToAssignOperator();
              setQuotas(response.data);
            } catch (err) {
              setError('erro buscando cotas, contate suporte')
            } finally {
              setIsLoading(false);
            }
        }
        getData()
        
    },[]);

    useEffect(() => {
      const items = [];

      let filtered  = quotas || [];
      if (filters.name?.length > 0) {
        filtered = filtered.filter((card) => card.client.name?.toLowerCase().includes(filters.name?.toLowerCase()));
      }
      if (filters.contractId?.length > 0) {
        filtered = filtered.filter((card) => card.contractId?.toLowerCase().includes(filters.contractId?.toLowerCase()));
      }
      if (filters.administrator?.length > 0) {
        filtered = filtered.filter((card) => card.administrator?.toLowerCase().includes(filters.administrator?.toLowerCase()));
      }

      filtered.forEach(quota => {
          const row = {
              id: quota.id,
              createdAt: moment(quota.createdAt).format('DD/MM/yyyy'),
              administrator: quota.administrator,
              contractId: quota.contractId,
              groupId: quota.groupId,
              quotaId: quota.quotaId,
              clientName: quota.client.name,
              clientDocument: quota.client.nationalId,
          };
          items.push(row);
      })
      setRows(items);
    },[quotas, filters]);

    const assignSelected = async (operatorId) => {
      if (!selectedQuotas.length) {
        return alert('selecione pelo menos uma cota para atribuir');
      }

      setIsLoading(true);
      try {
        await updateOperator(selectedQuotas, operatorId);
        const response = await getQuotasToAssignOperator();
        setQuotas(response.data);
      } catch (err) {
        setError('erro ao atribuir cotas, contate suporte')
      } finally {
        setIsLoading(false);
      }
    }

    const handleFiltersChange = (filter) => {
      setFilters(prevState => {
        const filters = { ...prevState };
        filters[filter.key] = filter.value;
        return filters;
      });
    }

    const handleStartOperation = async () => {
      try {
        await startOperation(selectedQuotas);
        alert('Operação iniciada');
        window.location.reload();
      } catch(err) {
        if (err.status === 400) {
          alert(`${err.data}, por favor, reinicie a página.`);
        } else {
          alert(err.data);
        }
      }
    }

    if (isLoading) {
      return (
        <div className='h-screen flex justify-center items-center'>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return (
        <div className='h-screen flex justify-center items-center'>
          <p>{error}</p>
        </div>
      );
    }
          
    return (
        <>
        <div className="rounded-t bg-blueGray-200 p-4">
            <h6 className="text-blueGray-800 text-left text-xl font-bold flex-grow uppercase">Cotas a atribuir operador</h6>
            <span className="text-blueGray-500 text-left text-xs font-bold flex-grow">Filtre, atribua, faça download.</span>
        </div>
        <div className="flex flex-col w-full m-4">
            <h5 className="text-blueGray-500 uppercase font-semibold text-sm">
                Filtros:
            </h5>
            <div className="flex">
              <div className="flex flex-col">
                  <label
                      className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
                      htmlFor="name"
                  >
                      nome:
                  </label>
                  <input
                      name="name"
                      type="text"
                      className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      value={filters.name}
                      onChange={(event) => {
                        handleFiltersChange({
                          key: 'name',
                          value: event.target.value,
                        });
                      }}
                  />
              </div>
              <div className="flex flex-col px-4">
                  <label
                      className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
                      htmlFor="contractId"
                  >
                      contrato:
                  </label>
                  <input
                      name="contractId"
                      type="text"
                      className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      value={filters.contractId}
                      onChange={(event) => {
                        handleFiltersChange({
                          key: 'contractId',
                          value: event.target.value,
                        });
                      }}
                  />
              </div>
              <div className="flex flex-col px-4">
                  <label
                      className="block uppercase text-blueGray-600 text-xs font-bold text-left py-2"
                      htmlFor="administrator"
                  >
                      administradora:
                  </label>
                  <input
                      name="administrator"
                      type="text"
                      className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      value={filters.administrator}
                      onChange={(event) => {
                        handleFiltersChange({
                          key: 'administrator',
                          value: event.target.value,
                        });
                      }}
                  />
              </div>
            </div>
        </div>
        <div className="flex-auto px-4 lg:px-4 py-4">
          <DataGrid
            autoHeight
            checkboxSelection={true}
            rows={rows}
            pageSize={pageSize}
            columns={columns}
            onSelectionModelChange={(newSelection) => setSelectedQuotas(newSelection)}
          />
          <div className="flex justify-between items-center mt-4">
            <button
            className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
            // onClick={() => setShowModalChoseOperator(true)}
            onClick={handleStartOperation}
            >
              Iniciar Operação
            </button>
            {/* {showModalChoseOperator ? <ModalChoseOperator setShowModalOperator={setShowModalChoseOperator} handleModalSave={assignSelected} selectedClusters={selectedQuotas} /> : null}
            <div  className="">
              <i className="fas fa-file-download"></i>
              <CSVLink style={{ marginLeft: '4px' }} data={rows} filename={"quotas.csv"} separator={";"}>Export CSV</CSVLink>
            </div> */}
          </div>
        </div>
        </>
    )
}