import { useState, useEffect } from "react";
import moment from "moment";
import Chart from "chart.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import DateFilter from "components/Filters/DateFilter";
import ChartOperator from "components/Charts/ChartOperator.js";
import ChartConsultant from "components/Charts/ChartConsultant";
import TableOperators from "components/Tables/TableOperators.js"

import generators from '../../utils/generators';

import { getDashboard, getDashboardConsultant } from "../../services/proposal.service";
import { getOperatorsByStep } from "../../services/operator.service";
import adminApi from "../../services/adminApi";



export default function Dashboard() {

  moment.locale('pt-br');

  const [initialDate, setInitialDate] = useState(moment().startOf('day').subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment().startOf('day'));
  const [consultantData, setConsultantData] = useState();
  const [operatorData, setOperatorData] = useState();
  const [stepsTableData, setStepsTableData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      const consultantReturn =  await getDashboardConsultant(initialDate, endDate);
      const operatorReturn =  await getDashboard(initialDate, endDate);
      const days = generators.daysBetweenDates(initialDate, endDate);
      const operatorsSteps = await getOperatorsByStep();

      setConsultantData({dataset: consultantReturn, labels: days});
      setOperatorData({dataset: operatorReturn, labels: days});
      setStepsTableData(operatorsSteps);

      const overviewReturn =  await adminApi.getOverviewData(moment(initialDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));

      let dates = [];
      let quotas = [];
      let volumes = [];
      for (let i = 0; i < overviewReturn.length; i++) {
        const item = overviewReturn[i];
        dates.push(moment(item.date).format('L'));
        quotas.push(item.quotas);
        volumes.push(item.volume);
      }

      var config = {
        type: "bar",
        data: {
          labels: dates,
          datasets: [
            {
              type: 'line',
              label: '# cotas',
              fill: false,
              backgroundColor: "#eab208",
              borderColor: "#eab208",
              data: quotas,
              yAxisID: 'quotas',
              order: 1,
              min: 0,
            },
            {
              type: 'bar',
              label: 'volume R$',
              backgroundColor: "#0ea4e9",
              borderColor: "#0ea4e9",
              data: volumes,
              fill: false,
              yAxisID: 'finance',
              order: 2,
              min: 0,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          legend: {
            labels: {
              fontColor: "white",
            },
            position: "bottom",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "rgba(255,255,255,.7)",
                },
              },
            ],
            yAxes: [
              {
                id:"quotas",
                position: 'left',
                ticks: {
                  fontColor: "rgba(255,255,255,.7)",
                  beginAtZero: true,
                },
                gridLines: {
                  borderDash: [3],
                  drawBorder: false,
                  color: "rgba(255, 255, 255, 0.15)",
                },
              },
              {
                id:"finance",
                position: 'right',
                ticks: {
                  fontColor: "rgba(255,255,255,.7)",
                  beginAtZero: true,
                },
                display: true,
                gridLines: {
                  borderDash: [3],
                  drawBorder: false,
                  color: "rgba(255, 255, 255, 0.15)",
                },
              },
            ],
          },
        },
      };

      setIsLoading(false);
      var ctx = document.getElementById("overview-chart").getContext("2d");
      window.overviewChart = new Chart(ctx, config);

      setIsLoading(false);
    }
    getData();
  }, [initialDate, endDate]);

  if (isLoading) {
    return (
      <div className='h-screen flex justify-center items-center'>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full xl:mb-0 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700">
            <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
              <div className="flex flex-wrap items-center">
                <div className="w-full max-w-full flex-grow flex-1">
                  <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
                    Visão geral
                  </h6>
                  <h2 className="text-white text-xl font-semibold">aquisições</h2>
                </div>
                <div className="bg-white rounded-lg px-2 flex">
                  <DateFilter initialDate={initialDate} setInitialDate={setInitialDate} endDate={endDate} setEndDate={setEndDate}/>
                </div>
              </div>
            </div>
            <div className="p-4 flex-auto">
              <div className="relative h-350-px">
                <canvas id="overview-chart"></canvas>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-4">
          <div className="flex flex-wrap">
            <div className="w-full">
              <div className="relative flex flex-col shadow-lg rounded-lg bg-white border-0">
                <div className="rounded-t bg-blueGray-700 mb-0 p-4">
                  <div className="text-center flex justify-between">
                      <h6 className="text-white text-xl font-bold">Performance</h6>
                      
                  </div>
                </div>
                <div className="p-4">
                  <ChartOperator data={operatorData} />
                </div>
                <div className="p-4">
                  <ChartConsultant data={consultantData}/>
                </div>
              </div>
              <div className="py-4">
                <TableOperators data={stepsTableData} />
              </div>
            </div>
          </div>
        </div>
      </div>
  </>
  );
}