import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Navbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import RecoverPassword from "views/auth/RecoverPassword";
import VerifyRecoverPassword from "views/auth/VerifyRecoverPassword";
import AcceptInvitation from "views/auth/AcceptInvitation";

export default function Auth() {
  return (
    <>
      <Navbar transparent />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div
            className="absolute top-0 w-full h-full bg-blueGray-600 bg-no-repeat bg-full"
          ></div>
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/recover-password" exact component={RecoverPassword} />
            <Route path="/auth/verify-recover-password" component={VerifyRecoverPassword} />
            <Route path="/auth/accept-invitation" component={AcceptInvitation} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          <div className="absolute bottom-0 w-full">
            <Footer />
          </div>
        </section>
      </main>
    </>
  );
}
