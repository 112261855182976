import { useEffect, useMemo, useState } from "react";
import { DataGrid } from '@material-ui/data-grid';
import moment from "moment";
import { CSVLink } from "react-csv";

import formatters from '../../utils/formatters';
import { generateProposalZipAndDownloadData } from '../../services/proposal.service';

const pageSize = 25;

const getCompanyNameById = {
  1: 'Contemplato',
  4: 'BTG',
  5: 'Rodobens',
}

export default function TableOperation({operationDetails}) {
  const [rows, setRows] = useState([]);

  const columns = useMemo(() => {
    async function handleDownloadDocuments(proposalId, contract, quota, group) {
      await generateProposalZipAndDownloadData(proposalId, contract, quota, group);
    }

    return [
      {
        field: '',
        type: 'number',
        width: 50,
        renderCell: ({ row }) => {
          return (
            <button type="button" onClick={() => handleDownloadDocuments(row.id, row.contractId, row.quota, row.group)}>
              <i class="fas fa-solid fa-download"></i>
            </button>
          )
        }
      },
      {
        field: 'contractId',
        headerName: 'contrato',
        type: 'number',
        width: 110,
      },
      {
        field: 'consultant',
        headerName: 'consultor',
        type: 'string',
        width: 140,
      },
      {
        field: 'assignee',
        headerName: 'cessionário',
        type: 'string',
        width: 140,
      },
      {
        field: 'group',
        headerName: 'grupo',
        type: 'string',
        width: 100,
      },
      {
        field: 'quota',
        headerName: 'cota',
        type: 'string',
        width: 90,
      },
      {
        field: 'status',
        headerName: 'status',
        type: 'string',
        width: 100,
      },
      {
        field: 'offer',
        headerName: 'oferta',
        type: 'number',
        valueFormatter: (params) => {
          return formatters.financeReal(params.value);
        },
        width: 100,
      },
      {
        field: 'lastUpdated',
        headerName: 'última atualização',
        type: 'date',
        width: 180,
      },
      {
        field: 'lastManualInteraction',
        headerName: 'última interação manual',
        type: 'date',
        width: 280,
      },
      {
        field: 'lastAutomaticInteraction',
        headerName: 'última interação automática',
        type: 'date',
        width: 390,
      },
    ]
  }, [operationDetails]);
    
    useEffect(() => {
      const rowsData = [];
      operationDetails?.forEach(details => {
        const row = {
          id: details.proposalId,
          contractId: details.contractId,
          consultant: getCompanyNameById[details.consultantId],
          assignee:getCompanyNameById[details.assigneeId],
          group: details.groupId,
          quota: details.quotaId,
          status: details.status,
          offer: details.finalValue ? details.finalValue : 0,
          lastUpdated: details.lastUpdated ? moment(details.lastUpdated).format('DD/MM/yyyy') : '',
          lastManualInteraction: details.lastManualInteractionText,
          lastAutomaticInteraction: details.lastAutomaticInteractionText,
        }
        rowsData.push(row);
      });
      setRows(rowsData);
    },[operationDetails]);
          
    return (
        <>
        <div>
          <DataGrid
            autoHeight
            rows={rows}
            columns={columns}
            pageSize={pageSize}
            disableColumnMenu
          />
          <div  className="flex justify-end items-center mt-4">
            <i className="fas fa-file-download"></i>
            <CSVLink style={{ marginLeft: '4px' }} data={rows} filename={"operacao.csv"} separator={";"}>Export CSV</CSVLink>
          </div>
        </div>
        </>
    )
}
