import api from "./api";

class FileService {
    upload(file, onUploadProgress) {
        try {
            let formData = new FormData();
        
            formData.append('quotas', file);
        
            return api.post('/quotas/upload', formData, {
                headers: {
                'Content-Type': 'multipart/form-data',
                },
                onUploadProgress,
            });
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    analyse(file, onUploadProgress) {
        try {
            let formData = new FormData();
        
            formData.append('quotas', file);
        
            return api.post('/quotas/analyse-file', formData, {
                headers: {
                'Content-Type': 'multipart/form-data',
                },
                onUploadProgress,
            });
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
  }
  
export default new FileService();