import React from "react";

export default function ModalCreateTemplate({ onClose, isOpen, onCreate }) {
    const nameRef = React.useRef(null);

    if (!isOpen) {
        return <div className="absolute" />
    }

    const handleOnCreate = () => {
        if (!nameRef.current?.value) {
            alert('Preencha o campo "nome" antes de criar o template.')
        }

        const template = {
            name: nameRef.current.value,
            title: 'Olá {nome}',
            message: 'Digite a sua mensagem para o {nome}',
            email: false,
            whatsapp: false,
        }

        onCreate(template);
    } 
    
    const handleClose = () => {
        onClose();
    }
        
    return (
    <>
        <div class="z-50">
            <div className="absolute w-auto my-6 mx-auto max-w-3xl">

                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-2xl px-6 font-semibold">
                        Criar template
                    </h3>
                    <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={handleClose}
                    >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                    </span>
                    </button>
                </div>

                <div className="relative px-3 py-6 flex-auto">
                    <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2 text-left py-3"
                        htmlFor="grid-password"
                    >
                        Nome:
                    </label>
                    <input
                        type="text"
                        ref={nameRef}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>

                <div className="flex items-center justify-end px-3 py-3 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleClose}
                    >
                    Fechar
                    </button>
                    <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleOnCreate}
                    >
                    Criar
                    </button>
                </div>
                </div>
            </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}